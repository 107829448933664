import {
  Component,
  Input
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  Store
} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import * as HelpMessages from '../../../core/store/actions/help';
import {
  MapService
} from '../../../routes/map/map.service';
import * as _ from 'underscore';
import {
  HelpMessage
} from '../../../routes/help/models/helpMessage';
import {
  Observable
} from 'rxjs';
import {
  map,
  flatMap
} from 'rxjs/operators';
import { ObservableHelper } from '../../../shared/helpers/observableHelper';

@Component({
  selector: 'app-help-info-right',
  templateUrl: './help-info.component.html'
})
export class HelpInfoPanelComponent {
    _id: string;
    @Input() set id(value: string) {
      this._id = value;
      this.loadDetails();
    }
  helpMessage$: Observable < HelpMessage > ;
  loading$: Observable < boolean > ;
  error$: Observable < string > ;
  success$: Observable < string > ;
  title$: Observable < string > ;

  constructor(private store: Store < fromRoot.State >, private router: Router ) {
    this.helpMessage$ = this.store.select(fromCore.getHelpMessage);
    this.loading$ = this.store.select(fromCore.getPriorityHelpLoading);
    this.error$ = this.store.select(fromCore.getHelpError);
    this.success$ = this.store.select(fromCore.getHelpSuccess);
    this.title$ = this.loading$.pipe(flatMap(loading => {
      if (!loading) {
        return this.helpMessage$.pipe(map(helpMessage => {
          if (helpMessage === null) {
            return 'Loading';
          }
          return helpMessage.user.firstName + ' ' + helpMessage.user.lastName;
        }));
      } else {
        return ObservableHelper.getLoadingObservable();
      }
    }));
  }

  loadDetails() {
    this.store.dispatch(new HelpMessages.GetHelpMessage(this._id));
  }

  goToSOS(){
    return '/helpmessages/view/' + this._id;
    //this.router.navigateByUrl(url);
  }
}
