import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  Store
} from '@ngrx/store';
import {
  Authenticate
} from '../models/user';
import * as fromRoot from '../../../core/store/reducers';
import * as Auth from '../../../core/store/actions/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../../core/services/config.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent implements OnInit, OnDestroy {
  pending$ = this.store.select(fromRoot.getLoginPagePending);
  error$ = this.store.select(fromRoot.getLoginPageError);
  errorDescription$ = this.store.select(fromRoot.getLoginErrorDescription);
  viewState = 1;
  email: string;
  password: string;
  appVersion: string;
  private ngUnsubscribe: Subject< any > = new Subject();

  constructor(private store: Store < fromRoot.State >, private cd: ChangeDetectorRef, private configService: ConfigService) {
    
  }

  ngOnInit() {

    this.configService.getVersion().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      this.appVersion = result;
    });

    this.error$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((description) => {
      if (description === 'verification_required') {
        this.viewState = 3;
      } else if (description === '2factor required') {
          this.viewState = 4;
      }

      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.cd.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit($event: Authenticate) {
    this.email = $event.email;
    this.password = $event.password;
    this.store.dispatch(new Auth.Login($event));
  }

  onStateChanged(newState: number): void {
    this.viewState = newState;
  }
}
