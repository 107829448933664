import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  VerificationItem
} from '../models/VerificationItem';
import {
  VerificationType
} from '../models/VerificationType';
import {
  Store
} from '@ngrx/store';
import * as fromRoot from '../../../core/store/reducers';
import * as Auth from '../../../core/store/actions/auth';
import {
  Authenticate
} from '../models/user';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  GenericValidator
} from '../../../shared/validators/generic.validator';
import {
  Subject
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  AuthService
} from '../services/auth.service';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
})

export class TwoFactorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() stateChanged = new EventEmitter < number > ();
  @Input() email: string;
  @Input() password: string;
  data: VerificationItem[];
  requestState = 0;
  selectedItem: VerificationItem;
  selected: boolean = false;
  event: Authenticate;
  emailSelected: boolean = false;
  phoneSelected: boolean = false;
  invalidCode: boolean = false;
  sendCodeForm: FormGroup;
  resendEmail: boolean = false;
  resendPhone: boolean = false;
  loading: boolean = false;
  code: string;
  private genericValidator: GenericValidator;
  private ngUnsubscribe: Subject < any > = new Subject();
  private validationMessages: {
    [key: string]: {
      [key: string]: string
    }
  };
  displayMessage: {
    [key: string]: string
  } = {};

  constructor(private store: Store < fromRoot.State > , private formBuilder: FormBuilder, private authService: AuthService, private zone: NgZone) {
    this.event = new Authenticate();

    this.validationMessages = {
      code: {
        required: 'Code is required',
        pattern: 'Only numbers are allowed'
      }
    };

    const codePattern = '[0-9]+';

    this.sendCodeForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(codePattern)]]
    });

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  setTitle(type) {
    if (type === VerificationType.Email) {
      return 'Email';
    } else if (type === VerificationType.PhoneNumber) {
      return 'SMS';
    }
  }

  ngOnInit() {
    this.loading = true;
    this.authService.getTwoFactorOptions(this.email).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      this.zone.run(() => {
        this.data = result;

        if (this.data.length === 1) {
          this.data[0].selected = true;
          this.selected = true;
          this.selectedItem = this.data[0];
        }
        this.loading = false;
      });
    });
  }

  onSelect(item) {
    this.selectedItem = item;
  }

  ngAfterViewInit(): void {
    /* Update the validation messages on value change callback */
    this.sendCodeForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.displayMessage = this.genericValidator.processMessages(this.sendCodeForm);
    });
  }

  onSubmit() {
    if (this.selectedItem !== null && this.selectedItem !== undefined) {
      this.loading = true;
      this.authService.generateTwoFactorCode(this.email, this.selectedItem).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
        this.code = '';
        this.requestState = 1;
        this.loading = false;
      });
    }
  }

  on2FClick(item: VerificationItem): void {
    const index = this.data.indexOf(item);
    item.selected = !item.selected;
    this.data[index] = item;

    this.selected = item.selected;

    const other = this.data.filter(x => x.value !== item.value);
    other.forEach(x => {
      x.selected = false;
    });
  }

  verifyTwoFactorCode(): void {
    this.loading = true;
    this.authService.checkTwoFactorCode(this.email, this.code, this.selectedItem).subscribe((result) => {
      this.sendCodeForm.reset();
      if (result) {
        this.event.email = this.email;
        this.event.password = this.password;
        this.store.dispatch(new Auth.Login(this.event));
      } else {
        this.invalidCode = true;
      }
      this.loading = false;
    });
  }

  resendCode(type): void {
    this.loading = true;
    this.selectedItem = this.data.find(x => x.type === type);
    this.authService.generateTwoFactorCode(this.email, this.selectedItem).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      if (result) {
        if (type === 0) {
          this.resendEmail = true;
        } else {
          this.resendPhone = true;
        }
      } else {
        // code invalid error
      }
      this.loading = false;
    });
  }

  // when user is typing into input form
  setInvalidCode(obj): void {
    this.resendEmail = false;
    this.resendPhone = false;
    this.invalidCode = false;
  }


  backClick(): void {
    this.resendEmail = false;
    this.resendPhone = false;
    this.requestState = 0;
  }

  goLoginScreen(state: number): void {
    this.stateChanged.emit(state);
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
