import {
    Component, OnInit
  } from '@angular/core';


@Component({
    selector: 'app-user-cost-estimate',
    templateUrl: './cost-estimate.component.html'
  })

  export class CostEstimatePanelComponent implements OnInit {
    
    title$: string;
    
    
    ngOnInit(): void {
        
    }

    constructor() {
        this.title$ = 'Cost Estimate';
      }

  }