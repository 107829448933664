import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalService } from "../../../core/services/modal.service";
import { StandDownRequestState } from "../../help/models/standDownModel";
import { HelpService } from "../../help/services/help.service";
import { AuthService } from "../services/auth.service";

@Component({
    selector: 'app-stand-down',
    templateUrl: './stand-down.component.html',
  })
  export class StandDownComponent  {

    id: string;
    email: string;
    state: StandDownRequestState;
    loading: boolean;

    private ngUnsubscribe: Subject < any > = new Subject();


    constructor(private modalService: ModalService, private helpService: HelpService, private formBuilder: FormBuilder, private route: ActivatedRoute, private http: HttpClient, private router: Router) {
      this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.loading = true;
        this.id = params['id'];
  
        this.helpService.checkStandDownRequest(this.id)
        .subscribe((result) => {
          this.email = result.email;
          this.state = result.state;
          this.loading = false;
        });
      });
    }

    OnStandDownClick(){

      this.modalService.showConfirmModal('Send Stand Down Alert', '<p>Sending a Stand Down alert will notify the same recipients of the SOS to stand down via the web dashboard and SMS. You will be associated to this stand down.</p><p><strong>This can’t be undone.</strong></p><p><strong>Are you sure you want to continue?</strong></p>')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if(result) {
          this.loading = true;
          this.helpService.standDownAnnonymous(this.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(result => {
            this.state = result.state;
            this.loading = false;
          });
        }
      });
    }
  }