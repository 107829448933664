import { User } from '../../users/models/user';
import { CheckIn } from '../../../core/models/stanzas/checkIn.stanza';
import { Location } from '../../locations/models/location';
import { SopTemplate } from '../../../core/models/sopTemplate';
import { HelpMessageNote } from './helpmessagenote';

export class HelpMessage {
    id: string;
    message: string;
    recipients: string;
    sentAt: Date;
    messageStatus: string;
    user: User;
    standDownUser: User;
    geoLookupLocation: string;
    location: Location;
    lastCheckinDate: Date;
    lastCheckinEndDate: Date;
    stoodDownOn: Date;
    lastCheckinEndDateFormatted: string;
    locationLastUpdated: Date;
    locationLastUpdatedFormatted: string; 
    usersLastCheckinLocation: string;
    managerPhoneNumbers: string[];
    carRegistration: string;
    cylinderCapacity: string;
    cO2Emissions: string;
    fuelType: string;
    euroStatus: string;
    realDrivingEmissions: string;
    userLastSeen: string;
    serviceManagerPhone: string;
    securityManagerPhone: string;
    lastThreeCheckIns: CheckIn[];
    lat: number;
    lng: number; 
    isDeveloperTest: boolean;
    helpMessageRecipients: HelpMessageRecipient[];
    sopTemplateId: number;
    sopTemplate: SopTemplate;
    state: number;
    method: HelpRequestMethod;
    hardwareState: string;
    what3WordsLocation: string;
    helpMessageNotes : HelpMessageNote[];
}

export interface HelpMessageRecipient {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    secondaryPhoneNumber: string;
}

export enum HelpRequestMethod {
    Standard = 0,
    Hardware = 1
  }
