import * as utils from '../../../shared/helpers/utils';
import * as roles from '../actions/roles';
import * as _ from 'underscore';
import {
  Role
} from '../../../routes/roles/models/role';
import {
  SelectItem
} from '../../models/selectItem';
import * as moment from 'moment';

export interface State {
  roles: Role[];
  selectedRole: Role;
  selectRoles: SelectItem[];
  loading: boolean;
  error: string;
  success: string;
  page: number;
  maxItems: number;
  searchTerm: string;
}

const initialState: State = {
  roles: [],
  selectedRole: null,
  selectRoles: [],
  loading: true,
  error: '',
  success: '',
  page: 1,
  maxItems: 0,
  searchTerm: '',
};

function convertRoles(roles: Role[]): SelectItem[] {
  const result = new Array < SelectItem > ();

  roles.forEach(item => {
    result.push({
      id: item.id,
      itemName: item.displayName
    });
  });

  return result;
}

function replaceItem(roles: Role[], role: Role) {
  const index = _.findIndex(roles, {
    id: role.id
  });
  const clonedRole = _.extend({}, role);

  if (index >= 0) {
    roles[index] = clonedRole;
  } else {
    roles.push(role);
  }

  const temp: Role[] = [];
  return roles = temp.concat(roles);
}

function mergeRoles(roles: Role[], newRoles: Role[]) {
  roles = roles.concat(newRoles);

  roles = roles.filter((role, index, self) => {
    return index === self.findIndex((t) => (t.id === role.id));
  });

  return roles;
}

export function reducer(state = initialState, action: roles.Actions): State {
  switch (action.type) {
    case roles.GET_ROLE: {
      return {
        ...state,
        loading: true
      };
    }
    case roles.GET_ROLE_COMPLETE: {
      return {
        ...state,
        roles: replaceItem(state.roles, action.payload),
        selectedRole: action.payload,
        loading: false,
        error: '',
        success: ''
      };
    }
    case roles.GET_ROLE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedRole: initialState.selectedRole,
        success: ''
      };
    }
    case roles.GET_ROLES_PAGED: {
      const page = action.payload;
      return Object.assign({}, state, {
        loading: true,
        page: page == null ? state.page : page,
        searchTerm: action.term
      });
    }
    case roles.GET_ROLES_PAGED_COMPLETE: {
      return {
        ...state,
        roles: mergeRoles(state.roles, action.payload),
        maxItems: action.maxCount,
        loading: false,
        error: '',
        success: ''
      };
    }
    case roles.GET_ROLES_PAGED_FAILED: {
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        success: ''
      });
    }
    case roles.GET_AVAILABLE_ROLES: {
      return {
        ...state,
        roles: [],
        selectRoles: [],
        loading: true,
        error: '',
      };
    }
    case roles.GET_AVAILABLE_ROLES_COMPLETE: {
      return {
        ...state,
        loading: false,
        error: '',
        roles: action.payload,
        selectRoles: convertRoles(action.payload)
      };
    }
    case roles.GET_AVAILABLE_ROLES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        roles: [],
        selectRoles: []
      };
    }
    case roles.UPDATE_ROLES: {
      return {
        ...state,
        loading: true
      };
    }
    case roles.UPDATE_ROLES_COMPLETE: {
      return {
        ...state,
        roles: replaceItem(state.roles, action.payload),
        selectedRole: action.payload,
        loading: false,
        error: '',
        success: 'Updated role at: ' + moment(new Date(), 'DD/MM/YYYY').format('LTS')

      };
    }
    case roles.UPDATE_ROLES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedRole: initialState.selectedRole,
        success: ''
      }
    };
  case roles.RESET_STORE: {
    return initialState;
  }
  default: {
    return state;
  }
  }
}

export const getAvailableRoles = (state: State) => state.roles;
export const getAvailableRolesForSelect = (state: State) => state.selectRoles;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getMaxItems = (state: State) => state.maxItems;
export const getPage = (state: State) => state.page;
export const getSearchTerm = (state: State) => state.searchTerm;
export const getRole = (state: State) => state.selectedRole;
