import { Action } from '@ngrx/store';
import { Result } from '../../../routes/search/models/search';

export const SEARCH = '[Search] Search';
export const SEARCH_COMPLETE = '[Search] Search Complete';
export const SEARCH_FAILED = '[Search] Search Failed';
export const SEARCH_CLEAR = '[Search] Search Clear';

export class Search implements Action {
  readonly type = SEARCH;

  constructor(public payload: string) {}
}

export class SearchComplete implements Action {
  readonly type = SEARCH_COMPLETE;

  constructor(public payload: Result[]) {}
}

export class SearchFailed implements Action {
  readonly type = SEARCH_FAILED;

  constructor(public payload: string) {}
}

export class SearchClear implements Action {
  readonly type = SEARCH_CLEAR;
}

export type Actions = Search | SearchComplete | SearchFailed | SearchClear;
