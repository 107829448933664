import { Component, Input, Output, EventEmitter, HostListener, ElementRef, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Result } from '../models/search';
import { NotificationService, ConnectionState } from '../../../core/services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil, map, debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-nav-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() results: Result[] = [];
  @Input() loading = false;
  @Input() error = '';
  @Output() search = new EventEmitter<string>();
  @Output() selected = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  term = '';
  termControl = new FormControl();
  healthClass = 'warning';

  public shouldShow = false;
  private firstClick = true;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(public elementRef: ElementRef, private router: Router, private notificationService: NotificationService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.termControl.valueChanges
      .pipe(map(newTerm => {
        this.term = newTerm;
        this.loading = true;
      }), debounceTime(1000))
      .subscribe(() => {
        this.search.emit(this.term);
      });

      this.MapHealthClass(this.notificationService.connectionState);

      this.notificationService.connectionState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
        switch (state) {
          case ConnectionState.Connected:
            this.healthClass = 'success';
          break;
          case ConnectionState.Connecting:
            this.healthClass = 'warning';
          break;
          case ConnectionState.Disconnected:
            this.healthClass = 'danger';
          break;
          case ConnectionState.Reconnecting:
            this.healthClass = 'warning';
          break;
        }
        this.cd.detectChanges();
      });
  }

  private MapHealthClass(state: ConnectionState): void {
    switch (state) {
      case ConnectionState.Connected:
        this.healthClass = 'success';
      break;
      case ConnectionState.Connecting:
        this.healthClass = 'warning';
      break;
      case ConnectionState.Disconnected:
        this.healthClass = 'danger';
      break;
      case ConnectionState.Reconnecting:
        this.healthClass = 'warning';
      break;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }

  @HostListener('document:click', ['$event'])
  handleClick(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
        this.firstClick = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
      if (this.firstClick && inside) {
        this.firstClick = false;
        this.shouldShow = true;
      } else if (!inside) {
        this.shouldShow = false;
      }
  }

  navigateTo(item: any) {
    this.shouldShow = false;
    this.term = '';
    this.selected.emit(item);
  }

  refreshMap() {
    this.refresh.emit();
  }

  loadSystemHealth() {
    this.router.navigate(['/systemhealth']);
  }

  searchIt() {
    this.search.emit(this.term);
  }
}
