import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ModalService } from '../../core/services/modal.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  getModalTitle: () => string;
  getModalText: () => string;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private modalService: ModalService) {
        
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    const modalTitle = component.getModalTitle() ? component.getModalTitle() : "Are you sure?";
    const modalText = component.getModalText() ? component.getModalText() : 'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.';

    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      this.modalService.showConfirmModal(modalTitle, modalText);
  }
}