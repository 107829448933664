import {
    Component,
    ChangeDetectorRef,
    OnDestroy,
    Input,
    OnInit,
    EventEmitter,
    Output,
    ViewChild,
    TemplateRef,
    NgZone,
    ElementRef,
    ComponentFactoryResolver
  } from '@angular/core';
  import {
    Router,
    ActivatedRoute
  } from '@angular/router';
  import {
    HelpMessageNote
  } from '../../../routes/help/models/helpmessagenote';
  import * as _ from 'underscore';      
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, takeUntil } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { GenericValidator } from '../../../shared/validators/generic.validator';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';

import * as Users from '../../../core/store/actions/users';
import { error } from '@angular/compiler/src/util';
import { HttpErrorResponse } from '@angular/common/http';
import * as utils from '../../../shared/helpers/utils';
import { SidePanelService } from '../../../core/services/sidepanel.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { HelpService } from '../../../routes/help/services/help.service';
  
  @Component({
    selector: 'app-help-message-note-right',
    templateUrl: './help-message-note.component.html'
  })
  export class HelpMessageNoteComponent implements OnInit, OnDestroy {  
    _id: string;
    @Input() message: HelpMessageNote;
    @Input() set id(value: string) {
      this._id = value;
    }    
    loading: boolean;    
    noteForm: FormGroup;
    displayMessage: {
      [key: string]: string
    } = {};
    private validationMessages: {
      [key: string]: {
        [key: string]: string
      }
    };
    @ViewChild('confirmSave', {static: false}) private confirmSaveModal: TemplateRef < any > ;    
    private modalRef: BsModalRef;
    private genericValidator: GenericValidator;
    private ngUnsubscribe: Subject < any > = new Subject();    
    @Output('save') save: EventEmitter < HelpMessageNote > = new EventEmitter < HelpMessageNote > ();
    
  
    constructor(private elementRef: ElementRef,private zone: NgZone, private router: Router, private cd: ChangeDetectorRef, private fb: FormBuilder, private store: Store < fromRoot.State >, private sidePanelService: SidePanelService, private modalService: BsModalService,private helpService: HelpService) {
      this.validationMessages = {
        note: {
          required: 'A note is required',
          maxlength: 'Note can be max 50 characters long'
        }
      };
      
      this.genericValidator = new GenericValidator(this.validationMessages);
    }
  
    ngOnInit(): void {       
      this.noteForm = this.fb.group({
        id: '',
        helpMessageId: this._id,
        userId: utils.AuthenticationHelper.getCurrentUser().id,
        note: ['', [Validators.required, Validators.maxLength(50)]]
      });
    }

    clickSave(form: any) {     
        this.modalRef = this.modalService.show(this.confirmSaveModal, {
            class: 'modal-md',
            animated: true,
            keyboard: false
          });
      
    }
    OnSaveConfirm() {
      if (this.noteForm.dirty && this.noteForm.valid) {
        const p: HelpMessageNote = Object.assign({}, this.message, this.noteForm.value);
        this.handleSave(p);
      }      
  }

    
  handleSave(helpMessageNote: HelpMessageNote) {
    this.helpService.addNote(helpMessageNote)
    .pipe(takeUntil(this.ngUnsubscribe))    
    .subscribe((data: any) => {   
      this.modalRef.hide();      
      this.sidePanelService.OnNoteSaved.emit(true);
    });
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }  
    ngAfterViewInit(): void {
      /* Update the validation messages on value change callback */
      this.noteForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(val => {
        this.displayMessage = this.genericValidator.processMessages(this.noteForm);
      }, 
    error =>{
      console.error('Error occurred:', error);
    });
    }
  }
  