import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SearchService {
  constructor(private http: HttpClient) {}

  search(term: string) {
    return this.http.post('/api/v2/search', { term: term });
  }
}
