import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import {
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import {
  GenericValidator
} from '../../../shared/validators/generic.validator';
import {
  Subject
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import * as fromRoot from '../../../core/store/reducers';
import {
  VerificationItem
} from '../models/VerificationItem';
import {
  VerificationType
} from '../models/VerificationType';
import {
  Store
} from '../../../../../node_modules/@ngrx/store';
import {
  Authenticate
} from '../models/user';
import {
  AuthService
} from '../services/auth.service';
import * as Auth from '../../../core/store/actions/auth';

@Component({
  selector: 'app-verification-page',
  templateUrl: './verification.component.html'
})

export class VerifyComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() stateChanged = new EventEmitter < number > ();
  @Input() email: string;
  @Input() password: string;
  requestState = 1;
  data: VerificationItem[];
  emailVerified: boolean = false;
  phoneVerified: boolean = false;
  invalidCode: boolean = false;
  sendCodeForm: FormGroup;
  code: string;
  resendEmail: boolean = false;
  resendPhone: boolean = false;
  loading: boolean = false;
  event: Authenticate;


  selectedItem: VerificationItem;
  verifiedItem: VerificationItem;

  private genericValidator: GenericValidator;
  private ngUnsubscribe: Subject < any > = new Subject();

  private validationMessages: {
    [key: string]: {
      [key: string]: string
    }
  };

  displayMessage: {
    [key: string]: string
  } = {};


  constructor(private store: Store < fromRoot.State > , private formBuilder: FormBuilder, private authService: AuthService, private cd: ChangeDetectorRef) {
    this.event = new Authenticate();
    this.validationMessages = {
      code: {
        required: 'Code is required',
        pattern: 'Only numbers are allowed'
      }
    };

    const codePattern = '[0-9]+';

    this.sendCodeForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(codePattern)]]
    });

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.loading = true;
    this.authService.getVerificationOptions(this.email)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
      this.data = result;
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    /* Update the validation messages on value change callback */
    this.sendCodeForm.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(val => {
      this.displayMessage = this.genericValidator.processMessages(this.sendCodeForm);
    });
  }

  loginScreen(state: number): void {
    this.stateChanged.emit(state);
  }

  backClick() {
    this.resendEmail = false;
    this.resendPhone = false;
    this.code = '';
    this.requestState = 1;
  }

  generateCode(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const idAttr = target.attributes.id;
    const value = idAttr.nodeValue;

    this.selectedItem = this.data[value];
    this.loading = true;
    this.authService.generateVerificationCode(this.email, this.selectedItem)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
      if (result) {
        this.requestState = 2;
      } else {
        // code invalid error
      }

      this.loading = false;
    });
  }

  login() {
    this.event.email = this.email;
    this.event.password = this.password;
    this.store.dispatch(new Auth.Login(this.event));
  }

  resendCode(type) {
    this.selectedItem = this.data[type];
    this.loading = true;
    this.authService.generateVerificationCode(this.email, this.selectedItem)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
      if (result) {
        if (type === 0) {
          this.resendEmail = true;
        } else {
          this.resendPhone = true;
        }
      } else {
        // code invalid error
      }

      this.loading = false;
    });
  }

  verifyCode(inputCode: any) {
    this.loading = true;
    this.authService.checkVerificationCode(this.email, inputCode, this.selectedItem)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
      this.sendCodeForm.reset();

      if (result == null) {
        this.invalidCode = true;
      } else {
        this.verifiedItem = result;
        this.data.find(x => x.value === this.verifiedItem.value).verified = this.verifiedItem.verified;

        if (this.verifiedItem.type === VerificationType.Email && this.verifiedItem.verified) {
          this.emailVerified = true;
          this.requestState = 1;
        } else if (this.verifiedItem.type === VerificationType.PhoneNumber && this.verifiedItem.verified) {
          this.phoneVerified = true;
          this.requestState = 1;
        }
      }

      this.loading = false;
    });
  }

  removeInvalidCode(value) {
    this.resendEmail = false;
    this.resendPhone = false;
    this.invalidCode = false;
  }

  ngOnDestroy() {
    this.cd.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
