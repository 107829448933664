import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationHelper } from '../../shared/helpers/utils';
import { Config } from '../models/config';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient) {}

  getConfig() {
    return this.http.get<Config>('/api/v2/config/get').pipe(map(result => {
      AuthenticationHelper.saveMenu(result.nav);
      console.log(result.nav);
      return result;
    }));
  }

  getMapStyles(){
    return this.http.get<Config>('/api/v2/config/getmapstyles')
  }

  getVersion() {
    return this.http.get<string>('/api/v2/config/getVersion')
  }
}
