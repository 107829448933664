import * as utils from '../../../shared/helpers/utils';
import * as reports from '../actions/reports';
import * as _ from 'underscore';
import { Report } from '../../../routes/reports/models/report';

export interface State {
  reports: Report[];
  selectedReport: Report;
  loading: boolean;
  error: string;
  success: string;
}

const initialState: State = {
  reports: [],
  selectedReport: null,
  loading: false,
  error: '',
  success: ''
};

export function reducer(state = initialState, action: reports.Actions): State {
    switch (action.type) {
      case reports.GET_ITEMS: {
        return {
          ...state,
          reports: [],
          loading: true
        };
      }
      case reports.GET_ITEMS_COMPLETE: {
        return {
          ...state,
          reports: action.payload,
          loading: false,
          error: '',
          success: ''
        };
      }

      case reports.GET_ITEM: {
        return {
          ...state,
          loading: true
        };
      }
      case reports.GET_ITEM_COMPLETE: {
        return {
          ...state,
          selectedReport: action.payload,
          loading: false,
          error: '',
          success: ''
        };
      }
      case reports.GET_ITEM_FAILED: {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedReport: initialState.selectedReport,
          success: ''
        };
      }

      default: {
        return state;
      }
    }
}

export const getReports = (state: State) => state.reports;
export const getReport = (state: State) => state.selectedReport;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
