import {
    Injectable,
    EventEmitter,
    Output
  } from '@angular/core';
  import {
    HttpClient
  } from '@angular/common/http';
import { TrainingEmailed, TrainingLog, TrainingModel, TrainingResponse, TrainingType } from '../models/TrainingModel';
import { catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { User } from '../../users/models/user';
@Injectable()
export class TrainingService {

    constructor(private http: HttpClient){

    }

    getLoneWorkerTraining(){
        return this.http.get< TrainingModel > ('api/v2/external/content/loneworkertraining')
        .pipe(timeout(10000), 
        catchError(error => {
          // Handle the error, for example by logging or returning a fallback value
          console.error('Request timed out or failed', error);
          return throwError(() => new Error('Request timed out or failed'));
        }) );
    }

    getSOSRecipientTraining(){
      return this.http.get< TrainingModel > ('api/v2/external/content/sosrecipienttraining')
      .pipe(timeout(10000), 
      catchError(error => {
        // Handle the error, for example by logging or returning a fallback value
        console.error('Request timed out or failed', error);
        return throwError(() => new Error('Request timed out or failed'));
      }) );
  }

    CompleteLoneWorkerTraining(email: string){
      var url = 'api/v2/training/loneworker?email=' + encodeURIComponent(email);
      return this.http.post< TrainingResponse > (url,null)
      .pipe(timeout(10000), 
      catchError(error => {
        // Handle the error, for example by logging or returning a fallback value
        console.error('Request timed out or failed', error);
        return throwError(() => new Error('Request timed out or failed'));
      }) );
    }
    CompleteSOSRecipientTraining(email: string){
      var url = 'api/v2/training/sosrecipient?email=' + encodeURIComponent(email);
      return this.http.post< TrainingResponse > (url, null)
      .pipe(timeout(10000), 
      catchError(error => {
        // Handle the error, for example by logging or returning a fallback value
        console.error('Request timed out or failed', error);
        return throwError(() => new Error('Request timed out or failed'));
      }) );
  }

  sendTrainingLink(userId: string, trainingType : TrainingType){    
    return this.http.post< TrainingEmailed > ('api/v2/training/sendtraining', { userId , trainingType })
    .pipe(timeout(10000), 
    catchError(error => {
      // Handle the error, for example by logging or returning a fallback value
      console.error('Request timed out or failed', error);
      return throwError(() => new Error('Request timed out or failed'));
    }) );
}

splitTrainingLogs(user: User) {
  console.log(user.trainingLogs);
  if(user.trainingLogs !== undefined)
  {
    //.filter returns a empty list if the trainingLogs are empty
    //Also sort is to ensure the most recent log is first for the completed date
    user.loneWorkerTrainingLogs = user.trainingLogs.filter(log => log.trainingTypeId === TrainingType.LoneWorker);    
    user.loneWorkerTrainingLogs = user.loneWorkerTrainingLogs.sort((a: TrainingLog, b: TrainingLog) => {
      return this.getTime(b.dateCompleted) - this.getTime(a.dateCompleted);
    });  
    user.sosRecipientTrainingLogs = user.trainingLogs.filter(log => log.trainingTypeId === TrainingType.SOSRecipient);
    user.sosRecipientTrainingLogs = user.sosRecipientTrainingLogs.sort((a: TrainingLog, b: TrainingLog) => {
      return this.getTime(b.dateCompleted) - this.getTime(a.dateCompleted);
    });  
  }    
  if(user.trainingEmailed !== undefined)
  {
    user.loneWorkerTrainingEmailed = user.trainingEmailed.filter(log => log.trainingTypeId === TrainingType.LoneWorker);    
    user.loneWorkerTrainingEmailed = user.loneWorkerTrainingEmailed.sort((a: TrainingEmailed, b: TrainingEmailed) => {
      return this.getTime(b.dateEmailed) - this.getTime(a.dateEmailed);
    });  
    user.sosRecipientTrainingEmailed = user.trainingEmailed.filter(log => log.trainingTypeId === TrainingType.SOSRecipient);
    user.sosRecipientTrainingEmailed = user.sosRecipientTrainingEmailed.sort((a: TrainingEmailed, b: TrainingEmailed) => {
      return this.getTime(b.dateEmailed) - this.getTime(a.dateEmailed);
    });  
  }

  return user;
}
private getTime(date?: Date) {
  return date != null ? new Date(date).getTime() : 0;
}

  
}

