import {
    Component, OnInit
  } from '@angular/core';


@Component({
    selector: 'app-user-carbon-footprint',
    templateUrl: './carbon-footprint.component.html'
  })

  export class CarbonFootprintPanelComponent implements OnInit {
    
    title$: string;
    
    
    ngOnInit(): void {
        
    }

    constructor() {
        this.title$ = 'Carbon Footprint Estimate';
    }

  }