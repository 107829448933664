import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  User
} from '../../../../routes/users/models/user';
import {
  Role
} from '../../../../routes/roles/models/role';
import {
  Result
} from '../../../../routes/search/models/search';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html'
})
export class TopNavComponent {
  @Input() user: User;
  @Input() roles: Role[];
  @Input() searchResults: Result[];
  @Input() searchLoading: boolean;
  @Input() searchError: string;
  @Output() logout = new EventEmitter();
  @Output() search = new EventEmitter < string > ();
  @Output() searchSelected = new EventEmitter < any > ();

  logoutClick() {
    this.logout.emit();
  }

  searchEmit(term: string) {
    this.search.emit(term);
  }

  searchSelectedEmit(item: any) {
    this.searchSelected.emit(item);
  }
}
