import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Permission } from '../models/admin/permission';

@Injectable()
export class PermissionsService {
    constructor(private http: HttpClient) {

    }

    getPermissions() {
        return this.http.get<Permission[]>('/api/v2/admin/permissions/list');
    }
}