import {
  Component,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  HttpClient
} from '@angular/common/http';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  GenericValidator
} from '../../../shared/validators/generic.validator';
import {
  Subject
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  PasswordValidators
} from '../../../shared/validators/password.validator';
import {
  AuthService
} from '../services/auth.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnDestroy, AfterViewInit {

  id: string;
  email: string;
  changePasswordForm: FormGroup;
  isValid: boolean;
  requestState = 1;
  loading: boolean;
  resend: boolean;

  @Output() stateChanged = new EventEmitter < number > ();

  error: any;

  displayMessage: {
    [key: string]: string
  } = {};

  private validationMessages: {
    [key: string]: {
      [key: string]: string
    }
  };

  private genericValidator: GenericValidator;

  private ngUnsubscribe: Subject < any > = new Subject();

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private route: ActivatedRoute, private http: HttpClient, private router: Router) {

    // checks if the token is valid before page is created. Displays appropriate page depending on return value
    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
      this.loading = true;
      this.id = params['id'];

      this.authService.changePassword(this.id)
      .subscribe((result) => {
        this.isValid = result.result;
        this.email = result.email;
        this.loading = false;
      });
    });

    this.validationMessages = {
      password: {
        PasswordRequirements: 'Password must have two lowercase letters, two uppercase letters, minimum 8 characters, two special characters and two numbers.',
        required: 'Password is required'
      },
      confirmPassword: {
        MatchPassword: 'Passwords must match',
        required: 'Password is required'
      }
    };

    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: Validators.compose([PasswordValidators.MatchPassword, PasswordValidators.PasswordRequirements])
    });

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Update the validation messages on value change callback */
    this.changePasswordForm.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(val => {
      this.displayMessage = this.genericValidator.processMessages(this.changePasswordForm);
    });
  }

  // submits new password and updates user
  submit() {
    if (this.changePasswordForm.valid) {
      this.loading = true;
      const newPassword = this.changePasswordForm.get('password').value;
      const confirmPassword = this.changePasswordForm.get('confirmPassword').value;

      this.authService.updatePassword(newPassword, confirmPassword, this.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result) {
          this.requestState = 2;
        } else {
          this.requestState = 3;
        }
        this.loading = false;
      });
    }
  }

  resetPasswordEmail() {
    this.loading = true;
    this.authService.resetPassword(this.email)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
      this.resend = true;
      this.loading = false;
    });
  }

  login() {
    this.router.navigateByUrl('/login');
  }
}
