import {
    Pipe,
    PipeTransform
  } from '@angular/core';
import { User } from '../../routes/users/models/user';


@Pipe({
    name: 'deletedUsers',
    pure: false
})
export class DeletedUsersPipe implements PipeTransform {
    transform(users: User[], includeDeletedUser: boolean): any {
        if (!users) {
            return [];
          }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out

        if(includeDeletedUser)
        {
            return users;
        }
        else
        {
            return users.filter(user => user.isDeleted === false);
        }
    }
}