import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf
} from '@angular/core';
import {
  EffectsModule
} from '@ngrx/effects';
import {
  StoreModule
} from '@ngrx/store';
import {
  ConfigService
} from './services/config.service';
import {
  ConfigEffects
} from './store/effects/config';
import {
  reducers
} from '../core/store/reducers';
import {
  MapService
} from './../routes/map/map.service';
import {
  SettingsStorageService
} from './services/settings.service';
import {
  NotificationService,
  NotificationConfig,
  SignalrWindow
} from './services/notification.service';
import {
  SidePanelService
} from './services/sidepanel.service';
import { AuthEffects } from './store/effects/auth.effects';
import { AuthService } from './../routes/auth/services/auth.service';
import { SearchEffects } from './store/effects/search';
import { LocationImportEffects } from './store/effects/locationimport';
import { LocationsEffects } from './store/effects/locations';
import { AnnouncementsEffects } from './store/effects/announcements';
import { ReportEffects } from './store/effects/report';
import { UserImportEffects } from './store/effects/userimport';
import { UsersEffects } from './store/effects/users';
import { TeamsEffects } from './store/effects/teams';
import { HelpMessageEffects } from './store/effects/help';
import { HelpService } from './../routes/help/services/help.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RolesEffects } from './store/effects/roles';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BearerTokenInterceptor } from '../shared/httpinterceptors/bearerToken-interceptor.service';
import { RefreshTokenInterceptor } from '../shared/httpinterceptors/refreshToken-interceptor.service';
import { ReportsService } from '../routes/reports/services/reports.service';
import { TeamsService } from '../routes/teams/services/teams.service';
import { LocationsService } from '../routes/locations/services/locations.service';
import { AnnouncementsService } from '../routes/messaging/services/messages.service';
import { SearchService } from '../routes/search/services/search.service';
import { UsersService } from '../routes/users/services/users.service';
import { RolesService } from '../routes/roles/services/roles.service';
import { PermissionsEffects } from './store/effects/permissions';
import { PermissionsService } from './services/permissions.service';
import { MenuService } from './services/menu.service';
import { TrainingService } from '../routes/training/services/training.service';

const channelConfig = new NotificationConfig();
channelConfig.url = '/signalr';
channelConfig.hubName = 'notificationHub';

export const EFFECTS = [
  ConfigEffects,
  AuthEffects,
  SearchEffects,
  LocationImportEffects,
  LocationsEffects,
  AnnouncementsEffects,
  ReportEffects,
  UserImportEffects,
  UsersEffects,
  TeamsEffects,
  HelpMessageEffects,
  RolesEffects,
  PermissionsEffects
];

@NgModule({
  imports: [
    StoreModule.forFeature('core', reducers),
    EffectsModule.forFeature(EFFECTS)
  ],
  providers: [
    NotificationService,
    {
      provide: SignalrWindow,
      useValue: window
    },
    {
      provide: 'channel.config',
      useValue: channelConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    MapService,
    AuthService,
    ReportsService,
    ConfigService,
    HelpService,
    SidePanelService,
    SettingsStorageService,
    TeamsService,
    LocationsService,
    AnnouncementsService,
    SearchService,
    UsersService,
    RolesService,
    PermissionsService,
    MenuService,
    TrainingService,
  ],
  exports: []
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
}
}
