import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Result } from '../models/search';

@Component({
  selector: 'app-search-item',
  template: `
    <div class="info-box">
      <div class="image">
        <div class="avatar">
          <img *ngIf="item?.image != '' && item?.image != null" [src]="item.image" width="40">
        </div>
      </div>
      <div class="info">
        <div class="info-content">
          <span>{{item.name}}</span>
          {{item.subname}}
        </div>
        <ul class="buttons">
          <li>
            <a href="javascript:void(0)" class="btn btn-action btn-action-compact btn-locate" (click)="navigateTo()">Locate</a>
          </li>
        </ul>
      </div>
    </div>
  `
})
export class SearchItemComponent {
  @Input() item: Result;
  @Output() showItem = new EventEmitter<any>();

  navigateTo() {
    this.showItem.emit(this.item);
  }
}
