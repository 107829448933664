import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-ok-close',
  templateUrl: './ok-close.component.html'
})
export class OkCloseComponent {
  subject: Subject<boolean>;
  @Input() title: string = 'Warning';
  @Input() message: string = 'Some Message here?';

  constructor(public bsModalRef: BsModalRef) { }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
}