import {
  Routes
} from '@angular/router';
import {
  AuthGuard, AuthedGuard
} from '../shared/guards/auth-guard.service';
import {
  NotFoundPageComponent
} from '../layout/components/not-found-page.component';
import {
  LoginPageComponent
} from './auth/containers/login-page.component';
import {
  LayoutComponent
} from '../layout/layout.component';
import { ChangePasswordComponent } from './auth/components/change-password.component';
import { UnauthorizedComponent } from '../layout/components/unauthorized.component';
import { StandDownComponent } from './auth/components/stand-down.component';
import { QuickSopComponent } from './auth/components/quick-sop.component';
import { TrainingComponent } from './training/components/training.component';
import { TrainingType } from './training/models/TrainingModel';

// Right Panels
export const routes: Routes = [{
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
        path: '',
        redirectTo: 'map',
        pathMatch: 'full'
      },
      {
        path: 'map',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Team Member', 'Vendor Administrator']
        }
      },
      {
        path: 'teamstatus',
        loadChildren: () => import('./teamstatus/teamstatus.module').then(m => m.TeamStatusModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Vendor Administrator', 'MDM Onboarder']
        }
      },
      {
        path: 'locations',
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Vendor Administrator']
        }
      },
      {
        path: 'messages',
        loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Vendor Administrator']
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Vendor Administrator', 'MDM Onboarder']
        }
      },
      {
        path: 'teams',
        loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule),
        data: {
          Roles: ['Administrator', 'Vendor Administrator', 'MDM Onboarder']
        }
      },
      {
        path: 'sosrecipients',
        loadChildren: () => import('./sosrecipients/sos-recipients.module').then(m => m.SosRecipientsModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Vendor Administrator']
        }
      },
      {
        path: 'helpmessages',
        loadChildren: () => import('./help/helpmessages.module').then(m => m.HelpMessageModule),
        data: {
          Roles: ['Administrator', 'Vendor Administrator', 'MDM Onboarder']
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        data: {
          Roles: ['Administrator', 'Vendor Administrator', 'MDM Onboarder']
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: {
          Roles: ['Vendor Administrator']
        }
      },
      {
        path: 'systemhealth',
        loadChildren: () => import('./systemhealth/systemhealth.module').then(m => m.SystemHealthModule),
        data: {
          Roles: ['Administrator', 'Team Manager', 'Team Member', 'Vendor Administrator', 'MDM Onboarder']
        }
      },
      {
        path: 'faqs',
        loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule),
        data : {
          Roles: ['Administrator', 'Team Manager', 'Team Member', 'Vendor Administrator']
        }
      }
    ]
  },
  // Not lazy-loaded routes
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [AuthedGuard]
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    canActivate: [AuthedGuard]
  },
  {
    path: 'standdown',
    component: StandDownComponent
  },
  {
    path: 'quicksop',
    component: QuickSopComponent
  },
  {
    path: '405-Unauthorized',
    component: UnauthorizedComponent
  },  
  {
    path: 'lone-worker-training',               
    component: TrainingComponent,
    data: { trainingType: TrainingType.LoneWorker }
  },
  {
    path: 'sos-recipient-training',               
    component: TrainingComponent,
    data: { trainingType: TrainingType.SOSRecipient }
  },
  {
    path: '**',
    component: NotFoundPageComponent
  },    
];
