import {
  Action
} from '@ngrx/store';
import { Role } from '../../../routes/roles/models/role';

export const GET_ROLE = '[ROLES] Get Item';
export const GET_ROLE_COMPLETE = '[ROLES] Get Item Complete';
export const GET_ROLE_FAILED = '[ROLES] Get Item Failed';

export class GetRole implements Action {
  readonly type = GET_ROLE;

  constructor(public payload: string) {}
}
export class GetRoleComplete implements Action {
  readonly type = GET_ROLE_COMPLETE;

  constructor(public payload: Role) {}
}
export class GetRoleFailed implements Action {
  readonly type = GET_ROLE_FAILED;

  constructor(public payload: string) {}
}
export type GetRoleActions = GetRole | GetRoleComplete | GetRoleFailed;

export const GET_ROLES_PAGED = '[ROLES] Get Roles Paged';
export const GET_ROLES_PAGED_COMPLETE = '[ROLES] Get Roles Paged Complete';
export const GET_ROLES_PAGED_FAILED = '[ROLES] Get Roles Paged Failed';

export class GetRolesPaged implements Action {
  readonly type = GET_ROLES_PAGED;
  constructor(public payload: number, public term: string) {}
}

export class GetRolesPagedComplete implements Action {
  readonly type = GET_ROLES_PAGED_COMPLETE;
  constructor(public payload: Role[], public maxCount: number) {}
}

export class GetRolesPagedFailed implements Action {
  readonly type = GET_ROLES_PAGED_FAILED;
  constructor(public payload: string) {}
}

export type GetRolesPagedActions = GetRolesPaged | GetRolesPagedComplete | GetRolesPagedFailed;

export const GET_AVAILABLE_ROLES = '[Roles] Get Available';
export const GET_AVAILABLE_ROLES_COMPLETE = '[Roles] Get Available Complete';
export const GET_AVAILABLE_ROLES_FAILED = '[Roles] Get Available Failed';

export class GetAvailableRoles implements Action {
  readonly type = GET_AVAILABLE_ROLES;
}
export class GetAvailableRolesComplete implements Action {
  readonly type = GET_AVAILABLE_ROLES_COMPLETE;

  constructor(public payload: Role[]) {}
}
export class GetAvailableRolesFailed implements Action {
  readonly type = GET_AVAILABLE_ROLES_FAILED;

  constructor(public payload: string) {}
}
export type ActionsGetAvailable = GetAvailableRoles | GetAvailableRolesComplete | GetAvailableRolesFailed;

export const GET_AVAILABLE_ROLES_FOR_SELECT = '[Roles] Get Available For Select';
export const GET_AVAILABLE_ROLES_COMPLETE_FOR_SELECT = '[Roles] Get Available Complete For Select';
export const GET_AVAILABLE_ROLES_FAILED_FOR_SELECT = '[Roles] Get Available Failed For Select';

export class GetAvailableRolesForSelect implements Action {
  readonly type = GET_AVAILABLE_ROLES;
}
export class GetAvailableRolesCompleteForSelect implements Action {
  readonly type = GET_AVAILABLE_ROLES_COMPLETE;

  constructor(public payload: Role[]) {}
}
export class GetAvailableRolesFailedForSelect implements Action {
  readonly type = GET_AVAILABLE_ROLES_FAILED;

  constructor(public payload: string) {}
}
export type ActionsGetForSelect = GetAvailableRolesForSelect | GetAvailableRolesCompleteForSelect | GetAvailableRolesFailedForSelect;

export const RESET_STORE = '[ROLES] Reset Store';

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}

export const UPDATE_ROLES = '[Roles] Update Roles';
export const UPDATE_ROLES_COMPLETE = '[Roles] Update Roles Complete';
export const UPDATE_ROLES_FAILED = '[Roles] Update Roles Failed';

export class UpdateRoles implements Action {
  readonly type = UPDATE_ROLES;
  constructor(public payload: Role) {}
}

export class UpdateRolesComplete implements Action {
  readonly type = UPDATE_ROLES_COMPLETE;
  constructor(public payload: Role) {}
}

export class UpdateRolesFailed implements Action {
  readonly type = UPDATE_ROLES_FAILED;
  constructor(public payload: string) {}
}

export type UpdateActions = UpdateRoles | UpdateRolesComplete | UpdateRolesFailed;

export type Actions = ActionsGetAvailable | ActionsGetForSelect | GetRolesPagedActions | ResetStore | GetRoleActions | UpdateActions;
