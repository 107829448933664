import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  OnInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  Location
} from '../../../routes/locations/models/location';
import * as _ from 'underscore';
import {
  CheckInItem
} from '../../../routes/locations/models/location';
import {
  CheckInState
} from '../../../core/models/stanzas/checkIn.stanza';
import {
  LocationsService
} from '../../../routes/locations/services/locations.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-location-info-right',
  templateUrl: './location-info.component.html'
})
export class LocationInfoPanelComponent implements OnInit, OnDestroy {
  _id: string;
  @Input() set id(value: string) {
    this._id = value;
    this.loadDetails();
  }
  location: Location;
  itemsLoading: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private cd: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private locationsService: LocationsService, private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  loadDetails() {
    this.itemsLoading = true;
      this.locationsService.getLocationDetail(this._id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((location) => {
        this.location = location;
        this.itemsLoading = false;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }

  gotoMap(location: Location) {
    this.router.navigate(['/map/' + location.id, {
      outlets: {
        'leftpanel': null,
        'rightpanel': null
      }
    }]);
  }

  private GetEventClass(event: CheckInItem): string {
    if (event.outAt === null) {
      return 'incomplete';
    }

    const inAt = new Date(event.inAt).setHours(0, 0, 0, 0);
    const outAt = new Date(event.outAt).setHours(0, 0, 0, 0);

    if (inAt !== outAt) {
      return 'overnight';
    }

    return '';
  }

  private IsOvernight(event: CheckInItem): boolean {
		if (event.outAt === null || event.outAt === undefined) {
			return false;
		}

    const inAt = new Date(event.inAt).setHours(0, 0, 0, 0);
		const outAt = new Date(event.outAt).setHours(0, 0, 0, 0);

    return (inAt !== outAt);
  }

  private GetCheckinClass(checkin: CheckInItem): string {
    if (checkin.outAt === null) {
      return 'incomplete';
    }

    const inAt = new Date(checkin.inAt).setHours(0, 0, 0, 0);
    const outAt = new Date(checkin.outAt).setHours(0, 0, 0, 0);

    if (inAt !== outAt) {
      return 'overnight';
    }

    return '';
  }

  private GetDay(date: Date): string {
    let result = '';

    switch (date.getDay()) {
      case 0:
        result = 'Sunday';
      break;
      case 1:
        result = 'Monday';
      break;
      case 2:
        result = 'Tuesday';
      break;
      case 3:
        result = 'Wednesday';
      break;
      case 4:
        result = 'Thursday';
      break;
      case 5:
        result = 'Friday';
      break;
      case 6:
        result = 'Saturday';
      break;
    }

    return result;
  }


  private checkinIsOvernight(checkin: CheckInItem): boolean {
    const inAt = new Date(checkin.inAt).setHours(0, 0, 0, 0);
    const outAt = new Date(checkin.outAt).setHours(0, 0, 0, 0);

    return (inAt !== outAt);
  }

  private getInAtDatestring(checkin: CheckInItem): string {
    return this.datePipe.transform(checkin.inAt, 'dd/MM/yyyy');
  }

  private getOutAtDatestring(checkin: CheckInItem): string {
    return this.datePipe.transform(checkin.outAt, 'dd/MM/yyyy');
  }

  private getInAtstring(checkin: CheckInItem): string {
    return this.datePipe.transform(checkin.inAt, 'HH:mm');
  }

  private getOutAtString(checkin: CheckInItem): string {
    if (checkin.outAt === null || checkin.outAt === undefined) {
			return 'Active';
		}

    return this.datePipe.transform(checkin.outAt, 'HH:mm');
  }

  private getOutAtDate(checkin: CheckInItem): Date {
    if (checkin.hardState === CheckInState.HardCheckOut) {
      return checkin.outAt;
    }

    return checkin.outAt;
  }

  private getCheckinType(checkin: CheckInItem): String {
    if (checkin.hardState === CheckInState.HardCheckOut) {
      return 'Hard Check-In';
    }
    return 'Soft Check-In';
  }
}
