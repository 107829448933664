import {
  Action
} from '@ngrx/store';

import {
  Config
} from '../../models/config';
import { AuthenticationHelper } from '../../../shared/helpers/utils';

export const GET_ITEMS = '[Config] Get Items';
export const GET_ITEMS_COMPLETE = '[Config] Get Items Complete';
export const GET_ITEMS_FAILED = '[Config] Get Items Failed';

export class GetItems implements Action {
  readonly type = GET_ITEMS;
}

export class GetItemsComplete implements Action {
  readonly type = GET_ITEMS_COMPLETE;

  constructor(public payload: Config) {
    AuthenticationHelper.SaveAllRoles(payload.roles);
  }
}

export class GetItemsFailed implements Action {
  readonly type = GET_ITEMS_FAILED;
}

export type Actions = GetItems | GetItemsComplete | GetItemsFailed;
