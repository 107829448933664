import {
  Injectable
} from '@angular/core';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Observable,
  of
} from 'rxjs';
import {
  PermissionsService
} from '../../services/permissions.service';
import * as permissions from '../actions/permissions';
import {
  switchMap,
  catchError,
  map
} from 'rxjs/operators';
import {
  ObservableHelper
} from '../../../shared/helpers/observableHelper';
import {
  Action
} from '@ngrx/store';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class PermissionsEffects {

  constructor(private actions$: Actions, private permissionsService: PermissionsService) {}

  @Effect()
  public getPermissions: Observable < Action > = this.actions$.pipe(
    ofType(permissions.GET_PERMISSIONS),
    switchMap(payload => this.permissionsService.getPermissions().pipe(
      map(data => ({
        type: permissions.GET_PERMISSIONS_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: permissions.GET_PERMISSIONS_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));
}
