import * as utils from '../../../shared/helpers/utils';
import * as announcements from '../actions/announcements';
import * as _ from 'underscore';
import {
  Announcement
} from '../../../routes/messaging/models/announcement';

export interface State {
  announcements: Announcement[];
  selectedAnnouncement: Announcement;
  loading: boolean;
  error: string;
  success: string;
  page: number;
  maxItems: number;
  searchTerm: string;
}

const initialState: State = {
  announcements: [],
  selectedAnnouncement: null,
  loading: false,
  error: '',
  success: '',
  page: 1,
  maxItems: 0,
  searchTerm: '',
};

function replaceItem(announcements: Announcement[], announcement: Announcement) {
  const index = _.findIndex(announcements, {
    id: announcement.id
  });

  const clonedAnnouncement = _.extend({}, announcement);
  if (index >= 0) {
    announcements[index] = clonedAnnouncement;
  } else {
    if (announcement.subject != null) {
      announcements.push(clonedAnnouncement);
    }
  }

  const temp: Announcement[] = [];
  return announcements = temp.concat(announcements);
}

function removeItem(announcements: Announcement[], announcement: Announcement) {  
  const index = _.findIndex(announcements, {
    id: announcement.id
  });  

  announcements.splice(index, 1);

  const temp: Announcement[] = [];
  return announcements = temp.concat(announcements);
}

function mergeAnnouncements(announcements: Announcement[], newAnnouncements: Announcement[]) {
  announcements = announcements.concat(newAnnouncements);
  return announcements;
}

export function reducer(state = initialState, action: announcements.Actions): State {
  switch (action.type) {
    case announcements.GET_ITEMS_PAGED:
      {
        const page = action.payload;
        return Object.assign({}, state, {
          loading: true,
          page: page == null ? state.page : page,
          searchTerm: action.term
        });
      }
    case announcements.GET_ITEMS_COMPLETE_PAGED:
      {
        return {
          ...state,
          announcements: mergeAnnouncements(state.announcements, action.payload),
          maxItems: action.maxCount,
          loading: false,
          error: '',
          success: ''
        };
      }
    case announcements.GET_ITEMS_FAILED_PAGED:
      {
        return {
          ...state,
          announcements: [],
          loading: false,
          error: action.payload,
          success: ''
        };
      }
    case announcements.GET_ITEMS:
      {
        return {
          ...state,
          announcements: [],
          loading: true
        };
      }
    case announcements.GET_ITEMS_COMPLETE:
      {
        return {
          ...state,
          announcements: action.payload,
          loading: false,
          error: '',
          success: ''
        };
      }
    case announcements.GET_ITEMS_FAILED:
      {
        return {
          ...state,
          announcements: [],
          loading: false,
          error: action.payload,
          success: ''
        };
      }

    case announcements.GET_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case announcements.GET_ITEM_COMPLETE:
      {
        return {
          ...state,
          announcements: replaceItem(state.announcements, action.payload),
          selectedAnnouncement: action.payload,
          loading: false,
          error: '',
          success: ''
        };
      }
    case announcements.GET_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedAnnouncement: initialState.selectedAnnouncement,
          success: ''
        };
      }

    case announcements.UPDATE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case announcements.UPDATE_ITEM_COMPLETE:
      {
        return {
          ...state,
          announcements: replaceItem(state.announcements, action.payload),
          selectedAnnouncement: action.payload,
          loading: false,
          error: '',
          success: 'Updated Team'
        };
      }
    case announcements.UPDATE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedAnnouncement: initialState.selectedAnnouncement,
          success: ''
        };
      }
      case announcements.DELETE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
      case announcements.DELETE_ITEM_COMPLETE:
      {
        return {
          ...state,
          announcements: removeItem(state.announcements, action.payload),
          maxItems: state.announcements.length,
          loading: false,
          selectedAnnouncement: initialState.selectedAnnouncement,
          error: '',
          success: 'Deleted Announcement'
        };
      }
      case announcements.DELETE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedAnnouncement: initialState.selectedAnnouncement,
          success: ''
        };
      }
    case announcements.GET_BASE_ITEM:
      {
        return {
          ...state,
          loading: true,
          error: '',
          success: ''
        };
      }
    case announcements.CREATE_ITEM:
      {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }

    case announcements.CLEAR_ERRORS:
      {
        return {
          ...state,
          error: '',
          success: ''
        };
      }
      case announcements.RESET_STORE:
      {
        return initialState;
      }

    default:
      {
        return state;
      }
  }
}

export const getMaxItems = (state: State) => state.maxItems;
export const getPage = (state: State) => state.page;
export const getAnnouncements = (state: State) => state.announcements;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
export const getAnnouncement = (state: State) => state.selectedAnnouncement;
export const getSearchTerm = (state: State) => state.searchTerm;
