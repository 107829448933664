import {
  Injectable,
  EventEmitter,
  Output
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Team
} from '../models/team';
import * as utils from '../../../shared/helpers/utils';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  Store
} from '@ngrx/store';
import {
  of
} from 'rxjs';

@Injectable()
export class TeamsService {
  @Output() IsEditing: EventEmitter < Boolean > = new EventEmitter();
  public page: number;
  public searchTerm: string;
  public isEditing = false;
  public overrideDialog = false;

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {
    this.store.select(fromCore.getTeamsPage).subscribe((page) => {
      this.page = page;
    });
    this.store.select(fromCore.getTeamsSearchTerm).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  toggleEditing(value: boolean) {
    this.isEditing = value;
    this.IsEditing.emit(this.isEditing);
  }

  getTeam(id: string) {
    return this.http.get < Team > ('/api/v2/teams/get/' + id + '?includes=Users.Roles,SopTemplate,Users.Nudges,Users.TrainingLogs,Users.TrainingEmailed');
  }

  getTeams() {
    return this.http.get < Team[] > ('/api/v2/teams/list?includes=Users&page=' + 0);
  }

  getTeamsPaged() {
    if (this.searchTerm !== '') {
      return this.http.get < Team[] > ('/api/v2/teams/list?includes=Users&pageSize=20&page=' + this.page + '&searchTerm=' + this.searchTerm, {
        observe: 'response'
      });
    } else {
      return this.http.get < Team[] > ('/api/v2/teams/list?includes=Users&pageSize=20&page=' + this.page, {
        observe: 'response'
      });
    }
  }

  updateTeam(team: Team) {
    return this.http.post < Team > ('/api/v2/teams/update', team);
  }

  addTeam(team: Team) {
    return this.http.post < Team > ('/api/v2/teams/add', team);
  }

  deleteTeam(id: string) {
    return this.http.delete < boolean > ('/api/v2/teams/delete/' + id);
  }

  hasTeamManager(id: string) {
    return this.http.get < boolean > ('/api/v2/teams/hasManager/' + id);
  }

  getBaseTeam() {
    const team: Team = {
      id: utils.EMPTY_GUID,
      name: '',
      shortName: '',
      userCount: 0,
      users: null,
      preferedLat: 0,
      preferedLng: 0,
      sopTemplateId: null,
      sopTemplate: null
    };
    return of({
      team
    });
  }
}
