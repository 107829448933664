import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from './core/core.module';
import { reducers } from './store/reducers';
import { CustomRouterStateSerializer } from './shared/helpers/utils';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ModalService } from './core/services/modal.service';
import { GlobalErrorHandler } from './shared/GlobalErrorHandler';
import { NudgeRightpanelComponent } from './layout/components/rightpanel/nudge-rightpanel.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  exports: [
    StoreModule,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
