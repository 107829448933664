import {
  Injectable, NgZone
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  AuthService
} from '../../../routes/auth/services/auth.service';
import * as Auth from '../../../core/store/actions/auth';
import * as ConfigAction from '../../../core/store/actions/config';
import {
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  exhaustMap,
  concat,
  tap,
  mergeMap,
  toArray,
  flatMap,
  take
} from 'rxjs/operators';
import { User } from '../../../routes/auth/models/user';
import { AuthenticationHelper } from '../../../shared/helpers/utils';
import { ConfigService } from '../../services/config.service';
import { Config } from '../../models/config';


@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions, private authService: AuthService,  private router: Router, private zone: NgZone, private configService: ConfigService) {}

  @Effect()
  public login$ = this.actions$.pipe(
    ofType(Auth.LOGIN),
    map ((action: Auth.Login) => action.payload),
    switchMap(auth => this.authService.login(auth).pipe(
      map((user: User) => { return new Auth.LoginSuccess({
        authToken: user.access_token,
        userRoles: user.userRoles
        });
      }),
      catchError((user: any) => {
        return of(new Auth.LoginFailure(user));
      })
    )));

    @Effect()
    public loginSuccess$ = this.actions$.pipe(
      ofType(Auth.LOGIN_SUCCESS),
      switchMap(res => [
        new ConfigAction.GetItems(),
        new Auth.GetProfile()
      ]),
      tap((res) => {
        if (res.type === Auth.GET_PROFILE) {
          this.configService.getConfig().subscribe((config: Config) => {
            if (AuthenticationHelper.IsOnboarder(config.roles)) {
              this.router.navigate(['users']);
            } else {
              this.router.navigate(['map']);
            }
          });
        }
      }));

  @Effect({
    dispatch: false
  })
  public loginRedirect$ = this.actions$.pipe(
    ofType(Auth.LOGIN_REDIRECT, Auth.LOGOUT),
    tap(authed => {
      this.zone.run(() => {
        /* Clear the token and user data on logout */
        this.authService.logout();
        this.router.navigate(['login']);
      });
    }));

  @Effect({
    dispatch: false
  })
  public loggedInRedirect$ = this.actions$.pipe(
    ofType(Auth.LOGGED_IN_REDIRECT),
    tap(authed => {
      this.configService.getConfig().subscribe((config: Config) => {
        if (AuthenticationHelper.IsOnboarder(config.roles)) {
          this.router.navigate(['users']);
        } else {
          this.router.navigate(['map']);
        }
      });
    }));

  @Effect()
  public profile$ = this.actions$.pipe(
    ofType(Auth.GET_PROFILE),
    exhaustMap(action => {
      return this.authService.profile().pipe(
        map(user => new Auth.GetProfileSuccess(user)),
        catchError(error => of (new Auth.GetProfileFailed()))
      );
    })
  );
}
