import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewContainerRef,
  NgZone,
  OnDestroy
} from '@angular/core';
import {
  Store
} from '@ngrx/store';
import {
  User
} from '../routes/users/models/user';
import {
  Role
} from '../routes/roles/models/role';
import {
  NavItem
} from '../core/models/nav';
import {
  Result
} from '../routes/search/models/search';
import * as fromRoot from '../core/store/reducers';
import * as fromCore from '../core/store/reducers';
import * as Auth from '../core/store/actions/auth';
import * as Nav from '../core/store/actions/config';
import * as Search from '../core/store/actions/search';
import {
  MapService
} from '../routes/map/map.service';
import {
  NotificationService,
  ConnectionState
} from '../core/services/notification.service';
import {
  Router
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { SidePanelService } from '../core/services/sidepanel.service';

@Component({
  selector: 'app-layout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {
  public viewContainerRef: ViewContainerRef;
  loggedIn$: Observable < boolean > ;
  user$: Observable < User > ;
  roles$: Observable < Role[] > ;
  navItems$: Observable < NavItem[] > ;
  searchResults$: Observable < Result[] > ;
  searchLoading$: Observable < boolean > ;
  testMode$: Observable < boolean > ;
  version$: Observable < string > ;
  searchError$: Observable < string > ;
  users$: Observable < User[] > ;
  selectedUser$: Observable < User > ;
  // An internal "copy" of the connection state stream used because
  //  we want to map the values of the original stream. If we didn't
  //  need to do that then we could use the service's observable
  //  right in the template.
  connectionState$: Observable < string > ;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(public sidePanelService: SidePanelService, public toastService: ToastrService, private store: Store < fromRoot.State > , private _mapService: MapService, private notificationService: NotificationService) {
    this.user$ = this.store.select(fromRoot.getUser);
    this.users$ = this.store.select(fromCore.getUsers);
    this.roles$ = this.store.select(fromCore.getRoles);
    this.loggedIn$ = this.store.select(fromRoot.getLoggedIn);
    this.navItems$ = this.store.select(fromCore.getNavItems);
    console.log(this.navItems$);
    this.testMode$ = this.store.select(fromCore.getTestMode);
    this.version$ = this.store.select(fromCore.getVersion);
    this.searchResults$ = this.store.select(fromCore.getSearchResults);
    this.searchLoading$ = this.store.select(fromCore.getSearchLoading);
    this.searchError$ = this.store.select(fromCore.getSearchError);
    this.selectedUser$ = this.store.select(fromCore.getUser);
    this.store.dispatch(new Auth.GetProfile());

    // Let's wire up to the signalr observables
    this.connectionState$ = this.notificationService.connectionState$
      .pipe(map((state: ConnectionState) => ConnectionState[state]));

    this.notificationService.error$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (error: any) => {
        //console.warn(error);
      },
      (error: any) => {
        //console.error('errors$ error', error);
      }
    );

    // Wire up a handler for the starting$ observable to log the
    // success/fail result
    this.notificationService.starting$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      () => {
        console.log('signalr service has been started');
      },
      () => {
        console.warn('signalr service failed to start!');
      }
    );

    this.sidePanelService.Logout
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {
      this.logout();
    });
  }

  ngOnInit() {
    this.store.dispatch(new Nav.GetItems());
    // Start the connection up!
    console.log('Starting the channel service');
    this.notificationService.start();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  logout() {
    this.store.dispatch(new Auth.Logout());
    this.toastService.clear();
    console.log('Stopping the channel service');
    this.notificationService.stop();
  }

  /* event triggered when typing into search box */
  search(event: string) {
    this.store.dispatch(new Search.Search(event));
  }

  /* Event hit when map icon clicked on user search result. */
  searchSelected(item: any) {
    this._mapService.searchClick.emit(item);
    this.store.dispatch(new Search.SearchClear());
  }
}
