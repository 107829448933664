import * as users from '../actions/users';
import * as _ from 'underscore';
import {
  User
} from '../../../routes/users/models/user';
import { SelectItem } from '../../models/selectItem';
import * as moment from 'moment';

export interface State {
  users: User[];
  selectedUser: User;
  selectUser: SelectItem[];
  loading: boolean;
  error: string;
  success: string;
  page: number;
  maxItems: number;
  searchTerm: string;
  includeDeletedUsers: boolean;
}

const initialState: State = {
  users: [],
  selectedUser: null,
  selectUser: [],
  loading: false,
  error: '',
  success: '',
  page: 1,
  maxItems: 0,
  searchTerm: '',
  includeDeletedUsers: false
};


function ConvertUsers(users: User[]): SelectItem[] {
  const result = new Array<SelectItem>();

  users.forEach(item => {
    result.push({
      id: item.id,
      itemName: item.firstName + ' ' + item.lastName
    });
  });

  return result.sort((a, b) => {
    if (a.itemName > b.itemName) {
      return 1;
    }

    if (a.itemName < b.itemName) {
      return -1;
    }

    return 0;
  });
}

// function updateUserLocation(users: User[], id: any, lat: any, lng: any) {
//   const index = users.findIndex(x => x.id === id);

//   if (index >= 0) {
//     const currentUser = users[index];
//     currentUser.lat = lat;
//     currentUser.lng = lng;
//     users[index] = currentUser;
//   }
//   const temp: User[] = [];

//   return users = temp.concat(users);
// }



function replaceItem(users: User[], user: User) {
  if(user !== null)
  {
    const index = _.findIndex(users, {
      id: user.id
    });
    let clonedUser = _.extend({}, user);
    clonedUser = cleanUser(clonedUser);

    if (index >= 0) {
      users[index] = clonedUser;
    } else {
      //if (user.email != null) {
        users.push(clonedUser);
      //}
    }
  }
  const temp: User[] = [];

  return users = temp.concat(users);
}

function deleteUser(users: User[], user: User) {
  return users.filter(item => item.id != user.id);
}

function cleanUser(user: User): User {
  if(user !== null)
  {
    if (user.team == null) {
      user.team = {
        id: '',
        name: '',
        shortName: '',
        userCount: 0,
        users: null,
        preferedLat: 0,
        preferedLng: 0,
        sopTemplate: null,
        sopTemplateId: null
      };
    }

    user.password = '';
    user.confirmPassword = '';
    }
  return user;
}

function mergeUsers(users: User[], newUsers: User[]) {
  users = users.concat(newUsers);

  // Remove any duplicate entried from the array;
  users = users.filter((user, index, self) => {
    return index === self.findIndex((t) => (t.id === user.id));
  });

  return users;
}

export function reducer(state = initialState, action: users.Actions): State {
  switch (action.type) {
    case users.GET_ITEMS_PAGED:
      {
        const page = action.payload;
        return Object.assign({}, state, {
          loading: true,
          page: page == null ? state.page : page,
          searchTerm: action.term,
          includeDeletedUsers: action.includeDeletedUsers
        });
      }
    case users.GET_ITEMS_COMPLETE_PAGED:
      {
        return {
          ...state,
          users: mergeUsers(state.users, action.payload),
          maxItems: action.maxCount,
          loading: false,
          error: '',
          success: ''
        };
      }
    case users.GET_ITEMS_FAILED_PAGED:
      {
        return Object.assign({}, state, {
          loading: false,
          error: action.payload,
          success: ''
        });
      }
    case users.GET_ITEMS:
      {
        return Object.assign({}, state, {
          users: [],
          loading: true,
          selectRoles: []
        });
      }
    case users.GET_ITEMS_COMPLETE:
      {
        return Object.assign({}, state, {
          users: action.payload,
          loading: false,
          error: '',
          success: '',
          selectUser: ConvertUsers(action.payload)
        });
      }
    case users.GET_ITEMS_FAILED:
      {
        return Object.assign({}, state, {
          users: [],
          loading: false,
          error: action.payload,
          selectRoles: [],
          success: ''
        });
      }

    case users.GET_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case users.GET_ITEM_COMPLETE:
      {
        return {
          ...state,
          users: replaceItem(state.users, action.payload),
          selectedUser: cleanUser(action.payload),
          loading: false,
          error: '',
          success: ''
        };
      }
    case users.GET_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedUser: initialState.selectedUser,
          success: ''
        };
      }

    case users.UPDATE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case users.UPDATE_ITEM_COMPLETE:
      {
        return {
          ...state,
          users: replaceItem(state.users, action.payload),
          selectedUser: cleanUser(action.payload),
          loading: false,
          error: '',
          success: 'Updated user at: ' + moment(new Date(), 'DD/MM/YYYY').format('LTS')
        };
      }
    case users.UPDATE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: ''
        };
      }

    case users.DEACTIVATE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case users.DEACTIVATE_ITEM_COMPLETE:
      {
        return {
          ...state,
          users: replaceItem(state.users, action.payload),
          selectedUser: cleanUser(action.payload),
          loading: false,
          error: '',
          success: 'User has been blocked'
        };
      }
    case users.DEACTIVATE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedUser: initialState.selectedUser,
          success: ''
        };
      }
    case users.ACTIVATE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case users.ACTIVATE_ITEM_COMPLETE:
      {
        return {
          ...state,
          users: replaceItem(state.users, action.payload),
          selectedUser: cleanUser(action.payload),
          loading: false,
          error: '',
          success: 'User has been unblocked'
        };
      }
    case users.ACTIVATE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedUser: initialState.selectedUser,
          success: ''
        };
      }
      case users.RESTORE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case users.RESTORE_ITEM_COMPLETE:
      {
        return {
          ...state,
          users: replaceItem(state.users, action.payload),
          selectedUser: cleanUser(action.payload),
          loading: false,
          error: '',
          success: 'User has been restored'
        };
      }
    case users.RESTORE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedUser: initialState.selectedUser,
          success: ''
        };
      }
    case users.GET_BASE_ITEM:
      {
        return {
          ...state,
          loading: true,
          error: '',
          success: ''
        };
      }
    case users.CREATE_ITEM:
      {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }
      case users.CREATE_ITEM_COMPLETE:
        {
          return {
            ...state,
            users: replaceItem(state.users, action.payload),
            selectedUser: cleanUser(action.payload),
            loading: false,
            error: '',
            success: 'Created user at: ' + moment(new Date(), 'DD/MM/YYYY').format('LTS')
          };
        }
      case users.CREATE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: ''
        };
      }
      case users.DELETE_ITEM:
        {
          return {
            ...state,
            loading: true,
          };
        }
      case users.DELETE_ITEM_COMPLETE:
        {
          return {
            ...state,
            users: replaceItem(state.users, action.payload),
            selectedUser: cleanUser(action.payload),
            loading: false,
            error: '',
            success: 'Deleted user'
          };
        }
      case users.DELETE_ITEM_FAILED:
        {
          return {
            ...state,
            loading: false,
            error: action.payload,
            selectedUser: initialState.selectedUser,
            success: ''
          };
        }
    case users.CLEAR_ERRORS:
      {
        return {
          ...state,
          error: '',
          success: ''
        };
      }
    case users.RESET_STORE:
      {
        return initialState;
      }
    case users.CLEAR_SELECTED_USER:
      {
        return {
          ...state,
          selectedUser: null
        }
      }
    default:
      {
        return state;
      }
  }
}

export const getUsers = (state: State) => state.users;
export const getMaxItems = (state: State) => state.maxItems;
export const getPage = (state: State) => state.page;
export const getSearchTerm = (state: State) => state.searchTerm;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
export const getUser = (state: State) => state.selectedUser;
export const getUsersForSelect = (state: State) => state.selectUser;
export const getIncludeDeletedUsers = (state: State) => state.includeDeletedUsers;
