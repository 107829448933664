import {
  Injectable
} from '@angular/core';
import {
  Store
} from '@ngrx/store';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import * as Auth from '../../core/store/actions/auth';
import * as fromRoot from '../../core/store/reducers';
import {
  AuthenticationHelper
} from '../helpers/utils';
import {
  AuthService
} from '../../routes/auth/services/auth.service';
import {
  User
} from '../../routes/auth/models/user';
import {
  Observable, BehaviorSubject
} from 'rxjs';
import {
  map,
  flatMap,
  catchError,
  finalize,
  filter,
  take,
  switchMap
} from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  loggedIn$: Observable<boolean>;
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject < string > = new BehaviorSubject < string > (null);
  constructor(private store: Store < fromRoot.State > , private authService: AuthService) {
    this.loggedIn$ = this.store.select(fromRoot.getLoggedIn);
  }

  intercept(req: HttpRequest < any > , next: HttpHandler): Observable<HttpEvent<any>> {
    if (!AuthenticationHelper.hasAuthorization() && AuthenticationHelper.hasAuthorizationRefresh() && !this.authService.processing && req.url !== '/api/account/login') {
        this.authService.processing = true;
        return this.authService.refreshToken().pipe(flatMap(
            (newToken: User) => {
                this.authService.publish(newToken);
                return next.handle(this.addToken(req));
            }
        ), catchError((error) => {
            this.authService.publish({});
            return Observable.throw(this.logoutUser());
        }), finalize(() => {
          this.authService.processing = false;
        }));
    } else if (req.url === '/api/account/login') {
        return next.handle(req);
    }

    if (this.authService.processing) {
        return this.authService.tokenRefreshed.pipe(flatMap(
            () => {
                return next.handle(req);
            }
        ));
    } else {
        return next.handle(req);
    }
  }

  addToken(req: HttpRequest < any > ): HttpRequest < any > {
    const newToken = AuthenticationHelper.getBearerToken();
    if (newToken !== null && newToken.access_token !== '') {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + newToken.access_token
        }
      });
    }

    return req;
  }

  handle401Error(req: HttpRequest < any > , next: HttpHandler) {
    const currentToken =  AuthenticationHelper.getBearerToken();

    if (currentToken != null && currentToken.access_token !== '') {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken().pipe(
              finalize(() => {
                this.isRefreshingToken = false;
              }),
              map((newToken: User) => {
                if (newToken) {
                  this.tokenSubject.next(newToken.access_token);
                  return next.handle(this.addToken(req));
                }
                // If we don't get a new token, we are in trouble so logout.
                return this.logoutUser();
              }),
              catchError(error => Observable.throw(this.logoutUser())));
          } else {
            return this.tokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(token => {
                return next.handle(this.addToken(req));
              }));
          }
    }
  }

  logoutUser() {
    this.store.dispatch(new Auth.Logout());
  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.store.dispatch(new Auth.Logout());
    }

    return Observable.throw(error);
  }
}
