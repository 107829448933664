import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef
  } from '@angular/core';
import { HelpMessage } from '../../../../routes/help/models/helpMessage';
import { trigger, transition, style, animate } from '@angular/animations';
  
  export type STATES = 'hidden' | 'shown';
  
  @Component({
    selector: 'app-sop-template-5',
    templateUrl: './sop-template-5.component.html',
    animations: [
      trigger(
        'enterAnimation', [
          transition(':enter', [
            style({ opacity: 0}),
            animate('1ms', style({ opacity: 1}))
          ]),
          transition(':leave', [
            style({ opacity: 1}),
            animate('1ms', style({ opacity: 0}))
          ])
        ]
      )
    ]
  })
  export class SopTemplate5Component implements OnInit {
    @Input() helpMessage: HelpMessage;
    @Input() previewMode: boolean;
    @Input() collapsableSteps: boolean;
    step2State: number;
    step3State: number;


    @ViewChild('step21', {static: false}) step201element: ElementRef
    @ViewChild('step22', {static: false}) step202element: ElementRef

    @ViewChild('step31', {static: false}) step301element: ElementRef
    @ViewChild('step32', {static: false}) step302element: ElementRef

    ngOnInit(): void {

    }

    SelectStep2Choice(state: number): void {
      if((state == 1 || state == 2) && this.step3State !== 0) {
        this.step3State = 0;
      }

      this.step2State = state;

      if(state === 1 && this.step201element !== undefined) {
        setTimeout(() => {
          this.step201element.nativeElement.classList.add('open');
          this.step201element.nativeElement.scrollIntoView();
        }, 1000)
      
      }

      if(state === 2 && this.step202element !== undefined) {
        setTimeout(() => {
          this.step202element.nativeElement.classList.add('open');
          this.step202element.nativeElement.scrollIntoView();
        }, 1000)
       
      }
    }

    SelectStep3Choice(state: number): void {

      if(state === 1 && this.step301element !== undefined) {
        setTimeout(() => {
          this.step301element.nativeElement.classList.add('open');
          this.step301element.nativeElement.scrollIntoView();
        }, 1000)
      }

      if(state === 2 && this.step302element !== undefined) {
        setTimeout(() => {
          this.step302element.nativeElement.classList.add('open');
          this.step302element.nativeElement.scrollIntoView();
        }, 1000)
      }

      this.step3State = state;
    }



    getUsersName(): string {
      if(this.previewMode) {
        return '[Name]';
      }

      if((this.helpMessage !== null && this.helpMessage !== undefined) && (this.helpMessage.user !== null && this.helpMessage.user !== undefined)) {
        return this.helpMessage.user.firstName + " " + this.helpMessage.user.lastName;
      }
    }

    getUsersPhoneNumber(): string {
      if(this.previewMode) {
        return '[Phone Number]';
      }

      if((this.helpMessage !== null && this.helpMessage !== undefined) && (this.helpMessage.user !== null && this.helpMessage.user !== undefined)) {
        return this.helpMessage.user.phoneNumber;
      }
    }

    getUsersLocation(): string {
      if(this.previewMode) {
        return '[Location Name]';
      }

      if((this.helpMessage !== null && this.helpMessage !== undefined) && (this.helpMessage.location !== null && this.helpMessage.location !== undefined)) {
        return this.helpMessage.location.name;
      } else {
        return this.helpMessage.geoLookupLocation;
      }
    }
  }
  