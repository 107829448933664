import { Action } from '@ngrx/store';
import { Location } from '../../../routes/locations/models/location';


export const GET_ITEMS_PAGED = '[Locations] Get Items Paged';
export const GET_ITEMS_COMPLETE_PAGED = '[Locations] Get Items Complete Paged';
export const GET_ITEMS_FAILED_PAGED = '[Locations] Get Items Failed Paged';

export class GetItemsPaged implements Action {
  readonly type = GET_ITEMS_PAGED;
  constructor(public payload: number, public term: string) {}
}
export class GetItemsCompletePaged implements Action {
  readonly type = GET_ITEMS_COMPLETE_PAGED;

  constructor(public payload: Location[], public maxCount: number) {}
}
export class GetItemsFailedPaged implements Action {
  readonly type = GET_ITEMS_FAILED_PAGED;

  constructor(public payload: string) {}
}
export type GetItemsActionsPaged = GetItemsPaged | GetItemsCompletePaged | GetItemsFailedPaged;

export const GET_ITEMS = '[Locations] Get Items';
export const GET_ITEMS_COMPLETE = '[Locations] Get Items Complete';
export const GET_ITEMS_FAILED = '[Locations] Get Items Failed';

export class GetItems implements Action {
  readonly type = GET_ITEMS;
}
export class GetItemsComplete implements Action {
  readonly type = GET_ITEMS_COMPLETE;

  constructor(public payload: Location[]) {}
}
export class GetItemsFailed implements Action {
  readonly type = GET_ITEMS_FAILED;

  constructor(public payload: string) {}
}
export type GetItemsActions = GetItems | GetItemsComplete | GetItemsFailed;

export const GET_ITEM = '[Locations] Get Item';
export const GET_BASE_ITEM = '[Locations] Get Base Item';
export const GET_ITEM_COMPLETE = '[Locations] Get Item Complete';
export const GET_ITEM_FAILED = '[Locations] Get Item Failed';

export class GetItem implements Action {
  readonly type = GET_ITEM;

  constructor(public payload: string) {}
}
export class GetBaseItem implements Action {
  readonly type = GET_BASE_ITEM;
}
export class GetItemComplete implements Action {
  readonly type = GET_ITEM_COMPLETE;

  constructor(public payload: Location) {}
}
export class GetItemFailed implements Action {
  readonly type = GET_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type GetItemActions = GetItem | GetBaseItem | GetItemComplete | GetItemFailed;

export const CREATE_ITEM = '[Locations] Create Item';
export const UPDATE_ITEM = '[Locations] Update Item';
export const UPDATE_ITEM_COMPLETE = '[Locations] Update Item Complete';
export const UPDATE_ITEM_FAILED = '[Locations] Update Item Failed';

export class CreateItem implements Action {
  readonly type = CREATE_ITEM;

  constructor(public payload: Location) {}
}
export class UpdateItem implements Action {
  readonly type = UPDATE_ITEM;

  constructor(public payload: Location) {}
}
export class UpdateItemComplete implements Action {
  readonly type = UPDATE_ITEM_COMPLETE;

  constructor(public payload: Location) {}
}
export class UpdateItemFailed implements Action {
  readonly type = UPDATE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type UpdateItemActions = CreateItem | UpdateItem | UpdateItemComplete | UpdateItemFailed;

export const RESET_STORE = '[Locations] Reset Store';

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}

export type Actions = GetItemActions | GetItemsActions | UpdateItemActions | ResetStore | GetItemsActionsPaged;
