import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalService } from "../../../core/services/modal.service";
import { HelpMessage } from "../../help/models/helpMessage";
import { QuickSopRequestState, StandDownRequestState } from "../../help/models/standDownModel";
import { HelpService } from "../../help/services/help.service";
import { AuthService } from "../services/auth.service";
import {
  Store
} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import * as HelpMessages from '../../../core/store/actions/help';
import * as Config from '../../../core/store/actions/config';
import { ConfigService } from "../../../core/services/config.service";

@Component({
    selector: 'app-quick-sop',
    templateUrl: './quick-sop.component.html',
  })
  export class QuickSopComponent  {

    id: string;
    email: string;
    state: QuickSopRequestState;
    loading: boolean;
    helpMessage: HelpMessage;
    styles: any[];
    standDownRequestID: string;

    private ngUnsubscribe: Subject < any > = new Subject();


    constructor(private store: Store < fromRoot.State >, private configService: ConfigService, private modalService: ModalService, private helpService: HelpService, private formBuilder: FormBuilder, private route: ActivatedRoute, private http: HttpClient, private router: Router) {
      
      this.configService.getMapStyles().subscribe((result) => {
        this.styles = result.map.styles;
      });

      this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.loading = true;
        this.id = params['id'];
  
        this.helpService.checkQuickSopRequest(this.id)
        .subscribe((result) => {
          this.email = result.email;
          this.state = result.state;
          this.helpMessage = result.helpMessage;
          this.standDownRequestID = result.standDownRequestID;
          this.loading = false;
        });
      });
    }

    OnStandDownClick(){
      this.router.navigate(['standdown'], {queryParams : { id: this.standDownRequestID}});
    }

    HasValidStandDownLink(){
      if(this.standDownRequestID === "" || this.standDownRequestID == "undefined"){
        return false;
      }
      return true;
    }
  }