import { Action } from '@ngrx/store';

import { ImportInfo } from '../../models/import/importinfo';

export const GET_USER_IMPORTER = '[Users] Get Importer';
export const GET_USER_IMPORTER_COMPLETE = '[Users] Get Importer Complete';
export const GET_USER_IMPORTER_FAILED = '[Users] Get Importer Failed';
export const UPLOAD_USER_IMPORT = '[Users] Upload Import';
export const UPLOAD_USER_IMPORT_COMPLETE = '[Users] Upload Import Complete';
export const UPLOAD_USER_IMPORT_FAILED = '[Users] Upload Import Failed';

export class GetUserImporter implements Action {
  readonly type = GET_USER_IMPORTER;
}
export class GetUserImporterComplete implements Action {
  readonly type = GET_USER_IMPORTER_COMPLETE;

  constructor(public payload: ImportInfo) {}
}
export class GetUserImporterFailed implements Action {
  readonly type = GET_USER_IMPORTER_FAILED;

  constructor(public payload: string) {}
}
export class UploadUserImport implements Action {
  readonly type = UPLOAD_USER_IMPORT;

  constructor(public payload: string) {}
}
export class UploadUserImportComplete implements Action {
  readonly type = UPLOAD_USER_IMPORT_COMPLETE;

  constructor(public payload: ImportInfo) {}
}
export class UploadUserImportFailed implements Action {
  readonly type = UPLOAD_USER_IMPORT_FAILED;

  constructor(public payload: string) {}
}
export type Actions = GetUserImporter | GetUserImporterComplete | GetUserImporterFailed | UploadUserImport | UploadUserImportComplete | UploadUserImportFailed;
