import {
  NgModule
} from '@angular/core';
import {
  SearchModule
} from '../routes/search/search.module';
import {
  ConfigService
} from '../core/services/config.service';
import {
  NotFoundPageComponent
} from './components/not-found-page.component';
import {
  MainLayoutComponent
} from './components/main-layout.component';
import {
  AlertsComponent
} from './components/alerts.component';
import {
  SideNavComponent
} from './components/nav/sidebar/sidenav.component';
import {
  TopNavComponent
} from './components/nav/header/topnav.component';
import {
  AccountComponent
} from './components/nav/header/account.component';
import {
  BaseRightPanelComponent
} from './components/rightpanel/base-rightpanel.component';
import {
  SettingsInfoPanelComponent
} from './components/rightpanel/settings-rightpanel.component';
import {
  MapService
} from '../routes/map/map.service';
import {
  SettingsStorageService
} from '../core/services/settings.service';
import {
  ShowErrorsComponent
} from './components/show-errors.component';
import {
  BaseMainContentScrollComponent
} from './components/main/base-main-content-scroll.component';
import {
  SharedModule
} from '../shared/shared.module';
import {
  HelpInfoPanelComponent
} from './components/rightpanel/help-info.component';
import {
  CostEstimatePanelComponent
}  from './components/rightpanel/cost-estimate.component';
import {
  CarbonFootprintPanelComponent
} from './components/rightpanel/carbon-footprint.component';
import{
  HelpMessageNoteComponent
}from './components/rightpanel/help-message-note.component';
import{
  NudgeRightpanelComponent
}from './components/rightpanel/nudge-rightpanel.component';
import {
  HelpToastComponent
} from './components/help-toast.component';
import {
  LayoutComponent
} from './layout.component';
import {
  LocationInfoPanelComponent
} from './components/rightpanel/location-info.component';
import {
  UserInfoPanelComponent
} from './components/rightpanel/user-info.component';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { GlobalUserListComponent } from './components/rightpanel/global-user-list.component';
import { UnauthorizedComponent } from './components/unauthorized.component';
import { ConfirmLeaveComponent } from './components/modals/confirm-leave.component';
import { OkCloseComponent } from './components/modals/ok-close.component';


export const COMPONENTS = [
  NotFoundPageComponent,
  UnauthorizedComponent,
  ShowErrorsComponent,
  LayoutComponent,
  AlertsComponent,

  // Modals
  ConfirmLeaveComponent,
  OkCloseComponent,

  // Left Nav
  SideNavComponent,

  // Top Nav
  TopNavComponent,
  AccountComponent,

  

  // Main Panel
  BaseMainContentScrollComponent,
  MainLayoutComponent,
  LayoutComponent,
  // Right Panels
  BaseRightPanelComponent,
  SettingsInfoPanelComponent,
  UserInfoPanelComponent,
  HelpInfoPanelComponent,
  CarbonFootprintPanelComponent,
  CostEstimatePanelComponent,
  LocationInfoPanelComponent,
  GlobalUserListComponent,
  HelpToastComponent,
  HelpMessageNoteComponent,
  NudgeRightpanelComponent
];

@NgModule({
  imports: [
    ToastrModule.forRoot({ positionClass: 'inline' }),
    ToastContainerModule,
    SearchModule,
    SharedModule
  ],
  providers: [
    MapService,
    ConfigService,
    SettingsStorageService
  ],
  entryComponents: [
    HelpToastComponent,
    ConfirmLeaveComponent,
    OkCloseComponent
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class LayoutModule {}
