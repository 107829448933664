import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  OnInit
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

export type STATES = 'hidden' | 'shown';

@Component({
  selector: 'app-fade-in',
  templateUrl: './fade-in.component.html',
  animations: [
    trigger('fadeIn', [
      state('hidden', style({
        opacity: 0
      })),
      state('shown', style({
        opacity: 1
      })),
      transition('* <=> *', animate('0.15s linear'))
    ])
  ]
})
export class FadeInComponent implements OnInit, OnChanges {
  @Input() state: boolean;
  oldState: boolean;

  animating = false;
  state$: EventEmitter < STATES > = new EventEmitter < STATES > (true);

  ngOnInit() {
    this.updateState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['state'] != null) {
      this.oldState = this.state;
    }

    this.updateState();
  }

  updateState() {
    const state: STATES = 'hidden';

    if (this.animating) {
      return this.getStateFromBool(this.oldState);
    }

    return this.getStateFromBool(this.state);
  }

  emitState(state: STATES) {
    this.state$.emit(state);
  }

  getStateFromBool(state: boolean) {
    if (state) {
      return 'shown';
    }

    return 'hidden';
  }

  animationStarted($event: any) {
    this.animating = true;
    this.updateState();
  }

  animationDone($event: any) {
    this.animating = false;
    this.updateState();
  }
}
