import {
  Action
} from '@ngrx/store';
import { Team } from '../../../routes/teams/models/team';

export const GET_ITEMS_PAGED = '[Teams] Get Items Paged';
export const GET_ITEMS_COMPLETE_PAGED = '[Teams] Get Items Complete Paged';
export const GET_ITEMS_FAILED_PAGED = '[Teams] Get Items Failed Paged';

export class GetItemsPaged implements Action {
  readonly type = GET_ITEMS_PAGED;
  constructor(public payload: number, public term: string) {}
}
export class GetItemsCompletePaged implements Action {
  readonly type = GET_ITEMS_COMPLETE_PAGED;

  constructor(public payload: Team[], public maxCount: number) {}
}
export class GetItemsFailedPaged implements Action {
  readonly type = GET_ITEMS_FAILED_PAGED;

  constructor(public payload: string) {}
}

export type GetItemsActionsPaged = GetItemsPaged | GetItemsCompletePaged | GetItemsFailedPaged;

export const GET_ITEMS = '[Teams] Get Items';
export const GET_ITEMS_COMPLETE = '[Teams] Get Items Complete';
export const GET_ITEMS_FAILED = '[Teams] Get Items Failed';

export class GetItems implements Action {
  readonly type = GET_ITEMS;
}
export class GetItemsComplete implements Action {
  readonly type = GET_ITEMS_COMPLETE;

  constructor(public payload: Team[]) {}
}
export class GetItemsFailed implements Action {
  readonly type = GET_ITEMS_FAILED;

  constructor(public payload: string) {}
}
export type GetItemsActions = GetItems | GetItemsComplete | GetItemsFailed;

export const GET_ITEM = '[Teams] Get Item';
export const GET_ITEM_COMPLETE = '[Teams] Get Item Complete';
export const GET_ITEM_FAILED = '[Teams] Get Item Failed';

export class GetItem implements Action {
  readonly type = GET_ITEM;

  constructor(public payload: string) {}
}
export class GetItemComplete implements Action {
  readonly type = GET_ITEM_COMPLETE;

  constructor(public payload: Team) {}
}
export class GetItemFailed implements Action {
  readonly type = GET_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type GetItemActions = GetItem | GetBaseItem | GetItemComplete | GetItemFailed;

export const UPDATE_ITEM = '[Teams] Update Item';
export const UPDATE_ITEM_COMPLETE = '[Teams] Update Item Complete';
export const UPDATE_ITEM_FAILED = '[Teams] Update Item Failed';

export class UpdateItem implements Action {
  readonly type = UPDATE_ITEM;

  constructor(public payload: Team) {}
}
export class UpdateItemComplete implements Action {
  readonly type = UPDATE_ITEM_COMPLETE;

  constructor(public payload: Team) {}
}
export class UpdateItemFailed implements Action {
  readonly type = UPDATE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type UpdateItemActions = UpdateItem | UpdateItemComplete | UpdateItemFailed;

export const CREATE_ITEM = '[Teams] Create Item';
export const GET_BASE_ITEM = '[Teams] Get Base Item';


export class CreateItem implements Action {
  readonly type = CREATE_ITEM;

  constructor(public payload: Team) {}
}

export class GetBaseItem implements Action {
  readonly type = GET_BASE_ITEM;
}
export type CreateItemActions = CreateItem | GetBaseItem;

export const CLEAR_ERRORS = '[Teams] Clear Errors';

export class ClearErrors implements Action {
  readonly type = CLEAR_ERRORS;
}


export const DELETE_ITEM = '[Teams] Delete Item';
export const DELETE_ITEM_COMPLETE = '[Teams] Delete Item Complete';
export const DELETE_ITEM_FAILED = '[Teams] Delete Item Failed';

export class DeleteItem implements Action {
  readonly type = DELETE_ITEM;

  constructor(public payload: Team) {}
}
export class DeleteItemComplete implements Action {
  readonly type = DELETE_ITEM_COMPLETE;

  constructor(public payload: Team) {}
}
export class DeleteItemFailed implements Action {
  readonly type = DELETE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type DeleteItemActions = DeleteItem | DeleteItemComplete | DeleteItemFailed;

export const HAS_MANAGER = '[Teams] Has Manager';
export const HAS_MANAGER_COMPLETE = '[Teams] Has Manager Complete';
export const HAS_MANAGER_FAILED = '[Teams] Has Manager Failed';

export class HasManager implements Action {
  readonly type = HAS_MANAGER;

  constructor(public payload: string) {}
}
export class HasManagerComplete implements Action {
  readonly type = HAS_MANAGER_COMPLETE;

  constructor(public payload: boolean) {}
}
export class HasManagerFailed implements Action {
  readonly type = HAS_MANAGER_FAILED;

  constructor(public payload: string) {}
}
export type HasMangerActions = HasManager | HasManagerComplete | HasManagerFailed;

export const RESET_STORE = '[Teams] Reset Store';

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}




export type Actions = GetItemActions | GetItemsActions | UpdateItemActions | CreateItemActions | ClearErrors | HasMangerActions| GetItemsActionsPaged | DeleteItemActions | ResetStore;
