import {
  Action
} from '@ngrx/store';
import {
  Announcement
} from '../../../routes/messaging/models/announcement';

export const GET_ITEMS_PAGED = '[Announcements] Get Items Paged';
export const GET_ITEMS_COMPLETE_PAGED = '[Announcements] Get Items Complete Paged';
export const GET_ITEMS_FAILED_PAGED = '[Announcements] Get Items Failed Paged';

export class GetItemsPaged implements Action {
  readonly type = GET_ITEMS_PAGED;
  constructor(public payload: number, public term: string) {}
}
export class GetItemsCompletePaged implements Action {
  readonly type = GET_ITEMS_COMPLETE_PAGED;
  constructor(public payload: Announcement[], public maxCount: number) {}
}
export class GetItemsFailedPaged implements Action {
  readonly type = GET_ITEMS_FAILED_PAGED;

  constructor(public payload: string) {}
}

export type GetItemsActionsPaged = GetItemsPaged | GetItemsCompletePaged | GetItemsFailedPaged;

export const GET_ITEMS = '[Announcements] Get Items';
export const GET_ITEMS_COMPLETE = '[Announcements] Get Items Complete';
export const GET_ITEMS_FAILED = '[Announcements] Get Items Failed';

export class GetItems implements Action {
  readonly type = GET_ITEMS;
}
export class GetItemsComplete implements Action {
  readonly type = GET_ITEMS_COMPLETE;

  constructor(public payload: Announcement[]) {}
}
export class GetItemsFailed implements Action {
  readonly type = GET_ITEMS_FAILED;

  constructor(public payload: string) {}
}
export type GetItemsActions = GetItems | GetItemsComplete | GetItemsFailed;

export const GET_ITEM = '[Announcements] Get Item';
export const GET_ITEM_COMPLETE = '[Announcements] Get Item Complete';
export const GET_ITEM_FAILED = '[Announcements] Get Item Failed';

export class GetItem implements Action {
  readonly type = GET_ITEM;

  constructor(public payload: string) {}
}
export class GetItemComplete implements Action {
  readonly type = GET_ITEM_COMPLETE;

  constructor(public payload: Announcement) {}
}
export class GetItemFailed implements Action {
  readonly type = GET_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type GetItemActions = GetItem | GetBaseItem | GetItemComplete | GetItemFailed;

export const UPDATE_ITEM = '[Announcements] Update Item';
export const UPDATE_ITEM_COMPLETE = '[Announcements] Update Item Complete';
export const UPDATE_ITEM_FAILED = '[Announcements] Update Item Failed';

export class UpdateItem implements Action {
  readonly type = UPDATE_ITEM;

  constructor(public payload: Announcement) {}
}
export class UpdateItemComplete implements Action {
  readonly type = UPDATE_ITEM_COMPLETE;

  constructor(public payload: Announcement) {}
}
export class UpdateItemFailed implements Action {
  readonly type = UPDATE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type UpdateItemActions = UpdateItem | UpdateItemComplete | UpdateItemFailed;

export const CREATE_ITEM = '[Announcements] Create Item';
export const GET_BASE_ITEM = '[Announcements] Get Base Item';


export class CreateItem implements Action {
  readonly type = CREATE_ITEM;

  constructor(public payload: Announcement) {}
}

export class GetBaseItem implements Action {
  readonly type = GET_BASE_ITEM;
}
export type CreateItemActions = CreateItem | GetBaseItem;

export const CLEAR_ERRORS = '[Announcements] Clear Errors';

export class ClearErrors implements Action {
  readonly type = CLEAR_ERRORS;
}


export const DELETE_ITEM = '[Announcements] Delete Item';
export const DELETE_ITEM_COMPLETE = '[Announcements] Delete Item Complete';
export const DELETE_ITEM_FAILED = '[Announcements] Delete Item Failed';

export class DeleteItem implements Action {
  readonly type = DELETE_ITEM;

  constructor(public payload: Announcement) {}
}
export class DeleteItemComplete implements Action {
  readonly type = DELETE_ITEM_COMPLETE;

  constructor(public payload: Announcement) {}
}
export class DeleteItemFailed implements Action {
  readonly type = DELETE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type DeleteItemActions = DeleteItem | DeleteItemComplete | DeleteItemFailed;

export const RESET_STORE = '[Announcements] Reset Store';

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}


export type Actions = GetItemActions | GetItemsActions | UpdateItemActions | CreateItemActions | ClearErrors | GetItemsActionsPaged | DeleteItemActions | ResetStore;
