export class AuthToken {
    access_token: string;
    refresh_token: RefreshToken;
    token_type: string;
    expires_in: number;
    expires: Date;
    issued: Date;
    expires_on: Date;
}

export class RefreshToken {
    constructor(id: string, issuedUtc: Date, expiresUtc: Date) {
        this.id = id;
        this.issuedUtc = issuedUtc;
        this.expiresUtc = expiresUtc;
    }

    id: string;
    issuedUtc: Date;
    expiresUtc: Date;
}
