import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  RolesService
} from '../../../routes/roles/services/roles.service';
import * as roles from '../actions/roles';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import {
  ObservableHelper
} from '../../../shared/helpers/observableHelper';
import { ErrorHelper } from '../../../shared/helpers/utils';


@Injectable()
export class RolesEffects {

  @Effect()
  public fetchAvailableRoles: Observable < Action > = this.actions$.pipe(
    ofType(roles.GET_AVAILABLE_ROLES),
    switchMap(payload => this.roleService.getAvailableRoles().pipe(
      map(data => ({
        type: roles.GET_AVAILABLE_ROLES_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: roles.GET_AVAILABLE_ROLES_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public getRolesPaged$: Observable < Action > = this.actions$.pipe(
    ofType(roles.GET_ROLES_PAGED),
    switchMap(action => this.roleService.getRolesPaged().pipe(
      map(data => ({
        type: roles.GET_ROLES_PAGED_COMPLETE,
        payload: data.body,
        maxCount: parseInt(JSON.parse(data.headers.get('X-Pagination')).totalCount)
      })),
      catchError((error) => of ({
        type: roles.GET_ROLES_PAGED_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetchAvailableRolesForDropdown: Observable < Action > = this.actions$.pipe(
    ofType(roles.GET_AVAILABLE_ROLES_FOR_SELECT),
    switchMap(payload => this.roleService.getAvailableRoles().pipe(
      map(data => ({
        type: roles.GET_AVAILABLE_ROLES_COMPLETE_FOR_SELECT,
        payload: data
      })),
      catchError((error) => of ({
        type: roles.GET_AVAILABLE_ROLES_FAILED_FOR_SELECT,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(roles.GET_ROLE),
    map((action: roles.GetRole) => action.payload),
    switchMap(payload => this.roleService.getRole(payload).pipe(
      map(data => ({
        type: roles.GET_ROLE_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: roles.GET_ROLE_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public update$: Observable < Action > = this.actions$.pipe(
    ofType(roles.UPDATE_ROLES),
    map((action: roles.UpdateRoles) => action.payload),
    switchMap(payload => this.roleService.updateRole(payload).pipe(
      map(data => ({
        type: roles.UPDATE_ROLES_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: roles.UPDATE_ROLES_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  constructor(private actions$: Actions, private roleService: RolesService, private router: Router) {}
}
