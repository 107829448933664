import { Action } from '@ngrx/store';
import { User } from '../../../routes/users/models/user';
import { Authenticate } from '../../../routes/auth/models/user';
import { AuthenticationHelper } from '../../../shared/helpers/utils';

export const LOGIN = '[Auth] Login';
export const LOGOUT = '[Auth] Logout';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_COMPLETE = '[Auth] Login Complete';
export const LOGIN_FAILURE = '[Auth] Login Failure';
export const LOGIN_REDIRECT = '[Auth] Login Redirect';
export const LOGGED_IN_REDIRECT = '[Auth] Logged In Redirect';
export const GET_PROFILE = '[Auth] Get Profile';
export const GET_PROFILE_SUCCESS = '[Auth] Get Profile Success';
export const GET_PROFILE_FAILED = '[Auth] Get Profile Failed';

export class Login implements Action {
  readonly type = LOGIN;
  constructor(public payload: Authenticate) {}
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;
  constructor(public payload: { authToken: string, userRoles: string}) {}
}

export class LoginFailure implements Action {
  readonly type = LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class LoginComplete implements Action {
  readonly type = LOGIN_COMPLETE;
}

export class LoginRedirect implements Action {
  readonly type = LOGIN_REDIRECT;
}

export class LoggedInRedirect implements Action {
  readonly type = LOGGED_IN_REDIRECT;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class GetProfile implements Action {
  readonly type = GET_PROFILE;
}

export class GetProfileSuccess implements Action {
  readonly type = GET_PROFILE_SUCCESS;
  constructor(public payload: User) {
    AuthenticationHelper.setCurrentUser(payload);
  }
}

export class GetProfileFailed implements Action {
  readonly type = GET_PROFILE_FAILED;
}

export type LoginActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | LoggedInRedirect
  | Logout;

export type ProfileActions = GetProfile | GetProfileSuccess | GetProfileFailed;

export type Actions = LoginActions | ProfileActions;
