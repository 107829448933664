import * as utils from '../../../shared/helpers/utils';
import * as search from '../actions/search';
import * as _ from 'underscore';
import { Result } from '../../../routes/search/models/search';

export interface State {
  results: Result[];
  error: string;
  loading: boolean;
}

const initialState: State = {
  results: [],
  error: '',
  loading: false
};

export function reducer(state = initialState, action: search.Actions): State {
  switch (action.type) {
    case search.SEARCH: {
      return {
        ...state,
        results: [],
        error: '',
        loading: true
      };
    }

    case search.SEARCH_COMPLETE: {
      return {
        ...state,
        results: action.payload,
        error: '',
        loading: false
      };
    }

    case search.SEARCH_FAILED: {
      return {
        ...initialState,
        results: [],
        error: action.payload,
        loading: false
      };
    }

    case search.SEARCH_CLEAR: {
      return {
        ...state,
        results: [],
        error: '',
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getResults = (state: State) => state.results;
