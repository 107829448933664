import { Directive, Output, EventEmitter, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  constructor(private _elementRef : ElementRef) {
  }

  @Output()
  public clickOutside = new EventEmitter();

  @Input() 
  public clickOutsideActive: boolean = false;

  @ViewChild('side-panel', {static: false}) containerElement: ElementRef;

  private skipFirst = true;

  @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement || !this.clickOutsideActive) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
          var container = document.getElementsByClassName('side-panel')[0];

          if(container !== null && container !== undefined) {
            if(container.classList.contains('panel-in')) {
              this.clickOutside.emit(event);
            }
          }
        }
    }
}