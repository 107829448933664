import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  Authenticate
} from '../models/user';
import {
  GenericValidator
} from '../../../shared/validators/generic.validator';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable,
  Subject
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';

@Component({
  selector: 'app-login-form',
  templateUrl: './login.component.html',
  styles: [
    `
      :host {
        z-index: 1000;
      }

      .error-message {
        text-align: center;
      }
    `
  ]
})
export class LoginFormComponent implements OnInit, OnDestroy, AfterViewInit {
  isPending: boolean;
  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;
    if (isPending) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
  }

  @Input() errorMessage: string | null;

  @Output() submitted = new EventEmitter < Authenticate > ();

  @Output() stateChanged = new EventEmitter < number > ();

  // Use with the generic validation message class
  displayMessage: {
    [key: string]: string
  } = {};
  private validationMessages: {
    [key: string]: {
      [key: string]: string
    }
  };
  private genericValidator: GenericValidator;

  loginForm: FormGroup;

  private ngUnsubscribe: Subject < any > = new Subject();

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.validationMessages = {
      email: {
        required: 'Email is required',
        pattern: 'Invalid email'
      },
      password: {
        required: 'Password is required'
      }
    };

    //const emailPattern = '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.errorMessage = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Update the validation messages on value change callback */
    this.loginForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
    });
  }

  forgotPassword() {
    this.stateChanged.emit(2);
  }

  submit() {
    if (this.loginForm.valid) {
      this.submitted.emit(this.loginForm.value);
    }
  }
}
