import { NgModule } from '@angular/core';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MomentModule } from 'angular2-moment';
import { CheckinDatePipe } from '../shared/pipes/CheckinDatePipe.pipe';
import { CheckinTimePipe } from '../shared/pipes/CheckinTimePipe.pipe';
import { KeysPipe, ActiveUsers, Last30DaysUsers } from '../shared/pipes/enum.pipe';
import { TabsModule, ModalModule, TooltipModule, CollapseModule, PaginationModule, BsDatepickerModule  } from 'ngx-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatePipe, CommonModule, CurrencyPipe } from '@angular/common';
import { CountdownModule  } from 'ngx-countdown';
import { CounterDirective } from './directives/counter.directive';
import { UrlInterceptor } from './httpinterceptors/url.interceptor';
import { BearerTokenInterceptor } from './httpinterceptors/bearerToken-interceptor.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LocationInfoComponent } from '../layout/components/location-info.component';
import { UserInfoComponent } from '../layout/components/user-info.component';
import { BaseLeftPanelComponent } from '../layout/components/leftpanel/base-leftpanel.component';
import { FadeInComponent } from '../layout/components/fade-in.component';
import { BaseMainContentComponent } from '../layout/components/main/base-main-content.component';
import { BaseMainComponent } from '../layout/components/main/base-main.component';
import { BaseMainOptionsComponent } from '../layout/components/main/base-main-options.component';
import { ImportFormComponent } from '../layout/components/import-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamInfoComponent } from '../layout/components/team-info.component';
import { AuthGuard, AuthedGuard } from './guards/auth-guard.service';
import { RoleGuard } from './guards/role-gaurd.service';
import { RouterModule } from '@angular/router';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CookieService } from 'ng2-cookies';
import { FlotDirective } from './directives/flot/flot.directive';
import { CountUpDirective } from './directives/count';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SearchComponent } from '../routes/search/components/search.component';
import { SearchItemComponent } from '../routes/search/components/search-item.component';
import { ValidationContainerComponent } from './components/validation-container.component';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { RoleInfoComponent } from '../layout/components/role-info.component';
import { GroupByPipe } from './pipes/GroupByPipe.pipe';
import { ArraySortPipe } from './pipes/ArraySortPipe.pipe';
import { NestableModule } from './modules/nestable/nestable.module';
import { PendingChangesGuard } from './guards/pending-changes-guard.service';
import { ScrollToDirective } from './directives/scrollto.directive';
import { SopTemplate1Component } from '../layout/components/rightpanel/SopTemplates/sop-template-1.component';
import { SopTemplate2Component } from '../layout/components/rightpanel/SopTemplates/sop-template-2.component';
import { SopTemplate3Component } from '../layout/components/rightpanel/SopTemplates/sop-template-3.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AutoCloseDirective } from './directives/auto-close.directive';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SopTemplate4Component } from '../layout/components/rightpanel/SopTemplates/sop-template-4.component';
import { SopTemplate5Component } from '../layout/components/rightpanel/SopTemplates/sop-template-5.component';
import { SopTemplateLuhft1Component } from '../layout/components/rightpanel/SopTemplates/sop-template-luhft1.component';
import { DeletedUsersPipe } from './pipes/DeletedUsersPipe.pipe';
import { PrettyPrintPipe } from './pipes/PrettyPrintJsonPipe.pipe';
import { ScreenIntentPipe } from './pipes/ScreenIntentPipe.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const COMPONENTS = [
  SopTemplate1Component,
  SopTemplate2Component,
  SopTemplate3Component,
  SopTemplate4Component,
  SopTemplate5Component,
  SopTemplateLuhft1Component,
  LocationInfoComponent,
  UserInfoComponent,
  RoleInfoComponent,
  BaseLeftPanelComponent,
  FadeInComponent,
  BaseMainContentComponent,
  BaseMainComponent,
  BaseMainOptionsComponent,
  ImportFormComponent,
  TeamInfoComponent,
  AutoCloseDirective,
  CountUpDirective,
  ClickOutsideDirective,
  ScrollToDirective,
  SearchComponent,
  SearchItemComponent,
  ValidationContainerComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    HttpClientModule,
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AngularMyDatePickerModule,
    MomentModule,
    UiSwitchModule,
    ModalModule.forRoot(),
    VirtualScrollerModule,
    NgxDatatableModule,
    NgxChartsModule,
    AngularMultiSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBQPaQy5fXBE8ZNFSUWXtdfUUTVyprnotg',
      libraries: ["places"]
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    CountdownModule,
    NestableModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    KeysPipe,
    PrettyPrintPipe,
    ScreenIntentPipe,
    GroupByPipe,
    ArraySortPipe,
    DeletedUsersPipe,
    UiSwitchModule,
    CheckinTimePipe,
    CheckinDatePipe,
    ActiveUsers,
    Last30DaysUsers,
    HttpClientModule,
    TooltipModule,
    NgxDatatableModule,
    NgxChartsModule,
    CountdownModule,
    PaginationModule,
    TabsModule,
    CarouselModule,
    BsDropdownModule,
    BsDatepickerModule,
    AngularMyDatePickerModule,
    CollapseModule,
    MomentModule,
    ModalModule,
    VirtualScrollerModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    AngularMultiSelectModule,
    FlotDirective,
    AutoCloseDirective,
    ClickOutsideDirective,
    NestableModule,
    COMPONENTS
  ],
  providers: [
    GoogleMapsAPIWrapper,
    AuthGuard,
    PendingChangesGuard,
    CookieService,
    AuthedGuard,
    RoleGuard,
    DatePipe,
    CurrencyPipe,
    TooltipModule,
    PaginationModule,
    CollapseModule,
    CarouselModule,
    BsDropdownModule,
    NgxChartsModule,
    CountdownModule,
    TabsModule,
    NgxDatatableModule,
    MomentModule,
    ModalModule,
    NestableModule,
    VirtualScrollerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
  ],
  declarations: [
    FlotDirective,
    CountUpDirective,
    ClickOutsideDirective,
    ScrollToDirective,
    AutoCloseDirective,
    KeysPipe,
    PrettyPrintPipe,
    ScreenIntentPipe,
    GroupByPipe,
    ArraySortPipe,
    CheckinTimePipe,
    DeletedUsersPipe,
    CheckinDatePipe,
    ActiveUsers,
    Last30DaysUsers,
    CounterDirective,
    COMPONENTS
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
        ngModule: SharedModule
    };
}
}