import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import {
  Store
} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  User
} from '../../../routes/users/models/user';
import {
  Role
} from '../../../routes/roles/models/role';
import * as _ from 'underscore';
import {
  UsersService
} from '../../../routes/users/services/users.service';
import {
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  takeUntil,
  map,
  debounceTime,
} from 'rxjs/operators';
import {
  FormControl
} from '@angular/forms';
import {
  SidePanelService
} from '../../../core/services/sidepanel.service';
import * as Roles from '../../../core/store/actions/roles';
import {
  SosUser
} from '../../../routes/sosrecipients/models/SosUser';
import { HelpService } from '../../../routes/help/services/help.service';

@Component({
  selector: 'app-global-user-list',
  templateUrl: './global-user-list.component.html'
})
export class GlobalUserListComponent implements OnInit, OnDestroy {
  _id: string;
  @Input() set id(value: string) {
    this._id = value;
  }

  filteredUsers: User[];
  excludedUsers: string[] = [];
  users: User[];
  term = '';
  termControl = new FormControl();
  termControlSubscription: Subscription;
  loading: boolean;
  roles$: Observable < Role[] > ;
  private ngUnsubscribe: Subject < any > = new Subject();

  constructor(private cd: ChangeDetectorRef, private helpService: HelpService, private store: Store < fromRoot.State > , private sidePanelService: SidePanelService) {
    this.roles$ = this.store.select(fromCore.getAvailableRoles);
  }

  ngOnInit(): void {
    this.store.dispatch(new Roles.GetAvailableRoles());
    this.loadUsers();
    this.termControlSubscription = this.termControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000))
      .subscribe(newTerm => {
        this.term = newTerm;
        this.updateFilter();
      })

      this.sidePanelService.OnUserRemoved
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((user) => {
          const index = this.excludedUsers.indexOf(user.id);
          this.excludedUsers.splice(index, 1);
          this.updateFilter();
        });
  }

  ngOnDestroy(): void {
    this.termControlSubscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }

  OnUserSelected(user: User): void {
    const newSosUser = new SosUser();
    newSosUser.id = user.id;
    newSosUser.firstName = user.firstName;
    newSosUser.lastName = user.lastName;
    newSosUser.phoneNumber = user.phoneNumber;
    newSosUser.email = user.email;
    newSosUser.userName = user.email;
    newSosUser.userTeamId = user.teamId;

    if (this._id === null || this._id === undefined) {
      this.sidePanelService.OnGlobalUserSelected.emit(newSosUser);
    } else {
      newSosUser.teamId = this._id;
      this.sidePanelService.OnUserSelected.emit(newSosUser);
    }

    this.excludedUsers.push(user.id);
    this.updateFilter();
  }


private updateFilter(): void {
  
    this.loading = true;
    this.cd.detectChanges();

    if(this.term) {
      this.filteredUsers = this.users.filter(x => 
        (x.firstName.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) 
      || (x.lastName.toLowerCase().indexOf(this.term.toLowerCase()) !== -1)
      || ((x.firstName.toLowerCase() + ' ' + x.lastName.toLowerCase()).indexOf(this.term.toLowerCase()) !== -1)
      || (x.email.toLowerCase().indexOf(this.term.toLowerCase()) !== -1)
      || x.firstName.toLowerCase().startsWith(this.term.toLowerCase()) 
      || x.lastName.toLowerCase().startsWith(this.term.toLowerCase())
      || x.email.toLowerCase().startsWith(this.term.toLowerCase())
      || (x.firstName.toLowerCase() + ' ' + x.lastName.toLowerCase()).startsWith(this.term.toLowerCase()));
    } else {
      this.filteredUsers = this.users;
    }

    this.filteredUsers = this.filteredUsers.filter(x => !(this.excludedUsers.indexOf(x.id) !== -1));

    this.loading = false;
    this.cd.detectChanges();
}
  private loadUsers(): void {
   
      this.loading = true;
      this.cd.detectChanges();
      this.helpService.getAvailableSosRecipients(this._id)
        .pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000))
        .subscribe((results) => {
          this.users = results;
          this.updateFilter();
          this.loading = false;
          this.cd.detectChanges();
        });
  }
}
