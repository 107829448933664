export class SosUser {
id: string;
firstName: string;
lastName: string;
phoneNumber: string;
secondaryPhoneNumber: string;
email: string;
userName: string;
teamId: string;
userTeamId: string;
preventRemove: boolean;
}
