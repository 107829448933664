export class User {
  userEmail: string;
  userFirst: string;
  userLast: string;
  userAvatar: string;
  userRoles: string;
  access_token: string;
  refresh_token: string;
  expires: Date;
  issued: Date;
  token_type: string;
  error: string;
  error_description: string;
}

export class Authenticate {
  email: string;
  password: string;
}
