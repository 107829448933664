import { Injectable } from "@angular/core";

@Injectable()
export class MenuService {
    _currentRouteId: number;

    set currentRouteId(value: number) {
        this._currentRouteId = value;
    }

    get currentRouteId(): number {
        return this._currentRouteId;
    }

    constructor() {}
}