import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';

@Directive({
  selector: '[AutoClose]',
  host: {
    '(mouseleave)': 'onMouseLeave()'
  }
})
export class AutoCloseDirective {

  private el: HTMLElement;

  constructor(el: ElementRef) { this.el = el.nativeElement; }

  // @HostListener('click', ['$event'])
  // public onClick(event) {
  //     setTimeout(() => {
  //       event.target.parentElement.classList.remove('open');
  //     }, 3000);
  // }

  onMouseLeave() { 
    setTimeout(() => {
          this.el.classList.remove('open');
          }, 3000);
   }

}