import {
  Component,
  Input,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'app-base-leftpanel',
  template: `
    <div class="content-area content-area-sidebar">
      <div *ngIf="subtitle != ''" class="section-title section-title-subtitle">{{subtitle}}</div>
      <ng-content></ng-content>
    </div>
  `
})
export class BaseLeftPanelComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() shouldWrap = false;
}
