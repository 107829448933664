import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Authenticate
} from '../models/user';
import {
  User
} from '../../users/models/user';
import {
  Store
} from '@ngrx/store';
import * as fromRoot from '../../../core/store/reducers';
import * as authUser from '../models/user';
import * as Auth from '../../../core/store/actions/auth';
import * as fromAuth from '../../../core/store/reducers/auth';
import {
  AuthenticationHelper
} from '../../../shared/helpers/utils';
import { AuthToken, RefreshToken } from '../models/token';
import * as moment from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { VerificationItem } from '../models/VerificationItem';
import { ChangePasswordModel } from '../models/ChangePasswordModel';

@Injectable()
export class AuthService {

  public processing: boolean = false;
  public tokenRefreshed: Subject<any> = new Subject<any>();
  public tokenExpired: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {}

  public publish(value: any) {
    this.tokenRefreshed.next(value);
  }

  /* Send a login request to get the bearer token and the user info. */
  login({ email, password}: Authenticate) {
    const encodedEmail = encodeURIComponent(email);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post < authUser.User > ('/api/account/login', `username=${encodedEmail}&password=${password}&grant_type=password&client_id=Web Client Dashboard`, {
        headers: headers
      }).pipe(
      map(res => {
        const result: AuthToken = Object.assign({}, new AuthToken(), res);

        const tempToken = JSON.parse(res.refresh_token);
        result.refresh_token = new RefreshToken(tempToken.Id, tempToken.IssuedUtc, tempToken.ExpiresUtc);
        AuthenticationHelper.setBearerToken(result);
        AuthenticationHelper.StoreUserRoles(res.userRoles.split(','));
        setTimeout(() => {
          this.NotifyTokenExpired();
        }, (result.expires_in * 1000));
        return res;
      }));
  }

  logoutUser() {
    this.store.dispatch(new Auth.Logout());
  }

  refreshToken() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const authtoken = AuthenticationHelper.getBearerToken();

    return this.http
      .post < authUser.User > ('/api/account/login', `grant_type=refresh_token&refresh_token=${authtoken.refresh_token.id}&client_id=Web Client Dashboard`, {
        headers: headers
      }).pipe(
      map(res => {
        const result: AuthToken = Object.assign({}, new AuthToken(), res);
        const tempToken = JSON.parse(res.refresh_token);
        result.refresh_token = new RefreshToken(tempToken.Id, tempToken.IssuedUtc, tempToken.ExpiresUtc);
        AuthenticationHelper.setBearerToken(result);
        setTimeout(() => {
          this.NotifyTokenExpired();
        }, ((result.expires_in) * 1000));
        return res;
      }), catchError(error => {
        return Observable.throw(this.logoutUser());
      }));
  }

  private NotifyTokenExpired(): void {
    if (AuthenticationHelper.getState().loggedIn === true) {
      this.tokenExpired.next();
    }
  }

  /* Get the current user profile. */
  profile(): Observable < User > {
    return this.http.get < User > ('/api/v2/users/get');
  }

  /* Get the current authentication state. */
  getState(): fromAuth.State {
    const authToken = AuthenticationHelper.getBearerToken();
    // Check when the token expires.
    const expiresAt = moment().add(authToken.expires_in, 'second');
    return {
      ...fromAuth.initialState,
      loggedIn: (authToken != null && moment().isBefore(expiresAt)),
      authToken: authToken
    };
  }

  checkTwoFactorCode(email: string, code: string, item: VerificationItem){
    return this.http.post< boolean >('/api/v2/users/verifyTwoFactorCode', {email: email, code: code, item: item});
  }

  generateTwoFactorCode(email: string, item: VerificationItem){
    return this.http.post< boolean >('/api/v2/users/generateTwoFactorCode', {email: email, item: item});
  }

  getTwoFactorOptions(email: string){
    return this.http.post < VerificationItem[] > ('/api/v2/users/getTwoFactorOptions', {email: email});
  }

  // verification methods
  checkVerificationCode(email: string, code: string, item: VerificationItem){
    return this.http.post< VerificationItem >('/api/v2/users/checkVerificationCode', {email: email, code: code, item: item});
  }

  generateVerificationCode(email: string, item: VerificationItem){
    return this.http.post < boolean > ('/api/v2/users/generateVerificationCode', {email: email, item: item});
  }

  getVerificationOptions(email: string){
    return this.http.post < VerificationItem[] > ('/api/v2/users/getVerificationOptions', {email: email});
  }

  // password request methods
  changePassword(token: string){
    return this.http.post < ChangePasswordModel > ('/api/v2/users/checkPasswordRequest', {token: token});
  }

  updatePassword(newPassword: string, confirmPassword: string, token: string){
    return this.http.post< boolean >('/api/v2/users/updatePassword', {newPassword: newPassword, confirmPassword: confirmPassword, token: token});
  }

  resetPassword(email: string) {
    return this.http.post< boolean>('/api/v2/users/resetpassword', {email: email});
  }

  logout() {
    AuthenticationHelper.clearLocalStorage();
    return of(true);
  }
}
