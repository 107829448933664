import {
  Injectable
} from '@angular/core';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  SearchService
} from '../../../routes/search/services/search.service';
import * as search from '../actions/search';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class SearchEffects {
  @Effect()
  public search$: Observable < Action > = this.actions$.pipe(
    ofType(search.SEARCH),
    map((action: search.Search) => action.payload),
    switchMap(payload => this.searchService.search(payload).pipe(
      map(data => ({
        type: search.SEARCH_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: search.SEARCH_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  constructor(private actions$: Actions, private searchService: SearchService) {}
}
