import { Permission } from '../../models/admin/permission';
import { SelectItem } from '../../models/selectItem';
import * as permissions from '../actions/permissions';

export interface State {
    permissions: Permission[];
    selectPermissions: SelectItem[];
    loading: boolean;
    error: string;
    success: string;
}

const initialState: State = {
    permissions: [],
    selectPermissions: [],
    loading: true,
    error: '',
    success: ''
};

function FormatForDropdown(permissions: Permission[]): SelectItem[] {
    const result = new Array<SelectItem>();

    permissions.forEach(item => {
        result.push({
            id: item.id,
            itemName: item.name
        });
    });

    return result;
}

export function reducer(state = initialState, action: permissions.Actions): State {
    switch (action.type) {
        case permissions.GET_PERMISSIONS: {
            return {
                ...state,
                permissions: [],
                selectPermissions: [],
                loading: true,
            };
        }
        case permissions.GET_PERMISSIONS_COMPLETE: {
            return {
                ...state,
                permissions: action.payload,
                selectPermissions: FormatForDropdown(action.payload),
                loading: false,
                error: '',
                success: ''
            };
        }
        case permissions.GET_PERMISSIONS_FAILED: {
            return {
                ...state,
                permissions: [],
                selectPermissions: [],
                loading: false,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const getPermissions = (state: State) => state.permissions;
