import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  Store
} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  User
} from '../../../routes/users/models/user';
import {
  Role
} from '../../../routes/roles/models/role';
import {
  MapService
} from '../../../routes/map/map.service';
import * as _ from 'underscore';
import {
  UsersService
} from '../../../routes/users/services/users.service';
import {
  DatePipe
} from '@angular/common';
import { TimeLineEvent } from '../../../core/models/timeline/timelineEventType';
import { ReportsService } from '../../../routes/reports/services/reports.service';
import { AnnouncementsService } from '../../../routes/messaging/services/messages.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-info-right',
  templateUrl: './user-info.component.html'
})
export class UserInfoPanelComponent implements OnDestroy {
  @Output() userSelected = new EventEmitter < any > ();
  _id: string;
  @Input() set id(value: string) {
    this._id = value;
    this.loadDetails();
  }
  user: User;
  roles$: Observable < Role[] > ;
  loading: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private zone: NgZone, private reportsService: ReportsService, private announcementsService: AnnouncementsService, private cd: ChangeDetectorRef, private _mapService: MapService, private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, private store: Store < fromRoot.State > , private userService: UsersService) {
    this.roles$ = this.store.select(fromCore.getRoles);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }

  loadDetails(): void {
    this.loading = true;
    this.userService.getUserDetail(this._id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.user = user;
      this.loading = false;
      this.cd.detectChanges();
    });
  }

  private GetEventClass(event: TimeLineEvent): string {
    if (event.endTime === null) {
      return 'incomplete';
    }

    const inAt = new Date(event.startTime).setHours(0, 0, 0, 0);
    const outAt = new Date(event.endTime).setHours(0, 0, 0, 0);

    if (inAt !== outAt) {
      return 'overnight';
    }

    return '';
  }

  private IsOvernight(event: TimeLineEvent): boolean {
		if (event.endTime === null || event.endTime === undefined) {
			return false;
		}

    const inAt = new Date(event.startTime).setHours(0, 0, 0, 0);
		const outAt = new Date(event.endTime).setHours(0, 0, 0, 0);

    return (inAt !== outAt);
  }

  private getInAtDatestring(event: TimeLineEvent): string {
    return this.datePipe.transform(event.startTime, 'dd/MM/yyyy');
  }

  private getOutAtDatestring(event: TimeLineEvent): string {
    return this.datePipe.transform(event.endTime, 'dd/MM/yyyy');
  }

  private getInAtstring(event: TimeLineEvent): string {
    return this.datePipe.transform(event.startTime, 'HH:mm');
  }

  private getOutAtString(event: TimeLineEvent): string {
		if (event.endTime === null || event.endTime === undefined) {
			return 'Active';
		}

    return this.datePipe.transform(event.endTime, 'HH:mm');
  }

  goToMessages(user: User) {
    this.announcementsService.setSelectedUser(user);
    this.router.navigate(['/messages/add']);
  }

  goToMileage(user: User) {
    this.reportsService.setSelectedUser(user);
    this.router.navigate(['/reports/view/6']);
  }

  gotoEdit(user: User) {
    this.router.navigate(['/users/edit/' + user.id, {
      outlets: {
        'leftpanel': 'user-list',
        'rightpanel': null
      }
    }]);
  }

  gotoMap(user: User) {
    if (this.router.url !== '/map') {
      this.router.navigate(['map']).then(() => {
        this.zone.run(() => {
          setTimeout(() => {
            this.zone.run(() => this._mapService.searchClick.emit({
              type: 2,
              id: user.id
            }));
          }, 3000);
        });
      });
    } else {
      this.zone.run(() => this._mapService.searchClick.emit({
        type: 2,
        id: user.id
      }));
    }
  }
}
