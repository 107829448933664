import { Action } from '@ngrx/store';
import { ImportInfo } from '../../models/import/importinfo';

export const GET_IMPORTER = '[Locations] Get Importer';
export const GET_IMPORTER_COMPLETE = '[Locations] Get Importer Complete';
export const GET_IMPORTER_FAILED = '[Locations] Get Importer Failed';
export const UPLOAD_IMPORT = '[Locations] Upload Import';
export const UPLOAD_IMPORT_COMPLETE = '[Locations] Upload Import Complete';
export const UPLOAD_IMPORT_FAILED = '[Locations] Upload Import Failed';

export class GetImporter implements Action {
  readonly type = GET_IMPORTER;
}
export class GetImporterComplete implements Action {
  readonly type = GET_IMPORTER_COMPLETE;

  constructor(public payload: ImportInfo) {}
}
export class GetImporterFailed implements Action {
  readonly type = GET_IMPORTER_FAILED;

  constructor(public payload: string) {}
}
export class UploadImport implements Action {
  readonly type = UPLOAD_IMPORT;

  constructor(public payload: string) {}
}
export class UploadImportComplete implements Action {
  readonly type = UPLOAD_IMPORT_COMPLETE;

  constructor(public payload: ImportInfo) {}
}
export class UploadImportFailed implements Action {
  readonly type = UPLOAD_IMPORT_FAILED;

  constructor(public payload: string) {}
}
export type Actions = GetImporter | GetImporterComplete | GetImporterFailed | UploadImport | UploadImportComplete | UploadImportFailed;
