export class Result {
  id: string;
  name: string;
  subname: string;
  image: string;
  type: ResultType;
}

export enum ResultType {
  User,
  Location
}
