import {
    Pipe,
    PipeTransform
  } from '@angular/core';

@Pipe({name: 'screenIntent'})
export class ScreenIntentPipe implements PipeTransform {
  transform(value, args: string[]): any {
    if(value === 'android.intent.action.SCREEN_ON'){
        return 'On';
    }
    else{
        return 'Off'
    }
  }
}