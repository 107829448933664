import {
  Action
} from '@ngrx/store';
import {
  AppSetting
} from '../../models/appSetting';

export const GET_SETTINGS = '[Settings] Get Settings';
export const GET_SETTINGS_COMPLETE = '[Settings] Get Settings Complete';
export const GET_SETTINGS_FAILED = '[Settings] Get Settings Failed';

export class GetSettings implements Action {
  readonly type = GET_SETTINGS;
}

export class GetSettingsComplete implements Action {
  readonly type = GET_SETTINGS_COMPLETE;
  constructor(public payload: AppSetting) {}
}

export class GetSettingsFailed implements Action {
  readonly type = GET_SETTINGS_FAILED;
  constructor(public payload: string) {}
}

export type Actions = GetSettings | GetSettingsComplete | GetSettingsFailed;
