import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  HttpClient
} from '@angular/common/http';
import {
  GenericValidator
} from '../../../shared/validators/generic.validator';
import {
  Subject
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  AuthService
} from '../services/auth.service';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-password.component.html',
})
export class ResetComponent implements OnInit, OnDestroy, AfterViewInit {

  resetForm: FormGroup;
  requestState = 1;
  email: any;
  resend: boolean;
  loading: boolean;
  @Output() stateChanged = new EventEmitter < number > ();

  private genericValidator: GenericValidator;
  private ngUnsubscribe: Subject < any > = new Subject();

  private validationMessages: {
    [key: string]: {
      [key: string]: string
    }
  };

  displayMessage: {
    [key: string]: string
  } = {};

  ngOnInit(): void {

  }

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private authService: AuthService) {
    this.validationMessages = {
      email: {
        required: 'Email is required',
        pattern: 'Invalid email'
      }
    };

    //const emailPattern = "^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Update the validation messages on value change callback */
    this.resetForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.displayMessage = this.genericValidator.processMessages(this.resetForm);
    });
  }

  submit() {
   const email =this.resetForm.get('email').value;

    if (this.resetForm.valid) {
      this.requestState = 2;
      this.authService.resetPassword(email).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {});
    }

  }

  resetPassword() {
    const email =this.resetForm.get('email').value;

    this.authService.resetPassword(email)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
      this.resend = result;
    });
  }

  goLoginScreen(state: number): void {
    this.stateChanged.emit(state);
  }
}
