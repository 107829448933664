import {
  Injectable, EventEmitter, Output
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Role
} from '../../roles/models/role';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  Store
} from '@ngrx/store';
import { User } from '../../auth/models/user';

@Injectable()
export class RolesService {
  @Output() IsEditing: EventEmitter < boolean > = new EventEmitter();
  public page: number;
  public searchTerm: string;
  public isEditing = false;

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {
    this.store.select(fromCore.getRolesPage).subscribe((page) => {
      this.page = page;
    });

    this.store.select(fromCore.getRolesSearchTerm).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  toggleEditing(){
    this.isEditing = !this.IsEditing;
    this.IsEditing.emit(this.isEditing);
  }

  getAvailableRoles() {
    return this.http.get < Role[] > ('/api/v2/roles/available');
  }

  getRole(id: string) {
    return this.http.get<Role>('/api/v2/roles/detail/' + id);
  }

  updateRole(role: Role) {
    return this.http.post< Role >('/api/v2/roles/update', role);
  }

  getRolesPaged() {
    if (this.searchTerm !== '') {
      return this.http.get<Role[]>('/api/v2/roles/search?pageSize=20&page=' + this.page + '&searchTerm=' + this.searchTerm, {
        observe: 'response'
      });
    } else {
      return this.http.get <Role[]> ('/api/v2/roles/search?pageSize=20&page=' + this.page, {
        observe: 'response'
      });
    }
  }
}
