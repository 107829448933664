import * as locations from '../actions/locations';
import * as _ from 'underscore';
import * as utils from '../../../shared/helpers/utils';
import {
  Location
} from '../../../routes/locations/models/location';
import * as moment from 'moment';

export interface State {
  locations: Location[];
  selectedLocation: Location;
  success: string;
  error: string;
  loading: boolean;
  page: number;
  maxItems: number;
  searchTerm: string;
}

const initialState: State = {
  locations: [],
  selectedLocation: null,
  success: '',
  error: '',
  loading: false,
  page: 1,
  maxItems: 0,
  searchTerm: '',
};

function replaceItem(locations: Location[], location: Location) {
  const index = _.findIndex(locations, {
    id: location.id
  });
  const clonedLocation = _.extend({}, location);

  if (index >= 0) {
    locations[index] = clonedLocation;
  } else {
    if (location.id !== null) {
      locations.push(clonedLocation);
    }
  }

  const temp: Location[] = [];

  return locations = temp.concat(locations);
}

function mergeLocations(locations: Location[], newLocations: Location[]) {
  locations = locations.concat(newLocations);
  return locations;
}

export function reducer(state = initialState, action: locations.Actions): State {
  switch (action.type) {
    case locations.GET_ITEMS_PAGED:
      {
        const page = action.payload;
        return Object.assign({}, state, {
          loading: true,
          page: page == null ? state.page : page,
          searchTerm: action.term
        });
      }
    case locations.GET_ITEMS_COMPLETE_PAGED:
      {
        return {
          ...state,
          locations: mergeLocations(state.locations, action.payload),
          maxItems: action.maxCount,
          loading: false,
          error: '',
          success: ''
        };
      }
    case locations.GET_ITEMS_FAILED_PAGED:
      {
        return Object.assign({}, state, {
          loading: false,
          error: action.payload,
          success: ''
        });
      }
    case locations.GET_ITEMS:
      {
        return {
          ...initialState,
          loading: true
        };
      }
    case locations.GET_ITEMS_COMPLETE:
      {
        return {
          ...state,
          locations: action.payload,
          loading: false
        };
      }
    case locations.GET_ITEMS_FAILED:
      {
        return {
          ...initialState,
          error: action.payload,
          loading: false
        };
      }

    case locations.GET_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case locations.GET_ITEM_COMPLETE:
      {
        return {
          ...state,
          locations: replaceItem(state.locations, action.payload),
          selectedLocation: action.payload,
          loading: false
        };
      }
    case locations.GET_ITEM_FAILED:
      {
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      }

    case locations.UPDATE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case locations.UPDATE_ITEM_COMPLETE:
      {
        return {
          ...state,
          locations: replaceItem(state.locations, action.payload),
          selectedLocation: action.payload,
          success: 'Updated Location at: ' + moment(new Date(), 'DD/MM/YYYY').format('LTS'),
          loading: false
        };
      }
    case locations.UPDATE_ITEM_FAILED:
      {
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      }
    case locations.RESET_STORE:
      {
        return initialState;
      }

    default:
      {
        return state;
      }
  }
}

export const getMaxItems = (state: State) => state.maxItems;
export const getPage = (state: State) => state.page;
export const getLocations = (state: State) => state.locations;
export const getLocation = (state: State) => state.selectedLocation;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
export const getSearchTerm = (state: State) => state.searchTerm;
