import { Team } from '../../teams/models/team';
import { CheckIn, CheckInState } from '../../../core/models/stanzas/checkIn.stanza';
import { Location } from '../../locations/models/location';
import { GroupedTimeLineEvent } from '../../../core/models/timeline/timelineEventType';
import { ApplicationSetting } from '../../admin/models/applicationsetting';
import { TrainingEmailed, TrainingLog } from '../../training/models/TrainingModel';

export interface MapUser {
  id: string;
  firstName: string;
  lastName: string;
  lat: number;
  lng: number;
  currentCheckIn: CheckIn;
  locationLastUpdated: Date;
  team: Team;
  roles: string[];
  hasActiveSession: boolean;
  lastSeenFormatted: string;
  lastSeen: Date;
  batteryState: BatteryState;
  lastBatteryPercentage: number;
  currentCheckinDescription: string;
  accuracy: number;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  secondaryPhoneNumber: string;
  avatar: string;
  jobTitle: string;
  roles: string[];
  lastLogin: Date;
  lat: number;
  lng: number;
  locationLastUpdated: Date;
  lockoutEnabled: boolean;
  team: Team;
  teamId: string;
  recentCheckIns: CheckInItem[];
  currentCheckIn: CheckIn;
  userValues: UserValue[];
  cars: Car[];
  password: string;
  confirmPassword: string;
  accuracy: number;
  locationAccuracy: LocationAccuracy;
  hasActiveSession: boolean;
  activeSessionTime: string;
  orderedCheckins: OrderedCheckinDay[];
  lastCheckIn: CheckInItem;
  lastSeenFormatted: string;
  lastSeen: Date;
  batteryState: BatteryState;
  lastBatteryPercentage: number;
  groupedEvents: GroupedTimeLineEvent[];
  currentCheckinDescription: string;
  applicationSettings: ApplicationSetting[];
  isDeleted: boolean;
  nudges: Nudge[];
  trainingLogs : TrainingLog[];
  loneWorkerTrainingLogs: TrainingLog[];
  sosRecipientTrainingLogs: TrainingLog[];  
  trainingEmailed : TrainingEmailed[];
  loneWorkerTrainingEmailed : TrainingEmailed[];
  sosRecipientTrainingEmailed : TrainingEmailed[];
}

export interface OrderedCheckinDay {
  title: string;
  day: number;
  checkins: CheckInItem[];
}

export enum LocationAccuracy {
  High,
  Medium,
  Low
}

export interface SendSmsToAllResponse {
  success: boolean;
  partialSuccess: boolean;
  errors: string[];
}

export enum BatteryState {
  Unkown,
  Low,
  Medium,
  High
}

export interface CheckInItem {
  id: string;
  inAt: Date;
  outAt: Date;
  hardInAt: Date;
  hardOutAt: Date;
  locationId: string;
  location: Location;
  state: CheckInState;
  hardState: CheckInState;
  timeAtLocation: string;
}

export interface UserRole {
  roleId: string;
  userId: string;
}

export interface UserValue {
  id: string;
  name: string;
  value: string;
}

export interface Car{
  id: string;
  carRegistration: string;
  cylinderCapacity: number;
  cO2Emissions: number;
  fuelType: string;
  euroStatus: string;
  realDrivingEmissions: string;
  user_Id: string;
}

export interface Nudge{
    id: string;
    userId: string;
    nudgeDate: Date;
}
