import { Observable, Subject, Subscription, of } from 'rxjs';
import { takeUntil, map, debounceTime, flatMap } from 'rxjs/operators';

export class ObservableHelper {
    static getLoadingObservable(): Observable<string> {
        return of('Loading');
    }

    static getStringObservable(content: string): Observable<string> {
        return of(content);
    }
}
