import {
  Injectable,
  Output,
  EventEmitter
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Location, LocationType
} from '../models/location';
import {
  ImportInfo
} from '../../../core/models/import/importinfo';
import * as utils from '../../../shared/helpers/utils';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  Store
} from '@ngrx/store';

@Injectable()
export class LocationsService {
  @Output() IsEditing: EventEmitter < Boolean > = new EventEmitter();
  public page: number;
  public searchTerm: string;
  public isEditing = false;
  public overrideDialog = false;

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {
    this.store.select(fromCore.getLocationsPage).subscribe((page) => {
      this.page = page;
    });
    this.store.select(fromCore.getLocationsSearchTerm).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  toggleEditing(value: boolean) {
    this.isEditing = value;
    this.IsEditing.emit(this.isEditing);
  }

  getLocations() {
    return this.http.get < Location[] > ('/api/v2/locations/list?page=' + 0);
  }

  getLocationsPaged() {
    if (this.searchTerm !== '') {
      return this.http.get < Location[] > ('/api/v2/locations/list?&pageSize=20&page=' + this.page + '&searchTerm=' + this.searchTerm, {
        observe: 'response'
      });
    } else {
      return this.http.get < Location[] > ('/api/v2/locations/list?&pageSize=20&page=' + this.page, {
        observe: 'response'
      });
    }
  }

  downloadImportTemplate() {
    return this.http.post('/api/v2/locations/import/template', {}, {observe: 'response', responseType: 'blob'});
  }

  getLocation(id: string) {
    return this.http.get < Location > ('/api/v2/locations/get/' + id );
  }

  getLocationDetail(id: string) {
    return this.http.get < Location > ('/api/v2/locations/details/' + id );
  }

  updateLocation(location: Location) {
    return this.http.post < Location > ('/api/v2/locations/update', location);
  }

  addLocation(location: Location) {
    return this.http.post < Location > ('/api/v2/locations/add', location);
  }

  deleteLocation(location: Location) {
    return this.http.post('/api/v2/locations/delete', location);
  }

  getImportInfo() {
    return this.http.get < ImportInfo > ('/api/v2/locations/import/info');
  }

  importLocations(data: string) {
    return this.http.post < ImportInfo > ('/api/v2/locations/import/upload', {
      data: data
    });
  }

  getBaseLocation() {
    const location: Location = {
      id: utils.EMPTY_GUID,
      name: '',
      postCode: '',
      lat: 0,
      lng: 0,
      locationRadius: 100,
      recentCheckIns : null,
      orderedCheckins : null,
      locationType: LocationType.Location
    };

    return location;
  }
}
