import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store} from '@ngrx/store';
import * as Auth from '../../core/store/actions/auth';
import * as fromRoot from '../../core/store/reducers';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { takeUntil, map, debounceTime, take, tap } from 'rxjs/operators';
import { ConfigService } from '../../core/services/config.service';
import { Config } from '../../core/models/config';
import { AuthenticationHelper } from '../helpers/utils';

@Injectable()
export class AuthGuard implements OnDestroy, CanActivate, CanActivateChild {

  private ngUnsubscribe: Subject < any > = new Subject();

  constructor(private store: Store<fromRoot.State>, private configService: ConfigService, private router: Router) {}

  ngOnDestroy() : void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.activate(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.activate(route, state);
  }

  activate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store
    .select(fromRoot.getLoggedIn)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      tap(authed => {
        if (!authed) {
          this.store.dispatch(new Auth.LoginRedirect());
          return false;
        }

        return true;
      })
      , 
      take(1)
    );
  }
}

@Injectable()
export class AuthedGuard implements OnDestroy, CanActivate {

  private ngUnsubscribe: Subject < any > = new Subject();

  constructor(private store: Store<fromRoot.State>) {}

  ngOnDestroy() : void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  canActivate(): Observable<boolean> {
    return this.store
    .select(fromRoot.getLoggedIn)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      map(authed => {
        if (authed) {
          this.store.dispatch(new Auth.LoggedInRedirect());
          return false;
        }

        return true;
      }), 
      take(1)
    );
  }
}
