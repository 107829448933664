import {
  Injectable
} from '@angular/core';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  ConfigService
} from '../../services/config.service';
import * as config from '../actions/config';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';

@Injectable()
export class ConfigEffects {
  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(config.GET_ITEMS),
    switchMap(action => {
      return this.configService.getConfig().pipe(
        map(data => ({
          type: config.GET_ITEMS_COMPLETE,
          payload: data
        })),
        catchError(() => of ({
          type: config.GET_ITEMS_FAILED
        })));
    }));

  constructor(private actions$: Actions, private configService: ConfigService) {}
}
