import {
    Component,
    ChangeDetectionStrategy
  } from '@angular/core';
  
  @Component({
    selector: 'app-unauthorized',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './unauthorized.component.html',
    styles: [
      `
        :host {
          text-align: center;
        }
      `
    ]
  })
  export class UnauthorizedComponent {
  
  }
  