import {
    Action
  } from '@ngrx/store';
import { Permission } from '../../models/admin/permission';
import { Role } from '../../../routes/roles/models/role';

  export const GET_PERMISSIONS = '[Permissions] Get Permissions';
  export const GET_PERMISSIONS_COMPLETE = '[Permissions] Get Permissions Complete';
  export const GET_PERMISSIONS_FAILED = '[Permissions] Get Permissions Failed';

  export class GetPermissions implements Action {
      readonly type = GET_PERMISSIONS;
  }

  export class GetPermissionsComplete implements Action {
      readonly type = GET_PERMISSIONS_COMPLETE;
      constructor(public payload: Permission[]) {}
  }

  export class GetPermissionsFailed implements Action {
      readonly type = GET_PERMISSIONS_FAILED;
      constructor(public payload: string) {}
  }

  export type Actions = GetPermissions | GetPermissionsComplete | GetPermissionsFailed;