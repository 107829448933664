import {
  ErrorHandler,
  Injectable,
  OnDestroy,
  Injector
} from '@angular/core';
import {
  ModalService
} from '../core/services/modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private _modalService: ModalService;

  constructor(private injector: Injector) {

  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {

      this._modalService = this.injector.get(ModalService);
      this._modalService.showConfirmModal('Refresh Required', 'A new update has been released which requires a browser refresh, click yes to refresh.')
      .subscribe((result) => {
          if (result) {
            window.location.reload();
          }
        })
    } else {
      throw error;
    }
  }
}
