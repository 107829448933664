import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html'
})
export class ConfirmLeaveComponent {
  subject: Subject<boolean>;
  @Input() title: string = 'Are you sure?';
  @Input() message: string = 'You have pending changes, do you really want to leave page?';

  constructor(public bsModalRef: BsModalRef) { }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
}