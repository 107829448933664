import * as utils from '../../../shared/helpers/utils';
import * as helpmessages from '../actions//help';
import * as _ from 'underscore';
import {
  HelpMessage
} from '../../../routes/help/models/helpMessage';
import { state } from '@angular/animations';

export interface State {
  helpMessages: HelpMessage[];
  selectedHelpMessage: HelpMessage;
  persistantHelpMessage: HelpMessage;
  loading: boolean;
  priorityLoading: boolean,
  persistantLoading: boolean;
  error: string;
  success: string;
  page: number;
  maxItems: number;
  searchTerm: string;
  includeTest: boolean;
}

const initialState: State = {
  helpMessages: [],
  selectedHelpMessage: null,
  persistantHelpMessage: null,
  loading: false,
  priorityLoading: false,
  persistantLoading: false,
  error: '',
  success: '',
  page: 1,
  maxItems: 0,
  searchTerm: '',
  includeTest: false
};

function mergeItems(helpMessages: HelpMessage[], newAnnouncements: HelpMessage[]) {
  helpMessages = helpMessages.concat(newAnnouncements);

  helpMessages = helpMessages.filter((message, index, self) => {
    return index === self.findIndex((t) => (t.id === message.id));
  });

  return helpMessages;
}

function replaceItem(helpMessages: HelpMessage[], helpMessage: HelpMessage) {
  const index = _.findIndex(helpMessages, {
    id: helpMessage.id
  });

  const clonedItem = _.extend({}, helpMessage);
  if (index >= 0) {
    helpMessages[index] = clonedItem;
  } else {
    helpMessages.push(clonedItem);
  }

  const temp: HelpMessage[] = [];
  return helpMessages = temp.concat(helpMessages);
}

export function reducer(state = initialState, action: helpmessages.Actions): State {
  switch (action.type) {
    case helpmessages.GET_AVAILABLE_HELP_MESSAGES:
    {
      const page = action.payload;
        return Object.assign({}, state, {
          loading: true,
          page: page == null ? state.page : page,
          searchTerm: action.term,
          includeTest: action.includeTest
        });
    }
    case helpmessages.GET_AVAILABLE_HELP_MESSAGES_COMPLETE:
    {
      return {
        ...state,
        helpMessages: mergeItems(state.helpMessages, action.payload),
        maxItems: action.maxCount,
        loading: false,
        error: '',
        success: ''
      };
    }
    case helpmessages.GET_AVAILABLE_HELP_MESSAGESS_FAILED:
    {
      return {
        ...state,
        helpMessages: [],
        loading: false,
        error: action.payload,
        success: ''
      };
    }
    case helpmessages.GET_HELP_MESSAGE:
      {
        return {
          ...state,
          priorityLoading: true
        };
      }
    case helpmessages.GET_HELP_MESSAGE_COMPLETE:
      {
        return {
          ...state,
          helpMessages: replaceItem(state.helpMessages, action.payload),
          selectedHelpMessage: action.payload,
          priorityLoading: false,
          error: '',
          success: ''
        };
      }
    case helpmessages.GET_HELP_MESSAGE_FAILED:
      {
        return {
          ...state,
          priorityLoading: false,
          error: action.payload,
          selectedHelpMessage: initialState.selectedHelpMessage,
          success: ''
        };
      }
      case helpmessages.GET_PERSISTENT_HELP_MESSAGE:
      {
        return {
          ...state,
          persistantLoading: true
        };
      }
    case helpmessages.GET_PERSISTENT_HELP_MESSAGE_COMPLETE:
      {
        return {
          ...state,
          helpMessages: replaceItem(state.helpMessages, action.payload),
          persistantHelpMessage: action.payload,
          persistantLoading: false,
          error: '',
          success: ''
        };
      }
    case helpmessages.GET_PERSISTENT_HELP_MESSAGE_FAILED:
      {
        return {
          ...state,
          persistantLoading: false,
          error: action.payload,
          persistantHelpMessage: initialState.persistantHelpMessage,
          success: ''
        };
      }
      case helpmessages.RESET_STORE:
      {
        return {
          ...state,
          error: '',
          success: '',
          searchTerm: '',
          helpMessages: [],
          loading: false
        };
      }
    default:
      {
        return state;
      }
  }
}

export const getMaxItems = (state: State) => state.maxItems;
export const getPage = (state: State) => state.page;
export const getHelpMessages = (state: State) => state.helpMessages;
export const getHelpMessage = (state: State) => state.selectedHelpMessage;
export const getPersistantHelpMessage = (state: State) => state.persistantHelpMessage;
export const getSuccess = (state: State) => state.success;
export const getError = (state: State) => state.error;
export const getLoading = (state: State) => state.loading;
export const getPriorityLoading = (state: State) => state.priorityLoading;
export const getPersistantLoading = (state: State) => state.persistantLoading;
export const getSearchTerm = (state: State) => state.searchTerm;
export const getIncludeTest = (state: State) => state.includeTest;
