export interface TrainingModel{    
    pageName: string;
    pageIntro: string;
    pageContent: string;
    training: TrainingMaterial[];
    form: TrainingForm;
    privacyUrl: string;
    privacyName: string;
    termsUrl: string;
    termsName: string;
    supportEmail: string;
    supportTelephone: string;
}

export interface TrainingMaterial
{
    title: string;
    shortTitle: string;
    stepSummary: string;
    videoFile: string;
    englishSubtitles: string;
    videoPoster: string;
    description: string;
    number: Number;
}

export interface TrainingForm
{
    contactTitle: string;
    contactIntro: string;
    contactNavTitle: string;
    contactNavSummary: string;
    formEmbedCode: string;
}

export interface TrainingLog
{
    trainingTypeId : TrainingType;
    dateCompleted : Date;
}

export interface TrainingEmailed
{
    trainingTypeId : TrainingType;
    dateEmailed: Date;
}

export enum TrainingType {
    LoneWorker = 0,
    SOSRecipient = 1
}

export interface TrainingResponse{
    trainingLog: TrainingLog;
    responseMessage : string;
}