import {
  Injectable,
  OnDestroy
} from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {
  AuthenticationHelper
} from '../helpers/utils';
import {
  Role
} from '../../routes/roles/models/role';
import {
  RolesService
} from '../../routes/roles/services/roles.service';
import {
  Subject
} from 'rxjs';
import {
  MenuService
} from '../../core/services/menu.service';
import { SidePanelService } from '../../core/services/sidepanel.service';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild, OnDestroy {
  private ngUnsubscribe: Subject < any > = new Subject();
  roles: Role[];
  exemptPaths: string[] = ['edit', 'view', 'add'];

  constructor(private router: Router, private menuService: MenuService, private sidepanelservice: SidePanelService) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.CheckRoles(state.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.CheckRoles(state.url);
  }

  private processUrl(path: string) {
    let urlSegments = path.split('/');

    if (urlSegments !== undefined && urlSegments !== null && urlSegments.length > 0) {
      let url = '/' + urlSegments[1];

      if (urlSegments[1] === 'reports') {
        url = path;
      }

      if (urlSegments[1] === 'admin') {

        url = path;

        if (urlSegments.indexOf('view') !== -1) {
          var index = urlSegments.indexOf('view');
          url = urlSegments.slice(0, index).join('/');
        }

        if (urlSegments.indexOf('edit') !== -1) {
          var index = urlSegments.indexOf('edit');
          url = urlSegments.slice(0, index).join('/');
        }
      }

      return url;
    }

    return '';
  }

  CheckRoles(path: string): boolean {

    let hasAccess = false;


    const userRolesIds: string[] = AuthenticationHelper.getUserRoles(); // <--------- get the current user's roles

    if (userRolesIds !== undefined && userRolesIds !== null) {
      
        var url = this.processUrl(path);
        const menuItem = AuthenticationHelper.getMenuForRoute(url); // <----------- get the menu item for the current route

        // if the menu item is null then the user cannot access it.
        if (menuItem === null || menuItem === undefined) {
          if(!AuthenticationHelper.hasMenuItems()){
            
            this.sidepanelservice.Logout.emit();
            return true;
          }

          hasAccess = false;
        } else {
          // now we check if the user has the required roles.
          var allowedRoles = menuItem.allowedRoles;

          userRolesIds.forEach((roleId) => {
            if (allowedRoles.indexOf(roleId) !== -1) {
              hasAccess = true;
            }
          })
        }
    }

    if (hasAccess) {
      return hasAccess;
    } else {
      this.router.navigate(['405-Unauthorized']);
      return false;
    }
  }
}
