
  import {
    EventEmitter
  } from '@angular/core';
import { Nudge, User } from '../../routes/users/models/user';
import { SosUser } from '../../routes/sosrecipients/models/SosUser';
  
  export class SidePanelService {

    /* Open and close events */
    public OpenSidePanelForUser = new EventEmitter < string > ();
    public OpenSidePanelForLocation = new EventEmitter < string > ();
    public OpenSidePanelForHelpDetails = new EventEmitter < string > ();
    public OpenSidePanelForSettings = new EventEmitter < string > ();
    public OpenSidePanelForCostEstimate = new EventEmitter < string > ();
    public OpenSidePanelForCarbonFootprintDetails = new EventEmitter < string > ();
    public OpenSidePanelForGlobalUsers = new EventEmitter< string > ();    
    public OpenSidePanelForNotes = new EventEmitter< { helpMessageId: string} > ();
    public OpenSidePanelForNudges = new EventEmitter<Nudge[]>();
    public CloseSidePanel = new EventEmitter < any > ();
    public Logout = new EventEmitter< any > ();

    /* Extra events like passing data back from the side panel */
    public OnUserSelected = new EventEmitter<SosUser>();
    public OnGlobalUserSelected = new EventEmitter<SosUser>();
    public OnUserRemoved = new EventEmitter<SosUser>();    
    public OnNoteSaved = new EventEmitter<boolean>();
  }

  