import { GeoLoc } from './geoloc.stanza';
import { Location } from '../../../routes/locations/models/location';

export class CheckIn extends GeoLoc {
  locationId: string;
  location: Location;
  state: CheckInState;
}

export enum CheckInState {
  SoftCheckIn = 0,
  HardCheckIn = 1,
  SoftCheckOut = 2,
  HardCheckOut = 3
}
