import * as fromSettings from '../store/reducers/settings';
import {
  AppSetting
} from '../models/appSetting';
import {
  EventEmitter, Injectable
} from '@angular/core';


export class SettingsStorageService {
  public refreshSettingChanged = new EventEmitter < any > ();
  public showLocationRadiusChanged = new EventEmitter < any > ();
  public showUserRadiusChanged = new EventEmitter < any >();
  public refreshTeamSettingChanged = new EventEmitter < any > ();
  private layout: any;

  constructor() {
        // Layout Settings
        // -----------------------------------
        this.layout = {
          isFixed: true,
          isCollapsed: false,
          isBoxed: false,
          isRTL: false,
          horizontal: false,
          isFloat: false,
          asideHover: false,
          theme: null,
          asideScrollbar: false,
          isCollapsedText: false,
          useFullLayout: false,
          hiddenFooter: false,
          offsidebarOpen: false,
          asideToggled: false,
          viewAnimation: 'ng-fadeInUp'
      };
  }


  getSetting(key: string): fromSettings.State {
    const appSetting = < AppSetting > JSON.parse(localStorage.getItem(key));
    return {
      ...fromSettings.initialState,
      appSetting: appSetting,
    };
  }

  setSetting(setting: AppSetting): fromSettings.State {
    localStorage.setItem(setting.key, JSON.stringify(setting));
    return {
      ...fromSettings.initialState
    };
  }

  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
        return this.layout[name] = value;
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }
}
