import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginPageComponent } from './containers/login-page.component';
import { LoginFormComponent } from './components/login.component';
import { SharedModule } from '../../shared/shared.module';
import { ResetComponent } from './components/reset-password.component';
import { ChangePasswordComponent } from './components/change-password.component';
import { VerifyComponent } from './components/verification.component';
import { TwoFactorComponent } from './components/two-factor.component';
import { FormsModule } from '../../../../node_modules/@angular/forms';
import { StandDownComponent } from './components/stand-down.component';
import { QuickSopComponent } from './components/quick-sop.component';
import { TrainingComponent } from '../training/components/training.component';


export const COMPONENTS = [LoginPageComponent, LoginFormComponent, ResetComponent, ChangePasswordComponent, VerifyComponent, TwoFactorComponent, StandDownComponent, QuickSopComponent, TrainingComponent];


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: COMPONENTS,
  exports: [
    FormsModule,
    RouterModule,
    LoginPageComponent,
    LoginFormComponent,
    ResetComponent,
    ChangePasswordComponent,
    VerifyComponent,
    TwoFactorComponent,
    StandDownComponent,
    QuickSopComponent,
    TrainingComponent
  ],
})
export class PagesModule {}

