import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  LocationsService
} from '../../../routes/locations/services/locations.service';
import * as locations from '../actions/locations';
import {
  Location
} from '../../../routes/locations/models/location';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class LocationsEffects {
  @Effect()
  public fetchList$: Observable < Action > = this.actions$.pipe(
    ofType(locations.GET_ITEMS),
    switchMap(action => this.locationService.getLocations().pipe(
      map(data => ({
        type: locations.GET_ITEMS_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: locations.GET_ITEMS_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetchListPaged$: Observable < Action > = this.actions$.pipe(
    ofType(locations.GET_ITEMS_PAGED),
    switchMap(action => this.locationService.getLocationsPaged().pipe(
      map(data => ({
        type: locations.GET_ITEMS_COMPLETE_PAGED,
        payload: data.body,
        maxCount: parseInt(JSON.parse(data.headers.get('X-Pagination')).totalCount)
      })),
      catchError((error) => of ({
        type: locations.GET_ITEMS_FAILED_PAGED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  fetch$: Observable < Action > = this.actions$.pipe(
    ofType(locations.GET_ITEM),
    map((action: locations.GetItem) => action.payload),
    switchMap(payload => this.locationService.getLocation(payload).pipe(
      map(data => ({
        type: locations.GET_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: locations.GET_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public update$: Observable < Action > = this.actions$.pipe(
    ofType(locations.UPDATE_ITEM),
    map((action: locations.UpdateItem) => action.payload),
    switchMap(payload => this.locationService.updateLocation(payload).pipe(
      map(data => ({
        type: locations.UPDATE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: locations.UPDATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public add$: Observable < Action > = this.actions$.pipe(
    ofType(locations.CREATE_ITEM),
    map((action: locations.CreateItem) => action.payload),
    switchMap(payload => this.locationService.addLocation(payload).pipe(
      map((data: Location) => {
        this.router.navigate(['/locations/edit', data.id]);
        return {
          type: locations.UPDATE_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => of ({
        type: locations.UPDATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public baseFetch$: Observable < Action > = this.actions$.pipe(
    ofType(locations.GET_BASE_ITEM),
    switchMap(action => {
      return of({
        type: locations.GET_ITEM_COMPLETE,
        payload: this.locationService.getBaseLocation()
      });
    }));

  constructor(private actions$: Actions, private locationService: LocationsService, private router: Router) {}
}
