import {
  Injectable,
  EventEmitter,
  Output
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  Store
} from '@ngrx/store';
import {
  HelpMessage
} from '../models/helpMessage';
import { User } from '../../users/models/user';
import { SosUser } from '../../sosrecipients/models/SosUser';
import { SopTemplate } from '../../../core/models/sopTemplate';
import { QuickSopModel, StandDownModel } from '../models/standDownModel';
import { HelpMessageNote } from '../models/helpmessagenote';

@Injectable()
export class HelpService {
  @Output() IsEditing: EventEmitter < Boolean > = new EventEmitter();
  public page: number;
  public searchTerm: string;
  public isEditing = false;
  public includeTest: boolean;

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {
    this.store.select(fromCore.getHelpMessagesPage).subscribe((page) => {
      this.page = page;
    });
    this.store.select(fromCore.getHelpMessagesSearchTerm).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
    this.store.select(fromCore.getHelpMessagesIncludeTest).subscribe((result) => {
      this.includeTest = result;
    });
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
    this.IsEditing.emit(this.isEditing);
  }

  getHelpMessage(id: string) {
    return this.http.get < HelpMessage[] > ('/api/v2/help/get/' + id + '?includes=User.Team');
  }

  getHelpMessagsPaged() {
      return this.http.get < HelpMessage[] > ('/api/v2/help/list?pageSize=20&page=' + this.page + '&includes=User' + '&searchTerm=' + this.searchTerm + '&includeTest=' + this.includeTest, {
        observe: 'response'
      });
  }

  checkStandDownRequest(token: string){
    return this.http.post < StandDownModel > ('/api/v2/help/checkStandDownRequest', {token: token});
  }

  checkQuickSopRequest(token: string){
    return this.http.post < QuickSopModel > ('/api/v2/help/checkQuickSopRequest', {token: token});
  }

  standDownAnnonymous(token: string) {
    return this.http.post<StandDownModel>('/api/v2/help/standdownannonymous', { token: token});
  }

  getSosRecipients(teamId: string) {
    return this.http.get < SosUser[] > ('/api/v2/help/sosRecipients/get/' + teamId);
  }

  getAvailableSosRecipients(teamId: string) {
    if(teamId)
    {
      return this.http.get < User[] > ('/api/v2/help/sosRecipients/users/' + teamId);
    } else {
      return this.http.get < User[] > ('/api/v2/help/sosRecipients/users/' + null);
    }
  }

  standDown(helpRequestId: string, userId: string) {
    return this.http.post<HelpMessage>('/api/v2/help/standdown', { helpRequestId: helpRequestId, userId: userId});
  }

  updateSosRecipients(users: SosUser[], teamId: string){
    return this.http.post < SosUser[] > ('/api/v2/help/sosRecipients/update', { recipientList: users, teamId: teamId });
  }

  getSopTemplates() {
    return this.http.get < SopTemplate[] > ('/api/v2/help/dropdown');
  }

  getNotes(helpMessageId) {
    return this.http.get < HelpMessageNote[] > ('/api/v2/help/getnotes/' + helpMessageId);    
  }

  addNote(helpMessageNote: HelpMessageNote) {
    return this.http.post < boolean > ('/api/v2/help/add', helpMessageNote);
  }  

  deleteNote(helpMessageNote: HelpMessageNote) {
    return this.http.post < boolean > ('/api/v2/help/delete', helpMessageNote);
  }  
}
