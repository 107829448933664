import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Nudge } from '../../../routes/users/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-nudge-rightpanel',
  templateUrl: './nudge-rightpanel.component.html'
})
export class NudgeRightpanelComponent implements OnInit , OnDestroy{
@Input() nudges: Nudge[];
loading: boolean;   
private ngUnsubscribe: Subject < any > = new Subject();    
  constructor(private cd: ChangeDetectorRef, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }

  private formatLastNudgeDate(nudge: Nudge)
  {     
      return formatDate(nudge.nudgeDate, 'dd/MM/yyyy H:mm','en-GB');    
  }


}
