import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'app-base-main',
  templateUrl: './base-main.component.html'
})
export class BaseMainComponent {
  @Input() title: string;
  @Input() hideTitle: boolean = false;
}
