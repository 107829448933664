import {
  Injectable,
  EventEmitter,
  Output
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Announcement
} from '../models/announcement';
import * as utils from '../../../shared/helpers/utils';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  Store
} from '@ngrx/store';
import {
  User
} from '../../users/models/user';
import {
  AuthenticationHelper
} from '../../../shared/helpers/utils';
import { of } from 'rxjs';

@Injectable()
export class AnnouncementsService {
  @Output() IsEditing: EventEmitter < Boolean > = new EventEmitter();
  public page: number;
  public searchTerm: string;
  public isEditing = false;
  public overrideDialog = false;
  private selectedUser: User;

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {
    this.store.select(fromCore.getAnnouncementsPage).subscribe((page) => {
      this.page = page;
    });
    this.store.select(fromCore.getAnnouncementsSearchTerm).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  toggleEditing(value: boolean) {
    this.isEditing = value;
    this.IsEditing.emit(this.isEditing);
    console.log('Is editing: ' + this.isEditing);
  }

  getAnnouncement(id: string) {
    return this.http.get < Announcement > ('/api/v2/announcements/get/' + id + '?includes=AnnouncementParticipants.user');
  }

  getAnnouncements() {
    return this.http.get < Announcement[] > ('/api/v2/announcements/list?includes=Users&page=' + 0);
  }

  getAnnouncementsPaged() {

    const user = AuthenticationHelper.getCurrentUser();

    if (this.searchTerm !== '') {
      return this.http.get < Announcement[] > ('/api/v2/announcements/list?userId=' + user.id + '&includes=AnnouncementParticipants&pageSize=20&page=' + this.page + '&searchTerm=' + this.searchTerm, {
        observe: 'response'
      });
    } else {
      return this.http.get < Announcement[] > ('/api/v2/announcements/list?userId=' + user.id + '&includes=AnnouncementParticipants&pageSize=20&page=' + this.page, {
        observe: 'response'
      });
    }
  }

  setSelectedUser(value: User) {
    this.selectedUser = value;
  }

  getSelectedUser() {
    return this.selectedUser;
  }

  clearSelectedUser() {
    this.selectedUser = null;
  }

  updateAnnouncement(team: Announcement) {
    return this.http.post < Announcement > ('/api/v2/announcements/update', team);
  }

  addAnnouncement(team: Announcement) {
    return this.http.post < Announcement > ('/api/v2/announcements/add', team);
  }

  deleteAnnouncement(id: string) {
    return this.http.delete < boolean > ('/api/v2/announcements/delete/' + id);
  }

  getBaseAnnouncement() {
    const announcement: Announcement = {
      id: utils.EMPTY_GUID,
      body: '',
      userId: null,
      subject: null,
      user: null,
      announcementParticipants: null
    };
    return of({
      announcement
    });
  }
}
