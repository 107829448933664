import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit
} from '@angular/core';
import {
  User
} from '../../../../routes/users/models/user';
import {
  Role
} from '../../../../routes/roles/models/role';
import {
  AuthenticationHelper
} from '../../../../shared/helpers/utils';
import {
  MapService
} from '../../../../routes/map/map.service';
import {
  Router
} from '@angular/router';
import {
  SidePanelService
} from '../../../../core/services/sidepanel.service';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

@Component({
  selector: 'app-nav-account',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit {
  @Input() user: User | null;
  @Input() roles: Role[];
  @Output() logout = new EventEmitter();
  @ViewChild('fsbutton', {static: false}) fsbutton;  // the fullscreen button

  constructor(private sidePanelService: SidePanelService) {
    this.user = AuthenticationHelper.getCurrentUser();
  }

  ngOnInit() {
    if (browser.msie) { // Not supported under IE
        this.fsbutton.nativeElement.style.display = 'none';
    }
}

  logoutClick() {
    this.logout.emit();
  }

  settingsClick() {
    this.sidePanelService.OpenSidePanelForSettings.emit('');
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
        screenfull.toggle();
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
        el.children('em').removeClass('fa-expand').addClass('fa-compress');
    } else {
        el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }
  }
}
