import * as utils from '../../../shared/helpers/utils';
import * as teams from '../actions/teams';
import * as _ from 'underscore';
import {
  Team
} from '../../../routes/teams/models/team';
import { TeamSelectItem } from '../../models/selectItem';
import * as moment from 'moment';

export interface State {
  teams: Team[];
  selectedTeam: Team;
  selectTeams: TeamSelectItem[];
  loading: boolean;
  error: string;
  success: string;
  page: number;
  maxItems: number;
  searchTerm: string;
}

const initialState: State = {
  teams: [],
  selectedTeam: null,
  loading: false,
  selectTeams: [],
  error: '',
  success: '',
  page: 1,
  maxItems: 0,
  searchTerm: '',
};

function RemoveItem(teams: Team[], team: Team) {  
  const index = _.findIndex(teams, {
    id: team.id
  });  
  teams.splice(index, 1);  
  const temp: Team[] = [];
  return teams = temp.concat(teams);
}

function replaceItem(teams: Team[], team: Team) {
  const index = _.findIndex(teams, {
    id: team.id
  });

  const clonedTeam = _.extend({}, team);
  if (index >= 0) {
    teams[index] = clonedTeam;
  } else {
    if (team.name != null) {
      teams.push(clonedTeam);
    }
  }

  const temp: Team[] = [];
  return teams = temp.concat(teams);
}

function mergeTeams(teams: Team[], newTeams: Team[]) {
  teams = teams.concat(newTeams);
  return teams;
}

function ConvertTeams(teams: Team[]): TeamSelectItem[] {
  const result = new Array<TeamSelectItem>();

  teams.forEach(item => {
    result.push({
      id: item.id,
      itemName: item.name,
      users: item.users
    });
  });

  return result;
}

export function reducer(state = initialState, action: teams.Actions): State {
  switch (action.type) {
    case teams.GET_ITEMS_PAGED:
      {
        const page = action.payload;
        return Object.assign({}, state, {
          loading: true,
          page: page == null ? state.page : page,
          searchTerm: action.term
        });
      }
    case teams.GET_ITEMS_COMPLETE_PAGED:
      {
        return {
          ...state,
          teams: mergeTeams(state.teams, action.payload),
          maxItems: action.maxCount,
          loading: false,
          error: '',
          success: ''
        };
      }
    case teams.GET_ITEMS_FAILED_PAGED:
      {
        return {
          ...state,
          teams: [],
          loading: false,
          error: action.payload,
          success: ''
        };
      }
    case teams.GET_ITEMS:
      {
        return {
          ...state,
          teams: [],
          loading: true
        };
      }
    case teams.GET_ITEMS_COMPLETE:
      {
        return {
          ...state,
          teams: action.payload,
          loading: false,
          error: '',
          success: '',
          selectTeams: ConvertTeams(action.payload)
        };
      }
    case teams.GET_ITEMS_FAILED:
      {
        return {
          ...state,
          teams: [],
          loading: false,
          error: action.payload,
          success: ''
        };
      }

    case teams.GET_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case teams.GET_ITEM_COMPLETE:
      {
        return {
          ...state,
          teams: replaceItem(state.teams, action.payload),
          selectedTeam: action.payload,
          loading: false,
          error: '',
          success: ''
        };
      }
    case teams.GET_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedTeam: initialState.selectedTeam,
          success: ''
        };
      }

    case teams.UPDATE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case teams.UPDATE_ITEM_COMPLETE:
      {
        return {
          ...state,
          teams: replaceItem(state.teams, action.payload),
          selectedTeam: action.payload,
          loading: false,
          error: '',
          success: 'Updated Team at: ' + moment(new Date(), 'DD/MM/YYYY').format('LTS')
        };
      }
    case teams.UPDATE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: ''
        };
      }
      case teams.DELETE_ITEM:
      {
        return {
          ...state,
          loading: true
        };
      }
    case teams.DELETE_ITEM_COMPLETE
    :
      {
        return {
          ...state,
          teams: RemoveItem(state.teams, action.payload),
          selectedTeam: initialState.selectedTeam,
          loading: false,
          error: '',
          success: 'Deleted Team'
        };
      }
    case teams.DELETE_ITEM_FAILED:
      {
        return {
          ...state,
          loading: false,
          error: action.payload,
          selectedTeam: initialState.selectedTeam,
          success: ''
        };
      }
    case teams.GET_BASE_ITEM:
      {
        return {
          ...state,
          loading: true,
          error: '',
          success: ''
        };
      }
    case teams.CREATE_ITEM:
      {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }

    case teams.CLEAR_ERRORS:
      {
        return {
          ...state,
          error: '',
          success: ''
        };
      }
      case teams.RESET_STORE:
      {
        return initialState;
      }

    default:
      {
        return state;
      }
  }
}

export const getMaxItems = (state: State) => state.maxItems;
export const getPage = (state: State) => state.page;
export const getTeams = (state: State) => state.teams;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
export const getTeam = (state: State) => state.selectedTeam;
export const getSearchTerm = (state: State) => state.searchTerm;
export const getTeamsForSelect = (state: State) => state.selectTeams;
