import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  NgZone,
  HostBinding
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  Toast,
  ToastrService,
  ToastPackage
} from 'ngx-toastr';
import {
  MapService
} from '../../routes/map/map.service';
import { SidePanelService } from '../../core/services/sidepanel.service';

@Component({
  selector: 'app-help-toast-component',
  styles: [],
  host: {
    'class': 'toast help-toast'
  },
  templateUrl: './help-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0,
      })),
      state('active', style({})),
      state('removed', style({
        opacity: 0
      })),
      transition('inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition('active => removed',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class HelpToastComponent extends Toast {
  @HostBinding('class.animate') someField: boolean = true;

  public UserId: string;
  public HelpMessageId: string;
  public shouldDelay: boolean;
  shouldAnimate: boolean;
  // constructor is only necessary when not using AoT
  constructor(private sidePanelService: SidePanelService, protected toastrService: ToastrService, public toastPackage: ToastPackage, private _mapService: MapService, private zone: NgZone, private router: Router) {
    super(toastrService, toastPackage);
    if (this.router.url !== '/map' && this.router.url !== '/admin/notifications' && this.router.url !== '/systemhealth') {
      this.redirectToMap();
    }
  }

  viewHelpInfo() {
    if (this.router.url !== '/map' && this.router.url !== '/admin/notifications') {
      this.redirectToMap();
    }
    this.sidePanelService.OpenSidePanelForHelpDetails.emit(this.HelpMessageId);
  }

  viewOnMap() {
    if (this.router.url !== '/map') {

      if (this.router.url === '/systemhealth') {
        this.shouldDelay = true;
      }

      this.router.navigate(['map']).then(() => {
        if (this.shouldDelay) {
          setTimeout(() => {
            this.zone.run(() => this._mapService.searchClick.emit({
              type: 2,
              id: this.UserId
            }));
          }, 1000);
        } else {
            this.zone.run(() => this._mapService.searchClick.emit({
              type: 2,
              id: this.UserId
            }));
        }
      });
    } else {
      this.zone.run(() => this._mapService.searchClick.emit({
        type: 2,
        id: this.UserId
      }));
    }
  }

  private redirectToMap() {

    this.router.navigate(['map']);
  }
}
