import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Report, ReportDropdownItem, ReportState } from '../models/report';
import { retry } from 'rxjs/operator/retry';
import { User } from '../../users/models/user';

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  private selectedTeam: string;

  private selectedUser: User;

  private selectedDateItem: ReportDropdownItem;

  private selectedSingleDate: Date;

  getReports() {
    return this.http.get<Report[]>('/api/v2/reports/list');
  }

  exportReport(payload: {id: number, page: number, pageSize: number, start: Date, end: Date, sortField: string, sortDir: string, searchTerm: string, userId: string, teamId: string, selectedDateItem: string }) {
    return this.http.post('/api/v2/reports/get/' + payload.id + '/export', {page: payload.page, pageSize: payload.pageSize, startDate: payload.start, endDate: payload.end, sortField: payload.sortField, sortDir: payload.sortDir, searchTerm: payload.searchTerm, userId: payload.userId, teamId: payload.teamId, selectedDateItem: payload.selectedDateItem }, {observe: 'response', responseType: 'blob'});
  }

  getTeamsDropdown() {
    return this.http.get<ReportDropdownItem[]>('/api/v2/teams/dropdown');
  }

  getReport(payload: {id: number, page: number, pageSize: number, start: Date, end: Date, sortField: string, sortDir: string, searchTerm: string, userId: string, teamId: string, selectedDateItem: string }) {

    return this.http.post<Report>('/api/v2/reports/get/' + payload.id, {page: payload.page, pageSize: payload.pageSize, startDate: payload.start, endDate: payload.end, sortField: payload.sortField, sortDir: payload.sortDir, searchTerm: payload.searchTerm, userId: payload.userId, teamId: payload.teamId, selectedDateItem: payload.selectedDateItem });
  }

  getReportSettings(payload: {id: number, page: number, pageSize: number, sortField: string, sortDir: string, userId: string, teamId: string}) {
    return this.http.post<Report>('/api/v2/reports/get/' + payload.id + '/settings', {page: payload.page, pageSize: payload.pageSize,sortField: payload.sortField, sortDir: payload.sortDir, userId: payload.userId, teamId: payload.teamId });
  }

  setSelectedSingleDate(value: Date) {
    this.selectedSingleDate = value;
  }

  getSelectedSingleDate(): Date {
    return this.selectedSingleDate;
  }

  clearSelectedSingleDate() {
    this.selectedSingleDate = null;
  }

  setSelectedUser(value: User) {
    this.selectedUser = value;
  }

  getSelectedUser() {
    return this.selectedUser;
  }
  
  // Saves the reports filtering state.
  AddReportState(value: ReportState) {
    const serializedStates = localStorage.getItem('reportStates');
    if (serializedStates === null || serializedStates === undefined) {
      const newStates = new Array<ReportState>();
      newStates.push(value);
      localStorage.setItem('reportStates', JSON.stringify(newStates));
    } else {
      const reportStates: Array<ReportState> = JSON.parse(serializedStates);

      const reportState = reportStates.filter(val => val.reportId === value.reportId)[0];

      if (reportState != null) {
        const index = reportStates.indexOf(reportState);
        reportStates[index] = value;
      }

      reportStates.push(value);
      localStorage.setItem('reportStates', JSON.stringify(reportStates));
    }
  }

  // Looks up the state of a reports filters.
  getReportState(reportId: number): ReportState {
    const serializedStates = localStorage.getItem('reportStates');
    if (serializedStates === null || serializedStates === undefined) {
      return null;
    } else {
      const reportStates: Array<ReportState> = JSON.parse(serializedStates);
      const reportState = reportStates.filter(value => value.reportId === reportId)[0];
      return reportState;
    }
  }

  setSelectedDateItem(value: ReportDropdownItem) {
    this.selectedDateItem = value;
  }

  getSelectedDateItem() {
    return this.selectedDateItem;
  }

  clearSelectedDateItem() {
    this.selectedDateItem = null;
  }

  clearSelectedUser() {
    this.selectedUser = null;
  }

  setSelectedTeam(value: string) {
    this.selectedTeam = value;
  }

  getSelectedTeam() {
    return this.selectedTeam;
  }
}
