import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { routes } from './routes';
import { PagesModule } from './auth/pages.module';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
        PagesModule
    ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor() {
    }
}
