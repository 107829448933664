import {
  Action
} from '@ngrx/store';
import {
  User
} from '../../../routes/users/models/user';

export const GET_ITEMS_PAGED = '[Users] Get Items Paged';
export const GET_ITEMS_COMPLETE_PAGED = '[Users] Get Items Complete Paged';
export const GET_ITEMS_FAILED_PAGED = '[Users] Get Items Failed Paged';
//export const UPDATE_USER_LOCATION = '[Users] Update users Location';

// export class UpdateUsersLocation implements Action {
//   readonly type = UPDATE_USER_LOCATION;
//   constructor(public id: string, public lat: any, public lng: any) {}
// }
export class GetItemsPaged implements Action {
  readonly type = GET_ITEMS_PAGED;
  constructor(public payload: number, public term: string, public includeDeletedUsers: boolean) {}
}
export class GetItemsCompletePaged implements Action {
  readonly type = GET_ITEMS_COMPLETE_PAGED;

  constructor(public payload: User[], public maxCount: number) {}
}
export class GetItemsFailedPaged implements Action {
  readonly type = GET_ITEMS_FAILED_PAGED;

  constructor(public payload: string) {}
}
export type GetItemsActionsPaged = GetItemsPaged | GetItemsCompletePaged | GetItemsFailedPaged;

export const GET_ITEMS = '[Users] Get Items';
export const GET_ITEMS_COMPLETE = '[Users] Get Items Complete';
export const GET_ITEMS_FAILED = '[Users] Get Items Failed';

export class GetItems implements Action {
  readonly type = GET_ITEMS;
}
export class GetItemsComplete implements Action {
  readonly type = GET_ITEMS_COMPLETE;

  constructor(public payload: User[]) {}
}
export class GetItemsFailed implements Action {
  readonly type = GET_ITEMS_FAILED;

  constructor(public payload: string) {}
}
export type GetItemsActions = GetItems | GetItemsComplete | GetItemsFailed;

export const GET_ITEM = '[Users] Get Item';
export const GET_ITEM_COMPLETE = '[Users] Get Item Complete';
export const GET_ITEM_FAILED = '[Users] Get Item Failed';

export class GetItem implements Action {
  readonly type = GET_ITEM;

  constructor(public payload: string) {}
}
export class GetItemComplete implements Action {
  readonly type = GET_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class GetItemFailed implements Action {
  readonly type = GET_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type GetItemActions = GetItem | GetBaseItem | GetItemComplete | GetItemFailed;

export const UPDATE_ITEM = '[Users] Update Item';
export const UPDATE_ITEM_COMPLETE = '[Users] Update Item Complete';
export const UPDATE_ITEM_FAILED = '[Users] Update Item Failed';

export class UpdateItem implements Action {
  readonly type = UPDATE_ITEM;

  constructor(public payload: User) {}
}
export class UpdateItemComplete implements Action {
  readonly type = UPDATE_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class UpdateItemFailed implements Action {
  readonly type = UPDATE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type UpdateItemActions = UpdateItem | UpdateItemComplete | UpdateItemFailed;

export const CREATE_ITEM = '[Users] Create Item';
export const CREATE_ITEM_COMPLETE = '[Users] Create Item Complete';
export const CREATE_ITEM_FAILED = '[Users] Create Item Failed';
export const GET_BASE_ITEM = '[Users] Get Base Item';


export class CreateItem implements Action {
  readonly type = CREATE_ITEM;

  constructor(public payload: User) {}
}

export class CreateItemComplete implements Action {
  readonly type = CREATE_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class CreateItemFailed implements Action {
  readonly type = CREATE_ITEM_FAILED;

  constructor(public payload: string) {}
}

export class GetBaseItem implements Action {
  readonly type = GET_BASE_ITEM;
}
export type CreateItemActions = CreateItem | CreateItemFailed | CreateItemComplete | GetBaseItem;

export const CLEAR_ERRORS = '[Users] Clear Errors';

export class ClearErrors implements Action {
  readonly type = CLEAR_ERRORS;
}

export type Actions = GetItemsActionsPaged | GetItemActions | GetItemsActions | UpdateItemActions | CreateItemActions | ClearErrors | DeactivateItemActions | ActivateItemActions | ResetStore | ClearSelectedUser | DeleteItemActions | RestoreItemActions;


export const RESET_STORE = '[Users] Reset Store';

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}

export const CLEAR_SELECTED_USER = '[Users] Clear Selected User';

export class ClearSelectedUser implements Action {
  readonly type = CLEAR_SELECTED_USER;
}

export const DELETE_ITEM = '[Users] Delete Item';
export const DELETE_ITEM_COMPLETE = '[Users] Delete Item Complete';
export const DELETE_ITEM_FAILED = '[Users] Delete Item Failed';

export class DeleteItem implements Action {
  readonly type = DELETE_ITEM;

  constructor(public payload: User) {}
}
export class DeleteItemComplete implements Action {
  readonly type = DELETE_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class DeleteItemFailed implements Action {
  readonly type = DELETE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type DeleteItemActions = DeleteItem | DeleteItemComplete | DeleteItemFailed;

export const DEACTIVATE_ITEM = '[Users] Deactivate Item';
export const DEACTIVATE_ITEM_COMPLETE = '[Users] Deactivate Item Complete';
export const DEACTIVATE_ITEM_FAILED = '[Users] Deactivate Item Failed';

export class DeactivateItem implements Action {
  readonly type = DEACTIVATE_ITEM;

  constructor(public payload: User) {}
}
export class DeactivateItemComplete implements Action {
  readonly type = DEACTIVATE_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class DeactivateItemFailed implements Action {
  readonly type = DEACTIVATE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type DeactivateItemActions = DeactivateItem | DeactivateItemComplete | DeactivateItemFailed;

export const ACTIVATE_ITEM = '[Users] Activate Item';
export const ACTIVATE_ITEM_COMPLETE = '[Users] Activate Item Complete';
export const ACTIVATE_ITEM_FAILED = '[Users] Activate Item Failed';

export class ActivateItem implements Action {
  readonly type = ACTIVATE_ITEM;

  constructor(public payload: User) {}
}
export class ActivateItemComplete implements Action {
  readonly type = ACTIVATE_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class ActivateItemFailed implements Action {
  readonly type = ACTIVATE_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type ActivateItemActions = ActivateItem | ActivateItemComplete | ActivateItemFailed;

export const RESTORE_ITEM = '[Users] Restore Item';
export const RESTORE_ITEM_COMPLETE = '[Users] Restore Item Complete';
export const RESTORE_ITEM_FAILED = '[Users] Restore Item Failed';

export class RestoreItem implements Action {
  readonly type = RESTORE_ITEM;

  constructor(public payload: User) {}
}
export class RestoreItemComplete implements Action {
  readonly type = RESTORE_ITEM_COMPLETE;

  constructor(public payload: User) {}
}
export class RestoreItemFailed implements Action {
  readonly type = RESTORE_ITEM_FAILED;

  constructor(public payload: string) {}
}

export type RestoreItemActions = RestoreItem | RestoreItemComplete | RestoreItemFailed;
