import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  AnnouncementsService
} from '../../../routes/messaging/services/messages.service';
import * as announcements from '../actions/announcements';
import {
  Announcement
} from '../../../routes/messaging/models/announcement';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class AnnouncementsEffects {

  constructor(private actions$: Actions, private announcementsService: AnnouncementsService, private router: Router) {}

  @Effect()
  public fetchList$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.GET_ITEMS),
    switchMap(() => this.announcementsService.getAnnouncements().pipe(
      map((results: Announcement[]) => {
        return {
          type: announcements.GET_ITEMS_COMPLETE,
          payload: results
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.GET_ITEMS_FAILED,
          payload: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));

  @Effect()
  public fetchListPaged$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.GET_ITEMS_PAGED),
    switchMap(() => this.announcementsService.getAnnouncementsPaged().pipe(
      map(data => {
        return {
          type: announcements.GET_ITEMS_COMPLETE_PAGED,
          payload: data.body,
          maxCount: parseInt(JSON.parse(data.headers.get('X-Pagination')).totalCount)
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.GET_ITEMS_FAILED_PAGED,
          payload: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));

  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.GET_ITEM),
    map((action: announcements.GetItem) => action.payload),
    switchMap(payload => this.announcementsService.getAnnouncement(payload).pipe(
      map(data => {
        return {
          type: announcements.GET_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.GET_ITEM_FAILED,
          payload: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));

  @Effect()
  public update$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.UPDATE_ITEM),
    map((action: announcements.UpdateItem) => action.payload),
    switchMap(payload => this.announcementsService.updateAnnouncement(payload).pipe(
      map(data => {
        return {
          type: announcements.UPDATE_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.UPDATE_ITEM_FAILED,
          payload: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));

  @Effect()
  public delete$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.DELETE_ITEM),
    map((action: announcements.DeleteItem) => action.payload),
    switchMap(payload => this.announcementsService.deleteAnnouncement(payload.id).pipe(
      map(data => {
        return {
          type: announcements.DELETE_ITEM_COMPLETE,
          payload: payload
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.DELETE_ITEM_FAILED,
          payload: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));

  @Effect()
  public add$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.CREATE_ITEM),
    map((action: announcements.CreateItem) => action.payload),
    switchMap(payload => this.announcementsService.addAnnouncement(payload).pipe(
      map((data: Announcement) => {
        this.router.navigate(['/messages/view', data.id]);
        return {
          type: announcements.UPDATE_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.UPDATE_ITEM_FAILED,
          payload: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));

  @Effect()
  public baseFetch$: Observable < Action > = this.actions$.pipe(
    ofType(announcements.GET_BASE_ITEM),
    switchMap(action => this.announcementsService.getBaseAnnouncement().pipe(
      map(data => {
        return {
          type: announcements.GET_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => {
        return of({
          type: announcements.GET_ITEM_FAILED,
          paylod: ErrorHelper.GetErrorMessage(error)
        });
      })
    )));
}
