import {
  NgModule
} from '@angular/core';
import {
  SearchComponent
} from './components/search.component';
import {
  SearchItemComponent
} from './components/search-item.component';
import { SharedModule } from '../../shared/shared.module';

export const COMPONENTS = [
  SearchComponent,
  SearchItemComponent
];

@NgModule({
  imports: [
    SharedModule
  ]
})
export class SearchModule {}
