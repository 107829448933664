import 'rxjs/add/operator/take';
import 'rxjs/add/operator/mergeMap';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import * as Auth from '../../core/store/actions/auth';
import * as fromRoot from '../../core/store/reducers';
import { AuthenticationHelper } from '../helpers/utils';
import { AuthToken } from '../../routes/auth/models/token';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromRoot.State>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newToken = AuthenticationHelper.getBearerToken();
    if (newToken !== null && newToken.access_token !== '') {
      req = req.clone({setHeaders: {Authorization: 'Bearer ' + newToken.access_token}});
    }
    return next.handle(req);
  }
}
