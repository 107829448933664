import {
    Component,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    NgZone,
    ViewEncapsulation,
    Renderer2
  } from '@angular/core';
  import {
    Store
  } from '@ngrx/store';
  import * as fromRoot from '../../../core/store/reducers';
  import * as Auth from '../../../core/store/actions/auth';
  import { Subject } from 'rxjs';
  import { takeUntil } from 'rxjs/operators';
  import { ConfigService } from '../../../core/services/config.service';
import { TrainingService } from '../services/training.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingType } from '../models/TrainingModel';
  
  @Component({
    selector: 'training',
    templateUrl: './training.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./promo-site.css']
  })
  export class TrainingComponent implements OnInit, OnDestroy {
    loading: boolean;
    data: any;
    private ngUnsubscribe: Subject< any > = new Subject();
    completedTrainingForm: FormGroup;
    formSubmitted = false;
    responseMessage ="";
    isWarningMessage = false;
    canAccessPromoSite : boolean = true;
    public trainingType: TrainingType;
  
    constructor(private route: ActivatedRoute, private renderer: Renderer2, private fb: FormBuilder, private store: Store < fromRoot.State >, private cd: ChangeDetectorRef,private zone: NgZone, private configService: ConfigService, private trainingService: TrainingService, private sanitizer: DomSanitizer, private router: Router ){
      this.completedTrainingForm = new FormGroup({
        workEmail: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(200)]),
        agreeTerms: new FormControl(false, Validators.requiredTrue)
      });
    }
  
    ngOnInit() {  
      this.loading = true;
      this.route.data.subscribe(data => {
        this.trainingType = data['trainingType'];
      });
      // const linkElement = this.renderer.createElement('link');
      // this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
      // this.renderer.setAttribute(linkElement, 'href', './app/routes/training/components/promo-site.css');
      // this.renderer.appendChild(document.head, linkElement);

      const script = this.renderer.createElement('script');
      script.src = 'assets/js/promo-site.js';      
      script.type = 'text/javascript';
      script.async = true;
      this.renderer.appendChild(document.body, script);

      script.src = 'assets/js/bootstrap.min.js';
      script.type = 'text/javascript';
      script.async = true;
      this.renderer.appendChild(document.body, script);   
       if(this.trainingType == TrainingType.LoneWorker) {
        this.trainingService.getLoneWorkerTraining()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          this.zone.run(() => {
            this.data = result//this.sanitizer.bypassSecurityTrustHtml(result);
            console.log(result);
            this.canAccessPromoSite = true;
            this.loading = false;
            this.cd.detectChanges();
          });
        },
        error => {     
          this.canAccessPromoSite = false;   
          this.loading = false;
        });
       }
       else if(this.trainingType == TrainingType.SOSRecipient){
        this.trainingService.getSOSRecipientTraining()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.zone.run(() => {
          this.data = result//this.sanitizer.bypassSecurityTrustHtml(result);
          console.log(result);
          this.canAccessPromoSite = true;
          this.loading = false;
          this.cd.detectChanges();
        });
      },
      error => {     
        this.canAccessPromoSite = false;   
        this.loading = false;
      });
       }
      
      
      
    }

    navigateToStep(step: string) {
      this.router.navigate([], { fragment: step });
    }
    scrollToElement(step: string): void {
      const element = document.getElementById(step);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    }

    get workEmail() {
      return this.completedTrainingForm.get('workEmail');
    }
  
    get agreeTerms() {
      return this.completedTrainingForm.get('agreeTerms');
    }
  
    ngOnDestroy() {
      this.cd.detach();
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }  

    onSubmit() {
      if (this.completedTrainingForm.valid) {
        console.log('Form Submitted', this.completedTrainingForm.value);
        if(this.trainingType == TrainingType.LoneWorker){
          this.trainingService.CompleteLoneWorkerTraining(this.completedTrainingForm.get("workEmail").value)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result) => {
            this.zone.run(() => {    
              this.formSubmitted = true;                 
              this.isWarningMessage = result.trainingLog == null ? true : false;  
              this.responseMessage = result.responseMessage;    
              this.loading = false;
              this.cd.detectChanges();
            });
          },
          error => {     
            this.formSubmitted = false;        
            this.loading = false;
          });;
          // Handle form submission logic here
        }
        else if(this.trainingType == TrainingType.SOSRecipient){
          this.trainingService.CompleteSOSRecipientTraining(this.completedTrainingForm.get("workEmail").value)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result) => {
            this.zone.run(() => {  
              this.formSubmitted = true;   
              this.isWarningMessage = result.trainingLog == null ? true : false;               
              this.responseMessage = result.responseMessage;             
              this.loading = false;
              this.cd.detectChanges();
            });
          },
          error => {      
            this.loading = false;
          });;
          // Handle form submission logic here
        }
      }        
      else {
        console.log('Form is invalid');
      }
    }

    getBackgroundImageUrl(style: string){
      return this.sanitizer.bypassSecurityTrustStyle(`url(${style}&quality=70)`);

    }

    getSubtitleUrl(url: string){      
      return this.sanitizer.bypassSecurityTrustUrl(url);

    }    
    
  }
  