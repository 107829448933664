import {
    Pipe,
    PipeTransform
  } from '@angular/core';
import { User } from '../../routes/users/models/user';
import * as moment from 'moment';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const keys = [];
    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({key: enumMember, value: value[enumMember]});
        // Uncomment if you want log
        // console.log('enum member: ', {key: enumMember, value: value[enumMember]});
      }
    }
    return keys;
  }
}

@Pipe({name: 'activeusers'})
export class ActiveUsers implements PipeTransform {
  transform(users: User[], args: string[]): any {
    if (!users) {
      return [];
    }
    return users.filter(user => this.userRecentlyActive(user) === true);
  }

  userRecentlyActive(user: User): boolean {
    const currentDate = new Date();
    if (moment(user.lastSeen).isBefore(moment(currentDate).subtract(2, 'hours'))) {
      return false;
    }
    return true;
  }
}

@Pipe({name: 'last30daysusers'})
export class Last30DaysUsers implements PipeTransform {
  transform(users: User[], args: string[]): any {
    if (!users) {
      return [];
    }
    return users.filter(user => this.userRecentlyActive(user) === true);
  }

  userRecentlyActive(user: User): boolean {
    const currentDate = new Date();
    if (moment(user.lastSeen).isBefore(moment(currentDate).subtract(30, 'days'))) {
      return false;
    }
    return true;
  }
}
