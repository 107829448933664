import { Observable, Subject, merge } from "rxjs";
import { BsModalService } from "ngx-bootstrap";
import { ConfirmLeaveComponent } from "../../layout/components/modals/confirm-leave.component";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { OkCloseComponent } from "../../layout/components/modals/ok-close.component";

@Injectable()
export class ModalService {

    constructor(private modalService: BsModalService){

    }

    showConfirmModal(title?: string, message?: string): Observable<boolean> {
        const subject = new Subject<boolean>();
        const modal = this.modalService.show(ConfirmLeaveComponent, {animated: true, keyboard: true,backdrop: true, ignoreBackdropClick: true});
        modal.content.subject = subject;
        modal.content.title = title ? title : 'Are you sure?';
        modal.content.message = message ? message : 'You have pending changes, do you really want to leave the page?';
        const onHideObservable = this.modalService.onHide.pipe(map(() => false));
        return merge(
        subject.asObservable(),
        onHideObservable
        );
    }

    showOkModal(title: string, message: string): Observable<boolean> {
        const subject = new Subject<boolean>();
        const modal = this.modalService.show(OkCloseComponent, {animated: true, keyboard: true,backdrop: true, ignoreBackdropClick: true});
        modal.content.subject = subject;
        modal.content.title = title;
        modal.content.message = message;
        const onHideObservable = this.modalService.onHide.pipe(map(() => false));
        return merge(
        subject.asObservable(),
        onHideObservable
        );
    }
}