import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import * as teams from '../actions/teams';
import {
  Team
} from '../../../routes/teams/models/team';
import {
  TeamsService
} from '../../../routes/teams/services/teams.service';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class TeamsEffects {

  @Effect()
  public fetchList$: Observable < Action > = this.actions$.pipe(
    ofType(teams.GET_ITEMS),
    switchMap(action => this.teamsService.getTeams().pipe(
      map(data => ({
        type: teams.GET_ITEMS_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: teams.GET_ITEMS_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetchListPaged$: Observable < Action > = this.actions$.pipe(
    ofType(teams.GET_ITEMS_PAGED),
    switchMap(action => this.teamsService.getTeamsPaged().pipe(
      map(data => ({
        type: teams.GET_ITEMS_COMPLETE_PAGED,
        payload: data.body,
        maxCount: parseInt(JSON.parse(data.headers.get('X-Pagination')).totalCount)
      })),
      catchError((error) => of ({
        type: teams.GET_ITEMS_FAILED_PAGED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(teams.GET_ITEM),
    map((action: teams.GetItem) => action.payload),
    switchMap(payload => this.teamsService.getTeam(payload).pipe(
      map(data => ({
        type: teams.GET_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: teams.GET_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public update$: Observable < Action > = this.actions$.pipe(
    ofType(teams.UPDATE_ITEM),
    map((action: teams.UpdateItem) => action.payload),
    switchMap(payload => this.teamsService.updateTeam(payload).pipe(
      map(data => ({
        type: teams.UPDATE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: teams.UPDATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public delete$: Observable < Action > = this.actions$.pipe(
    ofType(teams.DELETE_ITEM),
    map((action: teams.DeleteItem) => action.payload),
    switchMap(payload => this.teamsService.deleteTeam(payload.id).pipe(
      map(data => ({
        type: teams.DELETE_ITEM_COMPLETE,
        payload: payload
      })),
      catchError((error) => of ({
        type: teams.DELETE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public add$: Observable < Action > = this.actions$.pipe(
    ofType(teams.CREATE_ITEM),
    map((action: teams.CreateItem) => action.payload),
    switchMap(payload => this.teamsService.addTeam(payload).pipe(
      map((data: Team) => {
        this.router.navigate(['/teams/edit', data.id]);

        return {
          type: teams.UPDATE_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => of ({
        type: teams.UPDATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public baseFetch$: Observable < Action > = this.actions$.pipe(
    ofType(teams.GET_BASE_ITEM),
    switchMap(action => this.teamsService.getBaseTeam().pipe(
      map(data => ({
        type: teams.GET_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: teams.GET_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  constructor(private actions$: Actions, private teamsService: TeamsService, private router: Router) {}
}
