import {
  Action
} from '@ngrx/store';
import { HelpMessage } from '../../../routes/help/models/helpMessage';

export const GET_AVAILABLE_HELP_MESSAGES = '[HelpMessages] Get Available';
export const GET_AVAILABLE_HELP_MESSAGES_COMPLETE = '[HelpMessages] Get Available Complete';
export const GET_AVAILABLE_HELP_MESSAGESS_FAILED = '[HelpMessages] Get Available Failed';

export class GetAvailableHelpMessages implements Action {
  readonly type = GET_AVAILABLE_HELP_MESSAGES;
  constructor(public payload: number, public term: string, public includeTest: boolean) {}
}
export class GetAvailableHelpMessagesComplete implements Action {
  readonly type = GET_AVAILABLE_HELP_MESSAGES_COMPLETE;
  constructor(public payload: HelpMessage[], public maxCount: number) {}
}
export class GetAvailableHelpMessagesFailed implements Action {
  readonly type = GET_AVAILABLE_HELP_MESSAGESS_FAILED;
  constructor(public payload: string) {}
}
export type ActionsGetAvailable = GetAvailableHelpMessages | GetAvailableHelpMessagesComplete | GetAvailableHelpMessagesFailed;

export const GET_HELP_MESSAGE = '[HelpMessages] Get Item';
export const GET_HELP_MESSAGE_COMPLETE = '[HelpMessages] Get Item Complete';
export const GET_HELP_MESSAGE_FAILED = '[HelpMessages] Get Item Failed';

export class GetHelpMessage implements Action {
  readonly type = GET_HELP_MESSAGE;

  constructor(public payload: string) {}
}
export class GetHelpMessageComplete implements Action {
  readonly type = GET_HELP_MESSAGE_COMPLETE;

  constructor(public payload: HelpMessage) {}
}
export class GetHelpMessageFailed implements Action {
  readonly type = GET_HELP_MESSAGE_FAILED;

  constructor(public payload: string) {}
}

export const GET_PERSISTENT_HELP_MESSAGE = '[HelpMessages] Get Persistant Item';
export const GET_PERSISTENT_HELP_MESSAGE_COMPLETE = '[HelpMessages] Get Persistant Item Complete';
export const GET_PERSISTENT_HELP_MESSAGE_FAILED = '[HelpMessages] Get Persistant Item Failed';

export class GetPersistantHelpMessage implements Action {
  readonly type = GET_PERSISTENT_HELP_MESSAGE;

  constructor(public payload: string) {}
}
export class GetPersistantHelpMessageComplete implements Action {
  readonly type = GET_PERSISTENT_HELP_MESSAGE_COMPLETE;

  constructor(public payload: HelpMessage) {}
}
export class GetPersistantHelpMessageFailed implements Action {
  readonly type = GET_PERSISTENT_HELP_MESSAGE_FAILED;

  constructor(public payload: string) {}
}

export type GetItemActions = GetHelpMessage | GetHelpMessageComplete | GetHelpMessageFailed | GetPersistantHelpMessage | GetPersistantHelpMessageComplete | GetPersistantHelpMessageFailed;

export const RESET_STORE = '[HelpMessages] Reset Store';

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}

export type Actions = ActionsGetAvailable | GetItemActions | ResetStore;
