import * as config from '../actions/config';
import {
  NavItem
} from '../../models/nav';
import {
  Map
} from '../../models/map';
import {
  Role
} from '../../../routes/roles/models/role';
import { act } from '@ngrx/effects';

export interface State {
  roles: Role[];
  nav: NavItem[];
  map: Map;
  loading: boolean;
  testMode: boolean;
  version: string;
}

const initialState: State = {
  roles: [],
  nav: [],
  map: {
    lat: 0,
    lng: 0,
    zoom: 13,
    styles: [],
  },
  testMode: false,
  loading: false,
  version: "1.0.0"
};

export function reducer(
  state = initialState,
  action: config.Actions
): State {
  switch (action.type) {
    case config.GET_ITEMS:
      {
        return {
          ...initialState,
          loading: true
        };
      }
    case config.GET_ITEMS_COMPLETE:
      {
        return {
          ...state,
          roles: action.payload.roles,
          nav: action.payload.nav,
          map: action.payload.map,
          testMode: action.payload.isTestMode,
          version: action.payload.version,
          loading: false
        };
      }
    case config.GET_ITEMS_FAILED:
      {
        return {
          ...initialState,
          loading: false
        };
      }
    default:
      {
        return state;
      }
  }
}

export const getLoading = (state: State) => state.loading;
export const getNavItems = (state: State) => state.nav;
export const getMap = (state: State) => state.map;
export const getRoles = (state: State) => state.roles;
export const getIsTestMode = (state: State) => state.testMode;
export const getVersion = (state: State) => state.version;
