import { Component, Input } from '@angular/core';

import { Location } from '../../routes/locations/models/location';

@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html'
})
export class LocationInfoComponent {
  @Input() location: Location;
  @Input() large = false;
}
