import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  HelpService
} from '../../../routes/help/services/help.service';
import * as helpMessages from '../actions/help';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class HelpMessageEffects {

  constructor(private actions$: Actions, private helpService: HelpService, private router: Router) {}

  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(helpMessages.GET_HELP_MESSAGE),
    map((action: helpMessages.GetHelpMessage) => action.payload),
    switchMap(payload => this.helpService.getHelpMessage(payload).pipe(
      map(data => ({
        type: helpMessages.GET_HELP_MESSAGE_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: helpMessages.GET_HELP_MESSAGE_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

    @Effect()
  public fetchPersistant$: Observable < Action > = this.actions$.pipe(
    ofType(helpMessages.GET_PERSISTENT_HELP_MESSAGE),
    map((action: helpMessages.GetPersistantHelpMessage) => action.payload),
    switchMap(payload => this.helpService.getHelpMessage(payload).pipe(
      map(data => ({
        type: helpMessages.GET_PERSISTENT_HELP_MESSAGE_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: helpMessages.GET_PERSISTENT_HELP_MESSAGE_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetchList$: Observable < Action > = this.actions$.pipe(
    ofType(helpMessages.GET_AVAILABLE_HELP_MESSAGES),
    switchMap(action => this.helpService.getHelpMessagsPaged().pipe(
      map(data => ({
        type: helpMessages.GET_AVAILABLE_HELP_MESSAGES_COMPLETE,
        payload: data.body,
        maxCount: parseInt(JSON.parse(data.headers.get('X-Pagination')).totalCount)
      })),
      catchError((error) => of ({
        type: helpMessages.GET_AVAILABLE_HELP_MESSAGESS_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));
}
