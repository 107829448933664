import {
  Component,
  Input
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  SidePanelService
} from '../../../core/services/sidepanel.service';

@Component({
  selector: 'app-base-rightpanel',
  template: `
    <div>
      <a href="javascript:void(0)" (click)="close()" class="toggle-panel side-panel-close">Close</a>
      <div class="section-title section-title-main">{{title}}</div>
      <div class="content-area content-area-sidebar">
        <div *ngIf="subtitle" class="section-title section-title-subtitle">{{subtitle}}</div>
        <ng-content></ng-content>
      </div>
    </div>
  `
})
export class BaseRightPanelComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() clickOutsideClose: boolean;

  constructor(private router: Router, private sidePanelService: SidePanelService) {}  

  close(): void {
    this.sidePanelService.CloseSidePanel.emit();
  }
}
