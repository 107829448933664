import * as utils from '../../../shared/helpers/utils';
import * as settings from '../actions/settings';
import * as _ from 'underscore';
import {
  AppSetting
} from '../../models/appSetting';

export interface State {
  appSetting: AppSetting;
  loading: boolean;
  error: string;
  success: string;
}

export const initialState: State = {
  appSetting: null,
  loading: true,
  error: '',
  success: ''
};

export function reducer(state = initialState, action: settings.Actions): State {
  switch (action.type) {
    case settings.GET_SETTINGS:
      {
        return {
          ...state,
          appSetting: null,
          loading: true
        };
      };
    case settings.GET_SETTINGS_COMPLETE:
      {
        return {
          ...state,
          appSetting: action.payload,
          loading: false,
          error: '',
          success: ''
        };
      };
    case settings.GET_SETTINGS_FAILED:
      {
        return {
          ...state,
          appSetting: null,
          loading: false,
          error: action.payload,
          success: ''
        }
      }
    default:
      {
        return state;
      }
  }
}

export const getSetting = (state: State) => state.appSetting;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
