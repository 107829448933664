import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  LocationsService
} from '../../../routes/locations/services/locations.service';
import * as imports from '../actions/import';
import * as locations from '../actions/locations';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  mergeMap
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class LocationImportEffects {

  constructor(private actions$: Actions, private locationService: LocationsService, private router: Router) {}

  @Effect()
  public getImporter$: Observable < Action > = this.actions$.pipe(
    ofType(imports.GET_IMPORTER),
    mergeMap(action => this.locationService.getImportInfo().pipe(
      map(data => ({
        type: imports.GET_IMPORTER_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: imports.GET_IMPORTER_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public uploadImport$: Observable < Action > = this.actions$.pipe(
    ofType(imports.UPLOAD_IMPORT),
    map((action: imports.UploadImport) => action.payload),
    switchMap(payload => this.locationService.importLocations(payload).pipe(
      map(data => ({
        type: imports.UPLOAD_IMPORT_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: imports.UPLOAD_IMPORT_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public uploadComplete$: Observable < Action > = this.actions$.pipe(
    ofType(imports.UPLOAD_IMPORT_COMPLETE),
    mergeMap(action => {
      return of({
        type: locations.GET_ITEMS
      });
    }));
}
