import {
  Component,
  Input
} from '@angular/core';

import {
  Team
} from '../../routes/teams/models/team';

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.component.html'
})
export class TeamInfoComponent {
  @Input() team: Team;
  @Input() large = false;
}
