import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidators {

    static MatchPassword(AC: AbstractControl){
        let password = AC.get('password').value; // to get value in input tag
        let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag

         if(!password || password == "")
         {
             return null;
         }

         if(password != confirmPassword) {
             AC.get('confirmPassword').setErrors( {MatchPassword: true} );
         } else {
             return null;
         }
    }

    // Ensures the password meets the minimum standard. Password should be at least 8 char, contain two numeric, two upper, two lower, and two special characters.
    static PasswordRequirements(AC: AbstractControl) {
        let password = AC.get('password').value;
        
        if(!password || password == "")
        {
            return null;
        }
      
        if (password != password.match("^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*[0-9].*[0-9])(?=.*[#?!@$%^&*-].*[#?!@$%^&*-]).{8,}$")) {
            AC.get('password').setErrors({PasswordRequirements: true})
        } else {
            return null;
        }

    }
}
