import {
  createSelector,
  createFeatureSelector
} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import * as fromConfig from './config';
import * as fromAuth from './auth';
import * as fromSearch from './search';
import * as fromLocations from './locations';
import * as fromImport from './locationimport';
import * as fromAnnouncements from './announcements';
import * as fromReports from './reports';
import * as fromRoles from './roles';
import * as fromTeams from './teams';
import * as fromUsers from './users';
import * as fromUserImport from './userimport';
import * as fromHelpMessages from './help';
import * as fromPermissions from './permissions';

import {
  Map
} from '../../models/map';

export interface State extends fromRoot.State {
  config: fromConfig.State;
  status: fromAuth.State;
  state: fromSearch.State;
  locationState: fromLocations.State;
  locationImporter: fromImport.State;
  announcements: fromAnnouncements.State;
  reports: fromReports.State;
  roles: fromRoles.State;
  teams: fromTeams.State;
  users: fromUsers.State;
  userImporter: fromUserImport.State;
  helpMessages: fromHelpMessages.State;
  permissions: fromPermissions.State;
}

export const reducers = {
  config: fromConfig.reducer,
  status: fromAuth.reducer,
  state: fromSearch.reducer,
  locationState: fromLocations.reducer,
  locationImporter: fromImport.reducer,
  announcements: fromAnnouncements.reducer,
  reports: fromReports.reducer,
  roles: fromRoles.reducer,
  teams: fromTeams.reducer,
  users: fromUsers.reducer,
  userImporter: fromUserImport.reducer,
  helpMessages: fromHelpMessages.reducer,
  permissions: fromPermissions.reducer
};

export const selectCoreState = createFeatureSelector < State > ('core');
export const selectAuthStatusState = createSelector(selectCoreState, (state: State) => state.status);
export const getLoggedIn = createSelector(selectAuthStatusState, fromAuth.getLoggedIn);
export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const getToken = createSelector(selectAuthStatusState, fromAuth.getAuthToken);

export const selectCoreConfigState = createSelector(
  selectCoreState,
  (state: State) => state.config
);

export const selectCoreMapState = createSelector(
  selectCoreConfigState,
  fromConfig.getMap
);

export const getNavItems = createSelector(
  selectCoreConfigState,
  fromConfig.getNavItems
);

export const getTestMode = createSelector(
  selectCoreConfigState,
  fromConfig.getIsTestMode
);

export const getVersion = createSelector(
  selectCoreConfigState,
  fromConfig.getVersion
);

export const getMapLat = createSelector(
  selectCoreMapState,
  (state: Map) => state.lat
);

export const getMapLng = createSelector(
  selectCoreMapState,
  (state: Map) => state.lng
);

export const getMapZoom = createSelector(
  selectCoreMapState,
  (state: Map) => state.zoom
);

export const getMapStyles = createSelector(
  selectCoreMapState,
  (state: Map) => state.styles
);

export const getLoading = createSelector(
  selectCoreConfigState,
  fromConfig.getLoading
);

export const getRoles = createSelector(
  selectCoreConfigState,
  fromConfig.getRoles
);


export const selectLoginPageState = createSelector(
  selectCoreState,
  (state: State) => state.status
);
export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromAuth.getError
);

export const getLoginErrorDescription = createSelector(
  selectLoginPageState,
  fromAuth.getErrorDesciption
)

export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromAuth.getPending
);

export const selectSearchStatusState = createSelector(
  selectCoreState,
  (state: State) => state.state
);

export const getSearchResults = createSelector(
  selectSearchStatusState,
  fromSearch.getResults
);

export const getSearchLoading = createSelector(
  selectSearchStatusState,
  fromSearch.getLoading
);

export const getSearchError = createSelector(
  selectSearchStatusState,
  fromSearch.getError
);

export const selectPermissionsState = createSelector(
  selectCoreState,
  (state: State) => state.permissions
);

export const getPermissions = createSelector(
  selectPermissionsState,
  fromPermissions.getPermissions
);

export const selectLocationsState = createSelector(
  selectCoreState,
  (state: State) => state.locationState
);

export const selectImporterState = createSelector(
  selectCoreState,
  (state: State) => state.locationImporter
);

export const getLocationsMaxItems = createSelector(
  selectLocationsState, fromLocations.getMaxItems
);

export const getLocationsSearchTerm = createSelector(
  selectLocationsState, fromLocations.getSearchTerm
);

export const getLocationsPage = createSelector(
  selectLocationsState, fromLocations.getPage
);

export const getLocation = createSelector(
  selectLocationsState,
  fromLocations.getLocation
);

export const getLocations = createSelector(
  selectLocationsState,
  fromLocations.getLocations
);

export const getLocationError = createSelector(
  selectLocationsState,
  fromLocations.getError
);

export const getLocationLoading = createSelector(
  selectLocationsState,
  fromLocations.getLoading
);

export const getLocationSuccess = createSelector(
  selectLocationsState,
  fromLocations.getSuccess
);



export const getLocationImporter = createSelector(
  selectImporterState,
  fromImport.getImporter
);

export const getLocationImporterLoading = createSelector(
  selectImporterState,
  fromImport.getLoading
);

export const getLocationImporterError = createSelector(
  selectImporterState,
  fromImport.getError
);

export const selectAnnouncementsState = createSelector(
  selectCoreState,
  (state: State) => state.announcements
);

export const getAnnouncementsPage = createSelector(
  selectAnnouncementsState, fromAnnouncements.getPage
);

export const getAnnouncementsMaxItems = createSelector(
  selectAnnouncementsState, fromAnnouncements.getMaxItems
);

export const getAnnouncementsSearchTerm = createSelector(
  selectAnnouncementsState, fromAnnouncements.getSearchTerm
);

export const getAnnouncement = createSelector(
  selectAnnouncementsState,
  fromAnnouncements.getAnnouncement
);

export const getAnnouncements = createSelector(
  selectAnnouncementsState,
  fromAnnouncements.getAnnouncements
);

export const getAnnouncementError = createSelector(
  selectAnnouncementsState,
  fromAnnouncements.getError
);

export const getAnnouncementLoading = createSelector(
  selectAnnouncementsState,
  fromAnnouncements.getLoading
);

export const getAnnouncementSuccess = createSelector(
  selectAnnouncementsState,
  fromAnnouncements.getSuccess
);

export const selectReportsStatusState = createSelector(
  selectCoreState,
  (state: State) => state.reports
);

export const getReports = createSelector(
  selectReportsStatusState,
  fromReports.getReports
);

export const getReport = createSelector(
  selectReportsStatusState,
  fromReports.getReport
);

export const getReportLoading = createSelector(
  selectReportsStatusState,
  fromReports.getLoading
);

export const getReportSuccess = createSelector(
  selectReportsStatusState,
  fromReports.getSuccess
);

export const getReportError = createSelector(
  selectReportsStatusState,
  fromReports.getError
);

export const selectRolesState = createSelector(
  selectCoreState,
  (state: State) => state.roles
);

export const getAvailableRolesForDropdown = createSelector(
  selectRolesState,
  fromRoles.getAvailableRolesForSelect
);

export const getAvailableRoles = createSelector(
  selectRolesState,
  fromRoles.getAvailableRoles
);

export const getAvailableRolesLoading = createSelector(
  selectRolesState,
  fromRoles.getLoading
);

export const getAvailableRolesError = createSelector(
  selectRolesState,
  fromRoles.getError
);

export const selectTeamsState = createSelector(
  selectCoreState,
  (state: State) => state.teams
);

export const getTeamsPage = createSelector(
  selectTeamsState, fromTeams.getPage
);

export const getTeamsMaxItems = createSelector(
  selectTeamsState, fromTeams.getMaxItems
);

export const getTeamsSearchTerm = createSelector(
  selectTeamsState, fromTeams.getSearchTerm
);

export const getTeam = createSelector(
  selectTeamsState,
  fromTeams.getTeam
);

export const getTeams = createSelector(
  selectTeamsState,
  fromTeams.getTeams
);

export const getTeamError = createSelector(
  selectTeamsState,
  fromTeams.getError
);

export const getTeamLoading = createSelector(
  selectTeamsState,
  fromTeams.getLoading
);

export const getTeamSuccess = createSelector(
  selectTeamsState,
  fromTeams.getSuccess
);

export const getTeamsSelect = createSelector(
  selectTeamsState,
  fromTeams.getTeamsForSelect
);

export const selectUsersState = createSelector(
  selectCoreState,
  (state: State) => state.users
);

export const selectUserImporterState = createSelector(
  selectCoreState,
  (state: State) => state.userImporter
);

export const getUsersSearchTerm = createSelector(
  selectUsersState, fromUsers.getSearchTerm
);

export const getRolesSearchTerm = createSelector(
  selectRolesState, fromRoles.getSearchTerm
);

export const getRolesFromRoles = createSelector(
  selectRolesState,
  fromRoles.getAvailableRoles
);

export const getUsersPage = createSelector(
  selectUsersState, fromUsers.getPage
);

export const getRolesPage = createSelector(
  selectRolesState, fromRoles.getPage
);

export const getUserFromUser = createSelector(
  selectUsersState,
  fromUsers.getUser
);

export const getUsersMaxItems = createSelector(
  selectUsersState, fromUsers.getMaxItems
);

export const getRolesMaxItems = createSelector(
  selectRolesState, fromRoles.getMaxItems
);

export const getUsersSelect = createSelector(
  selectUsersState,
  fromUsers.getUsersForSelect
);

export const getRole = createSelector(
  selectRolesState,
  fromRoles.getRole
)

export const getUsers = createSelector(
  selectUsersState,
  fromUsers.getUsers
);

export const getUserError = createSelector(
  selectUsersState,
  fromUsers.getError
);

export const getUserLoading = createSelector(
  selectUsersState,
  fromUsers.getLoading
);

export const getUserSuccess = createSelector(
  selectUsersState,
  fromUsers.getSuccess
);

export const getUserImporter = createSelector(
  selectUserImporterState,
  fromUserImport.getImporter
);

export const getUserImporterLoading = createSelector(
  selectUserImporterState,
  fromUserImport.getLoading
);

export const getUserImporterError = createSelector(
  selectUserImporterState,
  fromUserImport.getError
);

export const getIncludeDeletedUsers = createSelector(
  selectUsersState, 
  fromUsers.getIncludeDeletedUsers
)

export const selectHelpMessagesState = createSelector(
  selectCoreState,
  (state: State) => state.helpMessages
);

export const getHelpLoading = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getLoading
);

export const getPersistantHelpLoading = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getPersistantLoading
);

export const getPriorityHelpLoading = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getPriorityLoading
);

export const getHelpMessage = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getHelpMessage
);

export const getPersistantHelpMessage = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getPersistantHelpMessage
);

export const getHelpError = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getError
);

export const getHelpSuccess = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getSuccess
);

export const getHelpMessages = createSelector(
  selectHelpMessagesState,
  fromHelpMessages.getHelpMessages
);

export const getHelpMessagesPage = createSelector(
  selectHelpMessagesState, fromHelpMessages.getPage
);

export const getHelpMessagesMaxItems = createSelector(
  selectHelpMessagesState, fromHelpMessages.getMaxItems
);

export const getHelpMessagesSearchTerm = createSelector(
  selectHelpMessagesState, fromHelpMessages.getSearchTerm
);

export const getHelpMessagesIncludeTest = createSelector(
  selectHelpMessagesState, fromHelpMessages.getIncludeTest
)