import {
    Component, Input, HostBinding, ChangeDetectorRef, NgZone, OnDestroy
  } from '@angular/core';
  
  @Component({
    selector: 'app-validation-container',
    templateUrl: './validation-container.component.html',
    host: {
        'class': 'validation-message',
        '[class.in]': 'display',
        '[class.out]': '!display',
        '[class.validation-danger]': '_isError',
        '[class.validation-success]': '!_isError'
      },
  })
  export class ValidationContainerComponent implements OnDestroy {
    @Input() set isError(value: boolean) {
        this._isError = value;
        this.cd.detectChanges();
    }

    private _isError: boolean = false;

    @Input() set description(value: string) {
        this._description = value;
        if (this._description !== undefined) {
            this.display = true;
            this.cd.detectChanges();
        }
    }
    _description: string;
    display = true;

    constructor(private cd: ChangeDetectorRef, private zone: NgZone) {

    }

    toggleDisplay(event: any) {
        this.zone.run(() => {
            this.display = false;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.cd.detach();
    }
  }
  