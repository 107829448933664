import { CheckInState } from '../../../core/models/stanzas/checkIn.stanza';
import { User } from '../../users/models/user';

export interface Location {
  id: string;
  lat: number;
  lng: number;
  name: string;
  postCode: string;
  locationRadius: number;
  recentCheckIns: CheckInItem[];
  locationType: LocationType;
  orderedCheckins: OrderedCheckinDay[];
}

export interface OrderedCheckinMonth {
  title: string;
  OrderedCheckinDays: OrderedCheckinDay[];
}

export interface OrderedCheckinDay {
  title: string;
  day: number;
  checkins: CheckInItem[];
}

export enum LocationType {
  Location = 0,
  Base = 1,
  Hospital = 2
}

export interface CheckInItem {
  id: string;
  inAt: Date;
  outAt: Date;
  hardInAt: Date;
  hardOutAt: Date;
  locationId: string;
  location: Location;
  state: CheckInState;
  hardState: CheckInState;
  timeAtLocation: string;
  user: User;
}
