import {
  Injectable,
  Output,
  EventEmitter
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  User,
  MapUser,
  SendSmsToAllResponse,
  Nudge
} from '../models/user';
import {
  MapUserCounts
} from '../models/MapUserCounts';
import {
  ImportInfo
} from '../../../core/models/import/importinfo';
import {
  Team
} from '../../teams/models/team';
import * as fromRoot from '../../../store/reducers';
import * as fromCore from '../../../core/store/reducers';
import {
  Store
} from '@ngrx/store';

@Injectable()
export class UsersService {
  @Output() IsEditing: EventEmitter < Boolean > = new EventEmitter();
  public page: number;
  public searchTerm: string;
  public isEditing = false;
  public overrideDialog = false;
  public includeDeletedUsers: boolean;

  constructor(private http: HttpClient, private store: Store < fromRoot.State > ) {
    this.store.select(fromCore.getUsersPage).subscribe((page) => {
      this.page = page;
    });

    this.store.select(fromCore.getUsersSearchTerm).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });

    this.store.select(fromCore.getIncludeDeletedUsers).subscribe((includeDeletedUsers) => {
      this.includeDeletedUsers = includeDeletedUsers;
    })
  }

  toggleEditing(value: boolean) {
    this.isEditing = value;
    this.IsEditing.emit(this.isEditing);
    console.log('is editing: ' + this.isEditing);
  }

  getUsers() {
    return this.http.get < User[] > ('/api/v2/users/list?includes=Roles,CheckIns&page=' + 0);
  }

  getMapUsers() {
    return this.http.get < MapUser[] > ('/api/v2/users/mapusers');
  }

  getUsersPaged() {
    if (this.searchTerm !== '') { 
      return this.http.get < User[] > ('/api/v2/users/list?includes=Roles&pageSize=20&page=' + this.page + '&searchTerm=' + encodeURIComponent(this.searchTerm) + '&includeDeletedUsers=' + this.includeDeletedUsers, {
        observe: 'response'
      });
    } else {
      return this.http.get < User[] > ('/api/v2/users/list?includes=Roles&pageSize=20&page=' + this.page + '&includeDeletedUsers=' + this.includeDeletedUsers, {
        observe: 'response'
      });
    }
  }

  getMapUserCounts(){
    return this.http.get<MapUserCounts> ('/api/v2/users/userCounts');
  }

  searchUsers(term: string){
    return this.http.get < User[] > ('/api/v2/users/list?includes=Roles&pageSize=0&page=0&searchTerm=' + term);
  }

  getUsersReport(teamsId: string[]) {
    return this.http.post < User[] > ('/api/v2/users/safe', {teamids: teamsId});
  }

  getUser(id: string) {
    return this.http.get < User > ('/api/v2/users/get/' + id + '?includes=Roles,Team,CheckIns.Location,UserSessions,UserValues,Cars,ApplicationSettings.ParentApplicationSetting,Nudges,TrainingLogs,TrainingEmailed');
  }

  getBase() {
    return this.http.get < User > ('/api/v2/users/get/base');
  }

  createUser(user: User) {
    return this.http.post < User > ('/api/v2/users/add', user);
  }

  updateUser(user: User) {
    return this.http.post < User > ('/api/v2/users/update', user);
  }

  deactivateUser(user: User) {
    return this.http.post < User > ('/api/v2/users/disable', user);
  }

  activateUser(user: User) {
    return this.http.post < User > ('/api/v2/users/enable', user);
  }

  restoreUser(user: User) {
    return this.http.post < User > ('/api/v2/users/restore', user);
  }

  getUserDetail(id: string) {
    return this.http.get < User > ('/api/v2/users/details/' + id );
  }

  sendSmsMulti(users: User[]) {
    return this.http.post<SendSmsToAllResponse>('/api/v2/users/sendpinmultiple', users);
  }

  sendSms(user: User) {
    return this.http.post< User > ('/api/v2/users/sendpin', user);
  }

  downloadImportTemplate() {
    return this.http.post('/api/v2/users/import/template', {}, {observe: 'response', responseType: 'blob'});
  }

  deleteUser(id: string) {
    return this.http.delete < boolean > ('/api/v2/users/delete/' + id);
  }

  getImportInfo() {
    return this.http.get < ImportInfo > ('/api/v2/users/import/info');
  }

  importUsers(data: string) {
    return this.http.post < ImportInfo > ('/api/v2/users/import/upload', {
      data: data
    });
  }

  getTeams(data: string) {
    return this.http.post < Team[] > ('/api/v2/teams/list', {
      data: data
    });
  }

  sendNudge(data: Nudge){
    return this.http.post < Nudge > ('/api/v2/nudge/add', data);
  }

  getNudgePreview(data: Nudge){
    return this.http.post < string > ('/api/v2/nudge/getemail', data);
  }
}
