import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import * as reports from '../actions/reports';
import {
  ReportsService
} from '../../../routes/reports/services/reports.service';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class ReportEffects {
  @Effect()
  public fetchReports$: Observable < Action > = this.actions$.pipe(
    ofType(reports.GET_ITEMS),
    switchMap(action => this.reportsService.getReports().pipe(
      map(data => ({
        type: reports.GET_ITEMS_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: reports.GET_ITEMS_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(reports.GET_ITEM),
    map((action: reports.GetItem) => action.payload),
    switchMap(payload => this.reportsService.getReport(payload).pipe(
      map(data => ({
        type: reports.GET_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: reports.GET_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  constructor(private actions$: Actions, private reportsService: ReportsService, private router: Router) {}
}
