import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  UsersService
} from '../../../routes/users/services/users.service';
import * as users from '../actions/users';
import {
  User
} from '../../../routes/users/models/user';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/utils';

@Injectable()
export class UsersEffects {

  @Effect()
  public fetchList$: Observable < Action > = this.actions$.pipe(
    ofType(users.GET_ITEMS),
    switchMap(action => this.userService.getUsers().pipe(
      map(data => ({
        type: users.GET_ITEMS_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.GET_ITEMS_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetchListPaged$: Observable < Action > = this.actions$.pipe(
    ofType(users.GET_ITEMS_PAGED),
    switchMap(action => this.userService.getUsersPaged().pipe(
      map(data => ({
        type: users.GET_ITEMS_COMPLETE_PAGED,
        payload: data.body,
        maxCount: parseInt(JSON.parse(data.headers.get('X-Pagination')).totalCount)
      })),
      catchError((error) => of ({
        type: users.GET_ITEMS_FAILED_PAGED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public fetch$: Observable < Action > = this.actions$.pipe(
    ofType(users.GET_ITEM),
    map((action: users.GetItem) => action.payload),
    switchMap(payload => this.userService.getUser(payload).pipe(
      map(data => ({
        type: users.GET_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.GET_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public update$: Observable < Action > = this.actions$.pipe(
    ofType(users.UPDATE_ITEM),
    map((action: users.UpdateItem) => action.payload),
    switchMap(payload => this.userService.updateUser(payload).pipe(
      map(data => ({
        type: users.UPDATE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.UPDATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public delete$: Observable < Action > = this.actions$.pipe(
    ofType(users.DELETE_ITEM),
    map((action: users.DeleteItem) => action.payload),
    switchMap(payload => this.userService.deleteUser(payload.id).pipe(
      map(data => ({
        type: users.DELETE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.DELETE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public deactivate$: Observable < Action > = this.actions$.pipe(
    ofType(users.DEACTIVATE_ITEM),
    map((action: users.DeactivateItem) => action.payload),
    switchMap(payload => this.userService.deactivateUser(payload).pipe(
      map(data => ({
        type: users.DEACTIVATE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.DEACTIVATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public restore$: Observable < Action > = this.actions$.pipe(
    ofType(users.RESTORE_ITEM),
    map((action: users.RestoreItem) => action.payload),
    switchMap(payload => this.userService.restoreUser(payload).pipe(
      map(data => ({
        type: users.RESTORE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.RESTORE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public activate$: Observable < Action > = this.actions$.pipe(
    ofType(users.ACTIVATE_ITEM),
    map((action: users.ActivateItem) => action.payload),
    switchMap(payload => this.userService.activateUser(payload).pipe(
      map(data => ({
        type: users.ACTIVATE_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.ACTIVATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public add$: Observable < Action > = this.actions$.pipe(
    ofType(users.CREATE_ITEM),
    map((action: users.CreateItem) => action.payload),
    switchMap(payload => this.userService.createUser(payload).pipe(
      map((data: User) => {
        this.router.navigate(['/users/edit', data.id]);

        return {
          type: users.CREATE_ITEM_COMPLETE,
          payload: data
        };
      }),
      catchError((error) => of ({
        type: users.CREATE_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  @Effect()
  public baseFetch$: Observable < Action > = this.actions$.pipe(
    ofType(users.GET_BASE_ITEM),
    switchMap(action => this.userService.getBase().pipe(
      map(data => ({
        type: users.GET_ITEM_COMPLETE,
        payload: data
      })),
      catchError((error) => of ({
        type: users.GET_ITEM_FAILED,
        payload: ErrorHelper.GetErrorMessage(error)
      }))
    )));

  constructor(private actions$: Actions, private userService: UsersService, private router: Router) {}
}
