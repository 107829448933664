import { Action } from '@ngrx/store';
import { Report } from '../../../routes/reports/models/report';

export const GET_ITEMS = '[Reports] Get Items';
export const GET_ITEMS_COMPLETE = '[Reports] Get Items Complete';
export const GET_ITEMS_FAILED = '[Reports] Get Items Failed';

export class GetItems implements Action {
  readonly type = GET_ITEMS;
}
export class GetItemsComplete implements Action {
  readonly type = GET_ITEMS_COMPLETE;

  constructor(public payload: Report[]) {}
}
export class GetItemsFailed implements Action {
  readonly type = GET_ITEMS_FAILED;

  constructor(public payload: string) {}
}
export type ItemsActions = GetItems | GetItemsComplete | GetItemsFailed;

export const GET_ITEM = '[Reports] Get Item';
export const GET_ITEM_COMPLETE = '[Reports] Get Item Complete';
export const GET_ITEM_FAILED = '[Reports] Get Item Failed';

export class GetItem implements Action {
  readonly type = GET_ITEM;

  constructor(public payload: {id: number, page: number, pageSize: number, start: Date, end: Date, sortField: string, sortDir: string, searchTerm: string, userId: string, teamId: string, selectedDateItem: string}) {}
}

export class GetItemComplete implements Action {
  readonly type = GET_ITEM_COMPLETE;

  constructor(public payload: Report) {}
}

export class GetItemFailed implements Action {
  readonly type = GET_ITEM_FAILED;

  constructor(public payload: string) {}
}
export type ItemActions = GetItem | GetItemComplete | GetItemFailed;

export type Actions = ItemsActions | ItemActions;
