import * as utils from '../../../shared/helpers/utils';
import * as imports from '../actions/import';
import * as _ from 'underscore';
import { ImportInfo } from '../../models/import/importinfo';

export interface State {
  importer: ImportInfo;
  loading: boolean;
  error: string;
}

const initialState: State = {
  importer: null,
  loading: true,
  error: ''
};

export function reducer(state = initialState, action: imports.Actions) {
  switch (action.type) {
    case imports.GET_IMPORTER: {
      return {
        ...state,
        loading: true,
        error: '',
        importer: null
      };
    }
    case imports.GET_IMPORTER_COMPLETE: {
      return {
        ...state,
        loading: false,
        error: '',
        importer: action.payload
      };
    }
    case imports.GET_IMPORTER_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        importer: null
      };
    }

    case imports.UPLOAD_IMPORT: {
      return {
        ...state,
        loading: true,
        error: ''
      };
    }
    case imports.UPLOAD_IMPORT_COMPLETE: {
      return {
        ...state,
        loading: false,
        error: '',
        importer: action.payload
      };
    }
    case imports.UPLOAD_IMPORT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getImporter = (state: State) => state.importer;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
