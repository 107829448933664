import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Router
} from '@angular/router';

import {
  ImportInfo
} from '../../core/models/import/importinfo';

@Component({
  selector: 'app-import-form',
  templateUrl: './import-form.component.html'
})
export class ImportFormComponent {
  @Input() importer: ImportInfo;
  @Output() save: EventEmitter < string > = new EventEmitter < string > ();
  @Output() downloadTemplate: EventEmitter < string > = new EventEmitter < string > ();
  @Output() cancel: EventEmitter < any > = new EventEmitter();
  @Input() instructions = '';
  rows = '';

  constructor(private router: Router) {}

  clickCancel(form: any) {
    let navigate = true;

    if (form.dirty) {
      navigate = confirm('Changes that you made may not be saved.');
    }

    if (navigate) {
      this.goBack();
    }
  }

  download() {
    this.downloadTemplate.emit();
  }

  goBack() {
    this.cancel.emit();
  }

  clickSave(form: any) {
    if (form.form.valid) {
      this.save.emit(this.rows);
    }
  }

  getPlaceholder() {
    if (this.importer == null || this.importer.headings == null) {
      return 'Unable to fetch importer';
    }

    return this.importer.headings.join(', ');
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const contents = e.target.result;
        this.rows = contents;
      };
      reader.readAsText(file);
    }
  }
}
