import {
  Component
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  Observable
} from 'rxjs/Observable';
import {
  AppSetting
} from '../../../core/models/appSetting';

import * as _ from 'underscore';
import {
  SettingsStorageService
} from '../../../core/services/settings.service';
import { AuthenticationHelper } from '../../../shared/helpers/utils';

@Component({
  selector: 'app-settings-info-right',
  templateUrl: './settings-rightpanel.component.html'
})
export class SettingsInfoPanelComponent {
  title$: string;
  loading$: Observable < boolean > ;
  refreshOptions$: AppSetting[];
  selectedValue$: any;
  selectedTeamRefresh: any;
  showLocationRadius: boolean = false;
  showUserRadius: boolean = false;
  canViewMap: boolean = false;
  canViewUserStatus: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private settingService: SettingsStorageService) {
    this.refreshOptions$ = [new AppSetting('5', '300000'),
      new AppSetting('15', '900000'),
      new AppSetting('30', '1.8e+6')
    ];
    this.title$ = 'Application Settings';
    this.canViewMap = AuthenticationHelper.hasMenuForRoute('/map');
    this.canViewUserStatus = AuthenticationHelper.hasMenuForRoute('/teamstatus');

    const test = this.settingService.getSetting('map.RefreshRate');
    if (test.appSetting != null) {
      this.selectedValue$ = test.appSetting.value;
    }

    const teamRefreshSetting = this.settingService.getSetting('teamStatus.RefreshRate');
    if (teamRefreshSetting.appSetting != null) {
      this.selectedTeamRefresh = teamRefreshSetting.appSetting.value;
    }

    const markerSettingValue = this.settingService.getSetting('map.ShowLocationRadius');
    if (markerSettingValue.appSetting != null) {
      this.showLocationRadius = markerSettingValue.appSetting.value;
    }

    const userRadiusSettingValue = this.settingService.getSetting('map.ShowUserRadius');
    if (userRadiusSettingValue.appSetting != null) {
      this.showUserRadius = userRadiusSettingValue.appSetting.value;
    }
  }

  updateShowLocationRadius(event: any) {
    const isChecked = (this.showLocationRadius = !this.showLocationRadius);
    this.settingService.setSetting(new AppSetting('map.ShowLocationRadius', isChecked));
    this.settingService.showLocationRadiusChanged.emit();
  }

  updateShowUserRadius(event: any) {
    const isChecked = (this.showUserRadius = !this.showUserRadius);
    this.settingService.setSetting(new AppSetting('map.ShowUserRadius', isChecked));
    this.settingService.showUserRadiusChanged.emit();
  }

  updateTeamRefreshValue(id: any): void {
    this.settingService.setSetting(new AppSetting('teamStatus.RefreshRate', id));
    this.settingService.refreshTeamSettingChanged.emit();
  }

  updateRefreshValue(id: any): void {
    this.settingService.setSetting(new AppSetting('map.RefreshRate', id));
    this.settingService.refreshSettingChanged.emit();
  }
}
