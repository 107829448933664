import * as auth from '../actions/auth';
import { User } from '../../../routes/users/models/user';
export const authCookie = 'auth_token';
import { AuthenticationHelper } from '../../../shared/helpers/utils';
import { AuthToken } from '../../../routes/auth/models/token';
import * as moment from 'moment';
import { CastExpr } from '@angular/compiler';

export interface State {
  loggedIn: boolean;
  user: User | null;
  authToken: AuthToken;
  error: string | null;
  error_description: string | null;
  pending: boolean;
}

export const initialState: State = {
  loggedIn: false,
  user: null,
  authToken: null,
  error: null,
  error_description: null,
  pending: false,
};

export function reducer(
  state = initialState,
  action
): State {
  switch (action.type) {

    case auth.LOGIN: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }


    case auth.LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        authToken: action.payload.authToken,
        error: null,
        pending: true,
      };
    }

    case auth.LOGIN_COMPLETE: {
      return {
        ...state,
        pending: false,
      };
    }

    case auth.LOGIN_FAILURE: {
      return {
        ...state,
        error: action.payload.error.error,
        error_description: action.payload.error.error_description,
        pending: false,
      };
    }

    case auth.LOGOUT: {
      return initialState;
    }

    case auth.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        user: AuthenticationHelper.getCurrentUser()
      };
    }

    case '@ngrx/store/init': {
      return AuthenticationHelper.getState();
    }

    default: {
      return AuthenticationHelper.getState();
    }
  }
}

export const getLoggedIn = (state: State) => {
  let expiresAt;
  if (state.authToken != null) {
    expiresAt = moment().add(state.authToken.expires_in, 'second');
  }
  return (state.authToken != null && moment().isBefore(expiresAt));
};
export const getUser = (state: State) => state.user;
export const getAuthToken = (state: State) => state.authToken;
export const getError = (state: State) => state.error;
export const getErrorDesciption = (state: State) => state.error_description;
export const getPending = (state: State) => state.pending;
