import { Component, Input } from '@angular/core';
import { Role } from '../../routes/roles/models/role';

@Component({
    selector: 'app-role-info',
    templateUrl: './role-info.component.html'
})
export class RoleInfoComponent {
    @Input() role: Role;
}
